<template>
  <v-sheet
    v-if="!ineligible && eligible"
    id="eligible-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <p id="container-header">{{ $t('refinancing') }}</p>
    <div id="inner-grey-container">
      <div>
        <div id="text-and-image">
          <div>{{ $t('youreEligibleToApply') }}</div>
          <img aria-hidden="true" alt="money bag with coins" src="~@/assets/images/svg/money_icon.svg" />
        </div>
        <p>{{ $t('keepPayingInstallment') }}</p>
      </div>
    </div>
    <div>
      <router-link
        :to="topUpRefiPath">
        <button
          id="refi-button"
          type="button">
          <span id="continue-text-span">{{ $t('continue') }}</span>
          <span id="arrow-img-span"><img aria-labelledby="continue-text-span" alt="arrow" src="~@/assets/arrow-back-gray.svg" /></span>
        </button>
      </router-link>
    </div>
    <v-expand-transition>
      <div v-if="expand" id="expanded">
        <p>{{ $t('eligibilityDependsOn') }}</p>
        <ul>
          <li>{{ $t('paymentDue') }}</li>
        </ul>
      </div>
    </v-expand-transition>
  </v-sheet>
</template>
<script lang="ts">
import { defineComponent, Ref, ref, onMounted, getCurrentInstance } from "vue"
import { useAccountDetailsStore } from "@/store"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "RefinanceEligibleCard",
  setup() {
    const { eligibleProducts } = useAccountDetailsStore()
    const ineligible: Ref<boolean> = ref(false)
    const eligible: Ref<boolean | null> = ref(null) // eligible = true, future eligible = false
    const expand: Ref<boolean> = ref(false)
    const topUpRefiPath: Ref<string> = ref("/top-up-refinance")
    const vueInstance = getCurrentInstance()

    const checkEligibility = () => {
      if (eligibleProducts.value && eligibleProducts.value.eligible_products.find((element: string) => element === "top_up_refinance")) {
        eligible.value = true
      } else {
        ineligible.value = true
      }
    }

    onMounted(() => {
      checkEligibility()

      if (!ineligible.value && eligible.value) {
        tagRecordings((vueInstance as unknown) as VueWithHotjar, ["refi_eligible_card_displayed"])
      }
    })

    return {
      expand,
      eligible,
      ineligible,
      topUpRefiPath
    }
  }
})
</script>
<style lang="scss" scoped>
#eligible-container {
  display: block;
  text-align: center;
  margin: 1em 0.25em;
  outline: 1px solid $simplic-outline-gray;
  box-sizing: border-box;

  #container-header {
    font: normal normal bold 20px/30px Open Sans;
    margin: 0;
    padding: 13px;
    color: $simplic-dark-text;
  }

  #inner-grey-container {
    background-color: $simplic-background;
    border-top: 1px solid $simplic-outline-gray;
    padding: 15px;
    text-align: left;

    #text-and-image {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding-bottom: 10px;

      div {
        flex-grow: 1;
        font: normal normal bold 24px/32px Open Sans;
      }
    }

    p {
      font: normal normal normal 14px/20px Open Sans;
      margin: 0;
    }

    #check-back-text {
      font: normal normal bold 14px/18px Open Sans;
      padding-top: 10px;
    }
  }

  button {
    background-color: $simplic-yellow;
    padding: 10px;
    margin: 20px auto;
    width: 90%;
    border-radius: 8px;

    #continue-text-span {
      font: normal normal bold 16px/22px Open Sans;
      float: left;
      color: $simplic-dark-text;
    }

    #arrow-img-span {
      float: right;
      padding-top: 3px;
    }
  }
  #learn-more-container {
    width: 100%;
    height: 35px;

    #learn-more-link {
      text-align: left;
      color: $simplic-blue;
      font: normal normal bold 14px/20px Open Sans;
      text-decoration: underline $simplic-blue;
      display: inline-block;
      width: 100px;
      float: left;

      p {
        width: 100px;
        padding: 5px 10px;
        margin: 0;
      }
    }

    .expandable-arrow {
      float: right;
      padding-top: 2px;
      padding-right: 10px;
    }
  }
  #expanded {
    text-align: left;

    p {
      font: normal normal bold 12px/20px Open Sans;
      padding-top: 10px;
      padding-left: 10px;
      margin: 0;
    }

    li {
      font: normal normal normal 12px/20px Open Sans;
      padding: 10px 2px;
    }
  }
}
</style>
<i18n>
{
  "pt": {
    "refinancing": "Crédito Adicional",
    "youreEligibleToApply": "Você tem mais dinheiro disponível!",
    "keepPayingInstallment": "Continue pagando o mesmo valor da sua parcela atual e tenha mais dinheiro na sua conta.",
    "continue": "Eu Quero!",
    "eligibilityDependsOn": "A elegibilidade ao crédito adicional depende de diferentes variáveis mas um dos fatores que contribuem para se tornar elegível é:",
    "paymentDue": "Você ter uma parcela a vencer nos próximos dias."
  }
}
</i18n>
