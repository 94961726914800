/* eslint-disable @typescript-eslint/ban-types */
import { Module } from "vuex"
import store, { State } from "@/store"
import { buildHelpers } from "@/store/composition-helpers"
import { WritableComputedRef } from "vue"
import { updateField, getField } from "vuex-map-fields"
import Cookies from "js-cookie"
import { logError } from "@/plugins/sentry"

const { mapGettersAndSettersToComputed } = buildHelpers<CookieStore>(
  () => store, "cookie"
)

export interface AnalyticDataLayerData {
  customer_type_after_login: string | null;
  app_version: string | null;
  overlays_version: string | null;
  environment: string;
  user_email: string | null;
  loan_application_type: string | null;
  bank_name: string | null;
  bank_display_name: string | null;
  channel: string | null;
  source: string | null;
  pay_date_ordinal: number | null;
  income: string | null;
  last_loan_application_state: string | null; // loan_application status
  loan_application_id: string | null;
  registration_flow: string | null;
  customerId: string | null; // account_id
  customerType: string;
  loanId: string | null; // loan_application_id
  loanNumber: string | null;
  eligible_to_apply?: string;
  maxDaysInDefault: number | null;
  inDefaultNoPmtPlan: boolean | null;
  user_home_phone: string | null;
}

interface AccessCookieData {
  access_token: string;
  refresh_token: string;
  full_name: string;
  email: string;
  account_id: number;
  cpf: number;
  loan_application_id: string;
  phone_number: number;
}

interface MenuItemData {
  display: boolean;
}

interface AccountHomeMenuData {
  top_up_refi: MenuItemData;
}

interface CookieStore {
  accountHomeMenu: AccountHomeMenuData;
  analyticDataLayer: AnalyticDataLayerData;
  accessTokensData: AccessCookieData;
}

export const newCookieStoreState = (): CookieStore => {
  return {
    accountHomeMenu: { top_up_refi: { display: false } },
    analyticDataLayer: {} as AnalyticDataLayerData,
    accessTokensData: {} as AccessCookieData
  }
}
const cookieState: CookieStore = newCookieStoreState()

interface CookieStoreAccessors {
  accountHomeMenu: WritableComputedRef<AccountHomeMenuData>;
  analyticDataLayer: WritableComputedRef<AnalyticDataLayerData>;
  accessTokensData: WritableComputedRef<AccessCookieData>;
  readAnalyticDataLayer: Function;
  updateAnalyticDataLayer: Function;
  readAccountHomeMenu: Function;
  readAccessTokens: Function;
  updateAccessTokens: Function;
  expireAccessTokens: Function;
}

function useCookieStore(): CookieStoreAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(cookieState) as Array<keyof CookieStore>)

  function readCookieAndParse(cookieName: string): object {
    try {
      const b64encoded = Cookies.get(cookieName)
      if (!b64encoded) {
        return {}
      }
      const buff = Buffer.from(b64encoded, "base64")
      return JSON.parse(buff.toString())
    } catch (e) {
      const extras = {
        cookie_name: cookieName
      }
      logError(e, extras)
      return {}
    }
  }

  function readAnalyticDataLayer(): AnalyticDataLayerData {
    return readCookieAndParse(process.env.VUE_APP_ANALYTIC_LAYER_COOKIE_NAME ?? "analytic_data_layer") as AnalyticDataLayerData
  }

  function updateAnalyticDataLayer() {
    const { analyticDataLayer } = mapFields
    const encoded = btoa(JSON.stringify(analyticDataLayer.value))
    const cookieDomain = process.env.VUE_APP_JWT_COOKIE_DOMAIN_NAME || "localhost"
    const secureCookies = process.env.VUE_APP_SECURE_JWT_COOKIES === "true"

    Cookies.set(process.env.VUE_APP_ANALYTIC_LAYER_COOKIE_NAME || "analytic_data_layer", encoded, { secure: secureCookies, domain: cookieDomain })
  }

  function readAccountHomeMenu(): AccountHomeMenuData {
    return readCookieAndParse(process.env.VUE_APP_MENU_ITEMS_COOKIE_NAME ?? "account_home_menu") as AccountHomeMenuData
  }

  function readAccessTokens(): AccessCookieData {
    return readCookieAndParse(process.env.VUE_APP_ACCESS_TOKENS_COOKIE_NAME ?? "simplic_access_tokens") as AccessCookieData
  }

  function expireAccessTokens() {
    const { accessTokensData } = mapFields
    const cookieDomain = process.env.VUE_APP_JWT_COOKIE_DOMAIN_NAME || "localhost"
    const secureCookies = process.env.VUE_APP_SECURE_JWT_COOKIES === "true"

    Cookies.remove(process.env.VUE_APP_ACCESS_TOKENS_COOKIE_NAME || "simplic_access_tokens", { secure: secureCookies, domain: cookieDomain })

    accessTokensData.value.access_token = ""
    accessTokensData.value.refresh_token = ""
  }

  function updateAccessTokens() {
    const { accessTokensData } = mapFields
    const encoded = btoa(JSON.stringify(accessTokensData.value))
    const cookieDomain = process.env.VUE_APP_JWT_COOKIE_DOMAIN_NAME || "localhost"
    const secureCookies = process.env.VUE_APP_SECURE_JWT_COOKIES === "true"

    Cookies.set(process.env.VUE_APP_ACCESS_TOKENS_COOKIE_NAME || "simplic_access_tokens", encoded, { secure: secureCookies, domain: cookieDomain })
  }

  mapFields.analyticDataLayer.value = readAnalyticDataLayer() as AnalyticDataLayerData
  mapFields.accountHomeMenu.value = readAccountHomeMenu()
  mapFields.accessTokensData.value = readAccessTokens() as AccessCookieData

  return {
    ...mapFields,
    readAnalyticDataLayer,
    updateAnalyticDataLayer,
    readAccountHomeMenu,
    readAccessTokens,
    updateAccessTokens,
    expireAccessTokens
  } as CookieStoreAccessors
}

const cookie: Module<CookieStore, State> = {
  namespaced: true,
  state: cookieState,
  getters: { getField },
  mutations: { updateField }
}

export default cookie
export { cookieState, useCookieStore, CookieStore, CookieStoreAccessors, AccessCookieData }
