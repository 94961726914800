<script setup lang="ts">
import AccountHomeBanner from "@/components/AccountHomeHeader.vue"
import Snackbar from "../Snackbar.vue"
import BackNavFooter from "@/components/footer/BackNavFooter.vue"
import OptionalTasks from "./OptionalTasks.vue"
import { useRoute } from "vue-router/composables"
import { ref, watch } from "vue"

const route = useRoute()

const showBackButton = () => {
  const split = route.path.replace(/^\//, "").split("/")
  if(split.length > 1) {
    return true
  } else if(split.includes("current-loan") || split.includes("loan-history") || split.includes("top-up-refinance") || split.includes("lower-my-payment")) {
    return true
  }

  return false
}

const getBackButtonPath = () => {
  const split = route.path.replace(/^\//, "").split("/")
  if(split.includes("current-loan") || split.includes("loan-history") || split.includes("payment-plans") || split.includes("top-up-refinance") || split.includes("lower-my-payment")) {
    return "/"
  }
  return "/" + route.path.replace(/^\//, "").split("/")[0]
}

const shouldShowBackButton = ref(showBackButton())
const backButtonPath = ref(getBackButtonPath())

watch(() => route.path, () => {
  shouldShowBackButton.value = showBackButton()
  backButtonPath.value = getBackButtonPath()
})
</script>

<template>
  <VContainer id="account-home-banner-container" class="simplic-main pa-0" fluid>
    <AccountHomeBanner />
    <div id="page-container">
      <v-card
        id="account-home-card"
        class="mx-auto pa-4">
        <Snackbar />
        <v-skeleton-loader v-if="!$slots.default" type="card" />
        <slot />
      </v-card>
      <OptionalTasks />
      <div v-if="shouldShowBackButton" id="back-nav">
        <BackNavFooter :path="backButtonPath" />
      </div>
    </div>
  </VContainer>
</template>

<style lang="scss">
	hr { border-color: #f5f5f5; }

  #account-home-banner-container {
    background-color: #f5f5f5;
  }

  #account-home-card {
    border: 1px solid grey;
    border-radius: 0;
    min-height: 30vh;
  }

  @media (min-width: 319px) {
    #account-home-card {
      width: 90%;
      margin-top: -16px !important;
    }
  }

  #page-container {
    max-width: 950px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  #back-nav {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
  }

  .clearfix {
    content: "";
    clear: both;
    display: table;
  }

</style>
