import { render, staticRenderFns } from "./OffersCardLoadingSkeleton.vue?vue&type=template&id=212af63c&scoped=true"
import script from "./OffersCardLoadingSkeleton.vue?vue&type=script&lang=js"
export * from "./OffersCardLoadingSkeleton.vue?vue&type=script&lang=js"
import style0 from "./OffersCardLoadingSkeleton.vue?vue&type=style&index=0&id=212af63c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.10_loda_nlv2dggb47nbs5pec7lzsfq3vm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212af63c",
  null
  
)

export default component.exports