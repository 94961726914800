<script setup lang="ts">
import { defineProps, defineEmits, defineExpose } from "vue"
import PaymentPlanOffersTooltip from "@/components/payment-plans/OffersTooltip.vue"
import { useAccountStore, usePaymentPlansStore } from "@/store"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"
import { postUniversalMetricsEvent } from "@/api/secure"

type OffersCardProps = {
  offer: object,
  singleOffer: boolean
}

defineProps<OffersCardProps>()
const emit = defineEmits<{
  (event: "offer-selected", offer: PaymentPlanOffer): void
  (event: "mouseover"): void
  (event: "mouseleave"): void
}>()

const { accountID } = useAccountStore()
const { withDownPayment } = usePaymentPlansStore()

const onSelectOffer = (offer: PaymentPlanOffer) => {
  emit("offer-selected", offer)
  postUniversalMetricsEvent(accountID.value, "selected_an_offer")
}
const onMouseover = () => {
  emit("mouseover")
}

const onMouseleave = () => {
  emit("mouseleave")
}

const downPaymentAmount = (offer: PaymentPlanOffer) => {
  return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
}

const installmentAmount = (offer: PaymentPlanOffer) => {
  if (!withDownPayment.value) {
    return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  }
  if (offer.number_of_installments === 1) {
    return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  }
  const remainingPaymentsAmount = (offer.total_amount - offer.installment_amount) / (offer.number_of_installments - 1)
  if (Math.abs(remainingPaymentsAmount - offer.installment_amount) > 0.1) {
    return remainingPaymentsAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  }
  return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
}

defineExpose({
  onMouseleave,
  onMouseover
})

</script>

<template>
  <v-sheet
    class="offer-sheet outer-offer-sheet"
    height="400px"
    color="white"
    :elevation="4"
    rounded
    tile
    @mouseover="onMouseover"
    @mouseleave="onMouseleave">
    <v-sheet
      class="offer-sheet inner-offer-sheet"
      :elevation="2"
      rounded
      color="white">
      <div class="installment-headline-container">
        <payment-plan-offers-tooltip v-if="offer.principal_discount > 0">
          {{ $t("discountTooltip", { discount: offer.discount }) }}
          <br />
          {{ $t("discountTooltipWithPrincipal", { principalDiscount: offer.principal_discount }) }}
        </payment-plan-offers-tooltip>
        <payment-plan-offers-tooltip v-else>
          {{ $t("discountTooltip", { discount: offer.discount }) }}
        </payment-plan-offers-tooltip>
        <h3 class="installment-amount-headline">
          {{ offer.total_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
        </h3>
      </div>
      <div class="installment-details">
        <span v-if="installmentAmount(offer) !== downPaymentAmount(offer)">
          <p>
            {{ $t('downPaymentDetails', {downPaymentAmount: downPaymentAmount(offer)}) }}
          </p>
        </span>
        <span v-else>
          <p id="no-remaining-installments">{{ $t('installmentDetails', {offerCount: offer.number_of_installments, installmentAmount: offer.installment_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}) }}</p>
        </span>
        <p id="remaining-installments-details">
          <span v-if="installmentAmount(offer) !== downPaymentAmount(offer)">{{ $t('remainingInstallmentsDetails', {installmentAmount: installmentAmount(offer), remainingInstallments: offer.number_of_installments - 1 }) }}</span>
        </p>
      </div>
    </v-sheet>
    <p class="value-of-first-installment">
      {{ $t('valueOfFirstInstallment') }}
    </p>
    <p class="first-installment-due-date">
      {{ new Date(offer.first_due_date).toLocaleDateString('pt-BR', {timeZone: "UTC"}) }}
    </p>
    <p class="discount-statement">
      {{ $t('openParentheses') }}
      {{ $t('discountStatement', {discount: offer.discount}) }}
      <span v-if="offer.principal_discount < 1">{{ $t('closeParentheses') }}</span>
    </p>
    <p v-if="offer.principal_discount > 0" class="discount-statement">
      {{ $t('discountStatementWithPrincipal', {principalDiscount: offer.principal_discount}) }}
      <span>{{ $t('closeParentheses') }}</span>
    </p>
    <div class="select-offer-container">
      <v-btn class="select-offer-button" @click="onSelectOffer(offer)">
        {{ $t('select') }}
      </v-btn>
    </div>
  </v-sheet>
</template>

<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
    #payment-plan-offers-desktop {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
    }
    .offer-sheet {
      color: black;
      padding: 1em;
    }
    .outer-offer-sheet {
      display: block;
      margin: 1em 0.25em;
      box-sizing: border-box;
      position: relative;
      width: calc(100% - 0.5em);
      .discount-statement {
        font-size: 0.7em;
      }
    }
    .inner-offer-sheet {
      padding: 0;
      text-align: center;
      width: 100%;
      display: block;
      margin: 0 auto 1em auto;
      .installment-headline-container {
        position: relative;
        padding: 1.5em 0.9em;
        padding-bottom: 0.5em;
      }
      .installment-details {
        height: 90px;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        text-align: center;
        background-color: #dff0d8;
        span {
          font-weight: bold;
        }
      }
      #no-remaining-installments {
        margin-top: 20px;
      }
      #remaining-installments-details {
        margin-top: -15px;
      }
    }
    .installment-amount-headline {
      font-family: $font-family-sans-serif;
      font-size: 1.5em;
    }
    .value-of-first-installment {
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }
    .first-installment-due-date {
      display: block;
      width: 100%;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .select-offer-container {
      width: 100%;
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
      padding: 0.8em 1em;
      .select-offer-button {
        margin: 0 auto;
        background-color: $simplic-yellow !important;
        color: $simplic-copy;
        padding: 1.2em 2em !important;
        width: 100%;
        font-weight: bold;
        font-size: 1.2em;
        text-transform: capitalize;
      }
    }
</style>
<i18n>
{
  "pt": {
    "valueOfFirstInstallment": "Vencimento 1ª parcela:",
    "select": "Selecionar",
    "installmentDetails": "{offerCount} x {installmentAmount}",
    "downPaymentDetails": "Entrada de {downPaymentAmount}",
    "remainingInstallmentsDetails": "+ {remainingInstallments} x {installmentAmount}",
    "openParentheses": "(",
    "closeParentheses": ")",
    "discountStatement": "{discount}% de desconto nos juros de mora e multa por atraso",
    "discountStatementWithPrincipal": "{principalDiscount}% de desconto sobre o valor da parcela original",
    "discountTooltip": "{discount}% Off - Juros",
    "discountTooltipWithPrincipal": "{principalDiscount}% Off - Parcela"
  }
}
</i18n>
