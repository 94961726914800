import { ComputedRef } from "vue"

export type NullableValidationObject =
  | number[]
  | number
  | string[]
  | boolean[]
  | boolean
  | Date
  | Record<string, unknown>
  | string
  | null
  | undefined
  | ComputedRef<unknown>

const maxLengthFactory = (maxLength: number, errorMsg: string, isMaxLength?: (value: string) => boolean): (value: string) => boolean | string => {
  if (isMaxLength == null) {
    return (value: string) => value.length <= maxLength || errorMsg
  } else {
    return (value: string)  => isMaxLength(value) || errorMsg
  }
}

const required = (value: string): boolean | string => {
  return (value != "" && value != undefined) || "Esse campo é obrigatório."
}

export { maxLengthFactory, required }
