<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref, withDefaults, defineProps } from "vue"
import { useAccountDetailsStore } from "@/store"
import RefinanceQuotes from "@/components/account-home/RefinanceQuotes.vue"
import lodash from "lodash"

type RefinanceDetailsProps = {
  useV2Layout?: boolean
}

withDefaults(defineProps<RefinanceDetailsProps>(), {
  useV2Layout: false
})

const { eligibleProducts } = useAccountDetailsStore()
const productDetails = eligibleProducts.value?.eligible_offers?.top_up_refinance?.eligible_product_details
const quotes = eligibleProducts.value?.eligible_offers?.top_up_refinance?.quotes
const sortedQuotes = lodash.orderBy(quotes, ["InstallmentsRequested", ["asc"]])
const currentInstallment = eligibleProducts.value?.eligible_offers?.top_up_refinance?.eligible_product_details.current_installment_amount

const windowWidth = ref(window.innerWidth)
const onResize = function() {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  nextTick(() => {
    window.addEventListener("resize", onResize)
  })
})

onUnmounted(() => {
  window.removeEventListener("resize", onResize)
})

const isMobile = function() {
  return windowWidth.value < 700
}
</script>

<template>
  <div id="refinance">
    <h1 id="title">{{ $t("title") }}</h1>
    <hr />
    <!-- v2 layout -->
    <div v-if="useV2Layout" id="v2-layout">
      <div id="information-paragraph">
        <p>{{ $t("preinformation") }}</p>
      </div>
      <hr />
      <refinance-quotes :quotes="sortedQuotes" :product-details="productDetails" :show-carousel="isMobile()" :current-installment-amount="currentInstallment" />
      <p>{{ $t("information") }}</p>
    </div>
    <!-- v1 layout -->
    <div v-else id="v1-layout">
      <div id="information-paragraph">
        <p>{{ $t("preinformation") }}</p>
        <p>{{ $t("information") }}</p>
      </div>
      <hr />
      <refinance-quotes :quotes="sortedQuotes" :product-details="productDetails" :show-carousel="isMobile()" :current-installment-amount="currentInstallment" />
    </div>
  </div>
</template>

<style scoped lang="scss">
#refinance {
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: 1em 0.25em;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;
}

#title {
  font-size: 26px;
  padding: 15px;
  color: $simplic-dark-text;
}

#information-paragraph {
  background-color: $simplic-background;
  text-align: left;
  padding: 15px;
  color: $simplic-dark-text;
}
</style>

<i18n>
{
  "pt": {
    "title": "Aproveite crédito adicional agora mesmo",
    "preinformation": "Com o refinanciamento, é possível juntar o saldo atual do seu empréstimo com um valor adicional em um novo empréstimo.",
    "information": "Ao aceitar esta oferta, você autoriza o débito automático das parcelas deste contrato de Refinanciamento, mesmo após o vencimento, por meio de lançamentos parciais e sobre o limite de crédito de sua conta (cheque especial), se aplicável."
  }
}
</i18n>
