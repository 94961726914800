import { client } from "@/api/secure-service"
import { getWhatsAppConsent } from "@/api/secure/account-home-service"

const consentTextKey = "/simplic/whatsapp_consent_text/active"

export interface WhatsAppConsentResponseConfiguration {
  consent_text: string;
  metadata: Record<string, unknown>; // Refers to any object
}

export interface WhatsAppConsentResponse {
  configuration: WhatsAppConsentResponseConfiguration;
}

export async function getWhatsAppConsentText (): Promise<WhatsAppConsentResponse> {
  let response: WhatsAppConsentResponse
  try {
    response = await client().get(process.env.VUE_APP_CONFIGURATION_SERVICE_CDN_URL + consentTextKey)
    if (response?.configuration?.consent_text != null) {
      return Promise.resolve(response)
    } else {
      return Promise.reject("invalid response")
    }
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function getWhatsAppConsentAgreed (accountID: number): Promise<boolean> {
  try {
    const data = await getWhatsAppConsent(accountID?.toString())
    return Promise.resolve(data != undefined && data.agreed)
  } catch(err) {
    return Promise.reject(err)
  }
}
