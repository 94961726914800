<template>
  <div v-if="isLoggedIn">
    <mobile-header-auth v-if="$vuetify.breakpoint.mobile" :key="$route.fullPath" />
    <desktop-header-auth v-else :key="$route.fullPath" />
  </div>
  <div v-else>
    <mobile-header-un-auth v-if="$vuetify.breakpoint.mobile" />
    <desktop-header-un-auth v-else />
  </div>
</template>
<script lang="ts">
import MobileHeaderAuth from "./MobileHeaderAuth.vue"
import MobileHeaderUnAuth from "./MobileHeaderUnAuth.vue"
import DesktopHeaderAuth from "./DesktopHeaderAuth.vue"
import DesktopHeaderUnAuth from "./DesktopHeaderUnAuth.vue"
import { defineComponent } from "vue"
import { useSessionStore } from "@/store"

export default defineComponent({
  name: "SimplicHeader",
  components: { MobileHeaderAuth, MobileHeaderUnAuth, DesktopHeaderAuth, DesktopHeaderUnAuth },
  setup() {
    const { isLoggedIn } = useSessionStore()

    return {
      isLoggedIn
    }
  }
})
</script>
