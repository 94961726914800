<script setup lang="ts">
import { computed, ref, watch, withDefaults, defineEmits, defineProps } from "vue"

type NumberSelectorProps = {
  start: number;
  end: number;
  edited?: boolean;
  disabled?: boolean;
  value?: string;
  label?: string;
  rules?: [];
  prependIcon?: string;
  prependIconEdit?: string;
}

const props = withDefaults(defineProps<NumberSelectorProps>(), {
  edited: false,
  disabled: false,
  value: "",
  label: "Pick a number",
  rules: () => [],
  prependIcon: "",
  prependIconEdit: ""
})

const emit = defineEmits<{
  (event: "select", value: string, isEdited: boolean): void
}>()

const initialValue = ref<string>(props.value)
const currentSelection = ref<string>(props.value)
const isEdited = ref<boolean>(false)
const items = []

for (let i = props.start; i <= props.end; i++) {
  items.push({ name: i, value: i.toString() })
}

watch(() => props.edited, (newValue) => {
  isEdited.value = newValue
  if (!newValue) initialValue.value = currentSelection.value
})

const onChange = (value: string) => {
  isEdited.value = value !== initialValue.value
  currentSelection.value = value
  emit("select", value, isEdited.value)
}

const prependIconValue = computed(() => {
  if (props.prependIconEdit !== "") {
    return isEdited.value ? props.prependIconEdit : props.prependIcon
  }

  return props.prependIcon
})

defineExpose({
  prependIconValue,
  items,
  onChange
})

</script>

<template>
  <v-text-field
    v-if="disabled"
    label="Tipo de Conta"
    :value="items[items.findIndex((element) => element.value === currentSelection)].value"
    :prepend-icon="prependIconValue"
    :readonly="true"
    :messages="['só leitura']"
    :persistent-placeholder="true" />
  <v-select
    v-else
    :items="items"
    :label="label"
    :value="value"
    item-text="name"
    item-value="value"
    :rules="rules"
    :outlined="true"
    :prepend-icon="prependIconValue"
    @change="onChange" />
</template>

<style scoped lang="scss">

</style>
