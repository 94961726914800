<script setup lang="ts">
import { onMounted, Ref, ref, defineProps } from "vue"
import { useAccountDetailsStore } from "@/store"

type LoanAppStatusDetailsCardProps = {
  loanAppCardView: string;
  showLoanAppCard: boolean;
}

defineProps<LoanAppStatusDetailsCardProps>()

const { loanApplications, activities } = useAccountDetailsStore()
const loanAppCreatedAt: Ref<string> = ref("")

const getLoanAppCreatedAt = () => {
  if (loanApplications && loanApplications.value) {
    const date = loanApplications.value[0].created_at
    const formatDate = new Date(date)
    const dateString = formatDate.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
    loanAppCreatedAt.value = dateString.substring(0, 10)
  }
}

onMounted(() => {
  getLoanAppCreatedAt()
})
</script>

<template>
  <table v-if="showLoanAppCard">
    <tr>
      <th>
        <span class="icon"><img class="icon-sizing" alt="checkmark" src="~@/assets/images/svg/icon-checkmark.svg" /></span>
        <p class="table-headers non-bold-text">{{ loanAppCreatedAt }}</p>
      </th>
      <td class="bold-text table-values">{{ $t('applied') }}</td>
    </tr>
    <tr v-for="activity in activities" :key="activity.name">
      <hr aria-hidden="true" />
      <th>
        <span v-if="activity.status == 'in_review'" class="lp-icon"><img class="lp-icon-sizing" :alt="$t('inReview')" src="~@/assets/images/svg/status-in-review.png" /></span>
        <span v-else-if="activity.status == 'invalid'" class="lp-icon"><img class="lp-icon-sizing" :alt="$t('invalid')" src="~@/assets/images/svg/status-invalid.svg" /></span>
        <span v-else-if="activity.status == 'success' || activity.status == 'failure'" class="lp-complete-icon"><img class="lp-complete-icon-sizing" :alt="$t('sent')" src="~@/assets/images/svg/status-complete-no-box.png" /></span>
        <span v-else class="lp-icon"><img class="lp-icon-sizing" :alt="$t('needsValidation')" src="~@/assets/images/svg/status-default.svg" /></span>
        <p v-if="activity.status == 'in_review'" class="table-headers non-bold-text">{{ $t('inReview') }}</p>
        <p v-else-if="activity.status == 'invalid'" class="table-headers non-bold-text">{{ $t('invalid') }}</p>
        <p v-else-if="activity.status == 'success' || activity.status == 'failure'" class="table-headers non-bold-text">{{ $t('sent') }}</p>
        <p v-else class="table-headers non-bold-text">{{ $t('needsValidation') }}</p>
      </th>
      <td v-if="activity.name == 'income_verification'" class="bold-text table-values">{{ $t('verifyIncome') }}</td>
      <td v-if="activity.name == 'identity_verification'" class="bold-text table-values">{{ $t('verifyIdentity') }}</td>
      <td v-if="activity.name == 'phone_verification'" class="bold-text table-values">{{ $t('verifyPhone') }}</td>
    </tr>
    <tr>
      <hr aria-hidden="true" />
      <th>
        <span v-if="loanAppCardView == 'approved' || loanAppCardView == 'funded'" class="icon"><img class="icon-sizing" alt="checkmark" src="~@/assets/images/svg/icon-checkmark.svg" /></span>
        <span v-if="loanAppCardView == 'declined' || loanAppCardView == 'withdrawn'" class="icon"><img class="icon-sizing" alt="red x icon" src="~@/assets/images/svg/red-x.svg" /></span>
        <p v-if="loanAppCardView != 'lp' && loanAppCardView != 'withdrawn'" class="table-headers non-bold-text">{{ $t('done') }}</p>
        <p v-if="loanAppCardView == 'withdrawn'" class="table-headers non-bold-text">{{ $t('notDone') }}</p>
        <p v-if="loanAppCardView == 'lp'" class="table-headers non-bold-text dash">{{ $t('dash') }}</p>
      </th>
      <td v-if="loanAppCardView == 'approved' || loanAppCardView == 'funded'" class="bold-text table-values">{{ $t('approved') }}</td>
      <td v-if="loanAppCardView == 'lp'" class="non-bold-text table-values">{{ $t('approved') }}</td>
      <td v-if="loanAppCardView == 'withdrawn'" class="bold-text table-values">{{ $t('withdrawn') }}</td>
      <td v-if="loanAppCardView == 'declined'" class="bold-text table-values">{{ $t('declined') }}</td>
    </tr>
    <hr v-if="loanAppCardView == 'approved' || loanAppCardView == 'lp' || loanAppCardView == 'funded'" aria-hidden="true" />
    <tr v-if="loanAppCardView == 'approved' || loanAppCardView == 'lp' || loanAppCardView == 'funded'">
      <th>
        <p v-if="loanAppCardView == 'approved' || loanAppCardView == 'lp'" class="table-headers non-bold-text dash">{{ $t('dash') }}</p>
        <span v-if="loanAppCardView == 'funded'" class="icon"><img class="icon-sizing" alt="pending icon" src="~@/assets/images/svg/icon-pending.svg" /></span>
        <p v-if="loanAppCardView == 'funded'" class="table-headers non-bold-text">{{ $t('pending') }}</p>
      </th>
      <td class="non-bold-text table-values">{{ $t('funded') }}</td>
    </tr>
  </table>
</template>

<style lang="scss" scoped>
table {
  background-color: $simplic-background;
  width: 100%;
  padding: 2px;

  hr {
    background-color: $simplic-light-gray;
    border: none;
    height: 1px;
    width: 95%;
    margin: 0 auto;
  }

  .table-headers {
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
  }

  .table-values {
    padding-right: 8px;
    padding-top: 8px;
  }

  .bold-text {
    font: normal normal bold 14px/20px Open Sans;
    color: $simplic-dark-text;
  }

  .non-bold-text {
    font: normal normal normal 14px/20px Open Sans;
    color: $simplic-dark-text;
  }

  .dash {
    padding-left: 32px;
  }

  th {
    float: left;
    text-align: left;
    display: flex;

    .icon {
      padding: 7px 0 0 8px;
    }

    .icon-sizing {
      height: 13px;
    }

    .lp-icon-sizing {
      height: 17px;
    }

    .lp-icon {
      padding: 7px 0 0 4px;
      + p {
        display: none;
        @media (min-width: 415px) {
          display: block;
        }
      }
    }

    .lp-complete-icon-sizing {
      height: 18px;
    }

    .lp-complete-icon {
      padding: 8px 0 0 4px;
    }
  }

  td {
    float: right;
    text-align: right;
  }
}
</style>
<i18n>
{
  "pt": {
    "dash": "—",
    "applied": "Solicitado",
    "done": "Concluído",
    "notDone": "Não Concluído",
    "verifyIdentity": "Comprovação de identidade",
    "verifyIncome": "Comprovação de renda",
    "verifyPhone": "Validação de telefone",
    "needsValidation": "Necessário validação",
    "approved": "Análise dos documentos",
    "pending": "Pendente",
    "funded": "Depósito",
    "withdrawn": "Cancelada",
    "declined": "Não aprovado",
    "inReview": "Em análise",
    "invalid": "Inválido",
    "sent": "Enviado"
  }
}
</i18n>
