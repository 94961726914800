<script setup lang="ts">
import { Ref, ref, onMounted, defineProps, defineExpose } from "vue"
import { Obligation, LoanPayment, Loan, LoanHistoryInfo } from "@/api/secure/account-home-service"

type LoanHistoryCardProps = {
  loans: Loan[]
}

const props = defineProps<LoanHistoryCardProps>()

const expand: Ref<boolean[]> = ref([])
const loansInfo: Ref<LoanHistoryInfo[]> = ref([])

const convertDate = (input: string) => {
  const date = new Date(input)
  const dateString = date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
  return dateString.substring(0, 10)
}

const getPaidOffDate = (index: number) => {
  const payments: LoanPayment[] = props.loans[index].payments
  payments.sort(function(a, b){
    return +new Date(b.effective_date) - +new Date(a.effective_date)
  })
  return convertDate(payments[0].effective_date)
}

const getFinalDueDate = (index: number) => {
  const obligations: Obligation[] = props.loans[index].obligations
  obligations.sort(function(a, b){
    return +new Date(b.due_date) - +new Date(a.due_date)
  })
  return convertDate(obligations[0].due_date)
}

const getLoansArray = () => {
  for(let i = 0; i < props.loans.length; i++) {
    const info: LoanHistoryInfo = {
      paid_off_date: getPaidOffDate(i),
      loan_app_id: props.loans[i].loan_agreement.loan_application_id,
      loan_amount: parseFloat(props.loans[i].amount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
      created_at: convertDate(props.loans[i].created_at),
      final_due_date: getFinalDueDate(i)
    }
    loansInfo.value.push(info)
  }
}

const expandClick = (index: number) => {
  if (expand.value[index]) {
    expand.value[index] = false
    expand.value = [...expand.value]
    return
  }
  expand.value[index] = true
  expand.value = [...expand.value]
}

onMounted(() => {
  getLoansArray()
  for(let i = 0; i < props.loans.length; i++) {
    expand.value.push(false)
  }
})

defineExpose({
  expandClick,
  expand
})
</script>

<template>
  <div id="history-container">
    <div v-for="(loan, index) in loansInfo" :key="loan.loan_app_id">
      <p id="date">{{ $t('paidOffAt') }} {{ loan.paid_off_date }}</p>
      <table>
        <thead>
          <tr>
            <th>
              <p class="table-headers first-row">{{ $t('loanNumber') }}</p>
            </th>
            <td class="table-values first-row">{{ loan.loan_app_id }}</td>
          </tr>
          <hr aria-hidden="true" />
          <tr class="divide-and-arrow">
            <td>
              <span v-if="!expand[index]" class="expandable-arrow" @click="expandClick(index)"><img alt="arrow to expand" src="~@/assets/images/svg/collapsed_table_icon.svg" /></span>
              <span v-if="expand[index]" class="expandable-arrow" @click="expandClick(index)"><img alt="arrow to collapse" src="~@/assets/images/svg/expanded_table_icon.svg" /></span>
            </td>
          </tr>
          <tr>
            <th>
              <p class="table-headers">{{ $t('status') }}</p>
            </th>
            <td class="table-values">{{ $t('paidOff') }}</td>
          </tr>
        </thead>
        <tbody v-if="expand[index]">
          <hr class="expanded-row" aria-hidden="true" />
          <tr>
            <th>
              <p class="table-headers">{{ $t('loanAmount') }}</p>
            </th>
            <td class="table-values-italics">{{ loan.loan_amount }}</td>
          </tr>
          <hr class="expanded-row" aria-hidden="true" />
          <tr>
            <th>
              <p class="table-headers">{{ $t('fundingDate') }}</p>
            </th>
            <td class="table-values">{{ loan.created_at }}</td>
          </tr>
          <hr class="expanded-row" aria-hidden="true" />
          <tr>
            <th>
              <p class="table-headers">{{ $t('finalDueDate') }}</p>
            </th>
            <td class="table-values">{{ loan.final_due_date }}</td>
          </tr>
        </tbody>
      </table>
      <div id="spacer" aria-hidden="true" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#history-container {
  display: block;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  background-color: $simplic-background;

  #date {
    font: normal normal bold 12px/20px Open Sans;
    color: $simplic-dark-text;
    margin: auto;
    padding-top: 10px;
  }

  table {
    width: 100%;
    padding: 2px;

    hr {
      background-color: $simplic-light-gray;
      border: none;
      height: 1px;
      width: 93%;
      margin: 0 auto;
    }

    .divide-and-arrow {
      font-size: 0;

      .expandable-arrow {
        float: right;
        padding-right: 3px;
      }
    }

    .first-row {
      padding-top: 8px !important;
    }

    .table-headers {
      font: normal normal normal 12px/20px Open Sans;
      color: $simplic-dark-text;
      margin: 0 auto;
      padding-top: 3px;
      padding-left: 30px;
    }

    .table-values {
      font: normal normal bold 12px/20px Open Sans;
      color: $simplic-dark-text;
      padding-right: 32px;
      padding-top: 3px;
    }

    .table-values-italics {
      font: italic normal normal 12px/20px Open Sans;
      color: $simplic-dark-text;
      padding-right: 32px;
      padding-top: 3px;
    }

    .expanded-row {
      margin-bottom: 8px;
    }

    th {
      float: left;
      text-align: left;
      display: flex;
    }

    td {
      float: right;
      text-align: right;
    }
  }

  #spacer {
    height: 10px;
    background-color: $simplic-white;
  }

  @media (max-width: 550px) {
    .divide-and-arrow {
      .expandable-arrow {
        padding-right: 0 !important;
      }
    }
  }

  @media (max-width: 420px) {
    hr {
      width: 90% !important;
    }
  }
}
</style>
<i18n>
{
  "pt": {
    "loanNumber": "Proposta",
    "status": "Status",
    "paidOff": "Quitado",
    "loanAmount": "Valor do empréstimo",
    "fundingDate": "Data do empréstimo",
    "finalDueDate": "Data da última parcela",
    "paidOffAt": "Quitado em"
  }
}
</i18n>
