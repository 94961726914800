<template>
  <div>
    <v-app-bar id="simplic-header" app height="100px">
      <v-container id="simplic-header--container">
        <router-link to="/account_home"><img id="simplic-logo" :alt="$t('simplic')" src="~@/assets/header-logo.png" /></router-link>
        <v-spacer />
        <div id="simplic-header--links">
          <router-link to="/como-funciona">
            {{ $t("howItWorks") }}
          </router-link>
          <router-link to="/quem_somos">
            {{ $t("whoWeAre") }}
          </router-link>
          <router-link to="/faq">
            {{ $t("faq") }}
          </router-link>
          <router-link to="/blog">
            {{ $t("blog") }}
          </router-link>
          <v-btn
            id="simplic-header--login"
            class="ml-3"
            dark
            @click="logIn">
            {{ $t("login") }}
          </v-btn>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue"
import { useSessionStore } from "@/store"

export default defineComponent({
  name: "DesktopHeaderUnAuth",
  setup() {
    const { logIn } = useSessionStore()

    return {
      logIn
    }
  }
})
</script>
<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
  #simplic-logo {
    display: block;
  }
  #simplic-header {
    background-color: $simplic-white;
  }
  #simplic-header--container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 900px;
  }
  #simplic-header--login {
    background-color: $simplic-yellow;
    color: $simplic-copy;
    font-weight: bold;
  }
  #simplic-header--links {
    display: flex;
    align-items: baseline;

    a {
      color: $simplic-dark-text;
      text-decoration: none;
      font-weight: bold;
      letter-spacing: 0.1em;
      margin-right: 1em;
      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
<i18n>
{
  "pt": {
    "simplic": "Simplic",
    "howItWorks": "Como Funciona",
    "whoWeAre": "Quem Somos",
    "faq": "Ajuda",
    "blog": "Blog",
    "login": "Login"
  }
}
</i18n>
