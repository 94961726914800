<script setup lang="ts">
import { ref, Ref, onMounted, getCurrentInstance, withDefaults, defineProps } from "vue"
import PaymentCard from "@/components/account-home/PaymentCard.vue"
import { useAccountDetailsStore } from "@/store"
import { PaymentCardDetails } from "@/api/secure/account-home-service"
import { Payment } from "@/api/secure/account-home-service"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

type PaymentScheduleCardProps = {
  paymentData?: object;
}

withDefaults(defineProps<PaymentScheduleCardProps>(), {
  paymentData: undefined
})

const { paymentData } = useAccountDetailsStore()
const installments: Ref<PaymentCardDetails[]> = ref([])
const loanStatusPath: Ref<string> = ref("/loan-status")
const loaded: Ref<boolean> = ref(false)
const vueInstance = getCurrentInstance()

const convertDate = (input: string) => {
  const date = new Date(input)
  const dateString = date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
  return dateString.substring(0, 10)
}

const populateInstallments = () => {
  const unpaid: null|Payment[] = paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "" ? paymentData.value.unpaid_installments : null
  if(unpaid != null) {
    unpaid.sort((a, b) => {
      return parseInt(a.installment_number) - parseInt(b.installment_number)
    })
  }

  if(paymentData.value?.installment_amount != "" && paymentData.value?.unpaid_due_installments && paymentData.value?.unpaid_due_installments.length > 0) { // in default
    const unpaidDue: null|Payment[] = paymentData.value ? paymentData.value.unpaid_due_installments : null
    if(unpaidDue) {
      unpaidDue.sort((a, b) => {
        return parseInt(a.installment_number) - parseInt(b.installment_number)
      })
    }

    if(unpaidDue != null && unpaidDue.length == 1) {
      installments.value.push({ date: convertDate(unpaidDue[0].installment_due_date), status: "pending", installment_amount: unpaidDue[0].payment_amount })

      if(unpaid != null) {
        let count = 0
        for(let i = 0; i < unpaid.length; i++) {
          if(unpaid[i].installment_number !== unpaidDue[0].installment_number && count < 3) {
            installments.value.push({ date: convertDate(unpaid[i].installment_due_date), status: "scheduled", installment_amount: unpaid[i].payment_amount })
            count++
          }
        }
      }
    } else if(unpaidDue != null && unpaidDue.length == 2) {
      installments.value.push({ date: convertDate(unpaidDue[0].installment_due_date), status: "pending", installment_amount: unpaidDue[0].payment_amount })
      installments.value.push({ date: convertDate(unpaidDue[1].installment_due_date), status: "pending", installment_amount: unpaidDue[1].payment_amount })

      if(unpaid != null) {
        let count = 0
        for(let i = 0; i < unpaid.length; i++) {
          if(unpaid[i].installment_number !== unpaidDue[0].installment_number && unpaid[i].installment_number !== unpaidDue[1].installment_number && count < 2) {
            installments.value.push({ date: convertDate(unpaid[i].installment_due_date), status: "scheduled", installment_amount: unpaid[i].payment_amount })
            count++
          }
        }
      }
    } else if(unpaidDue != null && unpaidDue.length > 2) {
      installments.value.push({ date: convertDate(unpaidDue[0].installment_due_date), status: "pending", installment_amount: unpaidDue[0].payment_amount })
      installments.value.push({ date: convertDate(unpaidDue[1].installment_due_date), status: "pending", installment_amount: unpaidDue[1].payment_amount })
      installments.value.push({ date: convertDate(unpaidDue[2].installment_due_date), status: "pending", installment_amount: unpaidDue[2].payment_amount })
    }
  } else if (paymentData.value?.installment_amount != "") { // not in default
    const paid: null|Payment[] = paymentData.value ? paymentData.value.paid_installments : null
    const paidLength = paid ? paid.length : 0
    if(paid) {
      paid.sort((a, b) => {
        return parseInt(a.installment_number) - parseInt(b.installment_number)
      })
    }

    if(paid != null && paidLength == 0 && unpaid != null && unpaid.length > 2) {
      installments.value.push({ date: convertDate(unpaid[0].installment_due_date), status: "scheduled", installment_amount: unpaid[0].payment_amount })
      installments.value.push({ date: convertDate(unpaid[1].installment_due_date), status: "scheduled", installment_amount: unpaid[1].payment_amount })
      installments.value.push({ date: convertDate(unpaid[2].installment_due_date), status: "scheduled", installment_amount: unpaid[2].payment_amount })
    } else if(paid != null && paidLength == 1 && unpaid != null && unpaid.length > 1) {
      installments.value.push({ date: convertDate(paid[paidLength - 1].installment_due_date), status: "complete", installment_amount: paid[0].payment_amount })
      installments.value.push({ date: convertDate(unpaid[0].installment_due_date), status: "scheduled", installment_amount: unpaid[0].payment_amount })
      installments.value.push({ date: convertDate(unpaid[1].installment_due_date), status: "scheduled", installment_amount: unpaid[1].payment_amount })
    } else if(paid != null && paidLength > 1 && unpaid != null && unpaid.length > 0) {
      installments.value.push({ date: convertDate(paid[paidLength - 2].installment_due_date), status: "complete", installment_amount: paid[0].payment_amount })
      installments.value.push({ date: convertDate(paid[paidLength - 1].installment_due_date), status: "complete", installment_amount: paid[1].payment_amount })
      installments.value.push({ date: convertDate(unpaid[0].installment_due_date), status: "scheduled", installment_amount: unpaid[0].payment_amount })
    }
  }
}

onMounted(() => {
  loaded.value = false
  populateInstallments()
  loaded.value = true

  if(loaded.value && paymentData && paymentData.value && paymentData.value.installment_amount != "") {
    tagRecordings((vueInstance as unknown) as VueWithHotjar, ["payment_schedule_card_displayed"])
  }
})
</script>

<template>
  <v-sheet
    v-if="loaded && paymentData && paymentData.installment_amount != ''"
    id="payment-schedule-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <p id="payment-schedule--header">
      {{ $t('paymentSchedule') }}
    </p>
    <hr />
    <div v-if="loaded && installments.length > 0">
      <div v-for="installment in installments" :key="installment.payment_id">
        <payment-card :date="installment.date" :status="installment.status" :installment-amount="installment.installment_amount" />
      </div>
    </div>
    <router-link
      :to="loanStatusPath">
      <button
        id="payment-schedule-button"
        type="button">
        <span id="view-more-text-span">{{ $t('viewMore') }}</span>
        <span id="arrow-img-span"><img alt="arrow" src="~@/assets/arrow-back-gray.svg" /></span>
      </button>
    </router-link>
  </v-sheet>
</template>

<style lang="scss" scoped>
#payment-schedule-container {
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: 1em 0.25em;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;

  #payment-schedule--header {
    font: normal normal bold 20px/24px Open Sans;
    padding: 20px 0;
    margin: 0 auto;
    color: $simplic-dark-text;
  }

  button {
    background-color: $simplic-yellow;
    padding: 10px;
    margin: 20px 20px;
    width: 90%;
    border-radius: 8px;

    #view-more-text-span {
      font: normal normal bold 16px/24px Open Sans;
      float: left;
      color: $simplic-dark-text;
    }

    #arrow-img-span {
      float: right;
      padding-top: 3px;
    }
  }

  router-link {
    text-decoration: none;
    color: inherit;
  }
}
</style>
<i18n>
{
  "pt": {
    "paymentSchedule": "Próximas parcelas",
    "viewMore": "Ver detalhes"
  }
}
</i18n>
