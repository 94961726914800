<script setup lang="ts">
import { computed, onMounted, withDefaults, defineProps, defineEmits } from "vue"
import PaymentPlanOffersLastCard from "@/components/payment-plans/OffersLastCard.vue"
import PaymentPlanOffersCard from "@/components/payment-plans/OffersCard.vue"
import OffersLoadingSkeletonMobile from "@/components/skeleton-loaders/payment-plans/OffersLoadingSkeletonMobile.vue"
import SimplicCarousel from "@/components/SimplicCarousel.vue"
import { useAccountStore } from "@/store"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"
import { postUniversalMetricsEvent } from "@/api/secure"
import store from "@/store"

type OffersCarouselProps = {
  id?: string;
  loading?: boolean;
}

withDefaults(defineProps<OffersCarouselProps>(), {
  id: "",
  loading: false
})

const emit = defineEmits<{
  (event: "offer-selected", offer: PaymentPlanOffer): void
}>()

const { accountID } = useAccountStore()

const onSelectOffer = (offer: PaymentPlanOffer) => {
  emit("offer-selected", offer)
}

const offers = computed(() => store.getters["paymentPlans/offersForDisplay"])

onMounted(() => {
  postUniversalMetricsEvent(accountID.value, "saw_payment_plan_offers")
})
</script>

<template>
  <div :id="id">
    <div v-if="loading || !offers">
      <offers-loading-skeleton-mobile :show-datepicker="false" />
    </div>
    <div v-else>
      <simplic-carousel :loading="loading">
        <v-carousel-item
          v-for="offer in offers"
          :key="offer.payment_plan_offer_id">
          <payment-plan-offers-card
            id="payment-plan-offers-card"
            :offer="offer"
            :single-offer="offers.length === 1"
            @offer-selected="onSelectOffer" />
        </v-carousel-item>
        <v-carousel-item>
          <payment-plan-offers-last-card />
        </v-carousel-item>
      </simplic-carousel>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
    .offer-sheet {
      color: black;
      padding: 1em;
    }
    .outer-offer-sheet {
      display: block;
      margin: 3em 0.25em;
      box-sizing: border-box;
      position: relative;
    }
    .inner-offer-sheet {
      padding: 0;
      text-align: center;
      width: 100%;
      display: block;
      margin: 0 auto 2em auto;
      h3 {
        font-size: 2em;
      }
      .installment-headline-container {
        position: relative;
        padding: 1em;
      }
      .installment-details {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: #dff0d8;
        span {
          font-weight: bold;
        }
      }
    }
    .installment-amount-headline {
      letter-spacing: 0.05em;
    }
    .installment-amount-subtitle {
      font-size: 0.85em;
    }
    .value-of-first-installment {
      display: block;
      width: 100%;
    }
    .first-installment-due-date {
      display: block;
      width: 100%;
      font-weight: bold;
    }
    .select-offer-container {
      position: absolute;
      padding: 0 1em 0 1em;
      bottom: 1em;
      left: 0;
      right: 0;
      width: 100%;
      .select-offer-button {
        margin: 0 auto;
        display: block;
        padding: 2em 3em;
        background-color: $simplic-yellow !important;
        color: $simplic-copy;
        width: 100%;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
</style>
