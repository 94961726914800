<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "LowerMyPaymentCard",
  setup() {
    console.log("lower my payment card setup")
    const vueInstance = getCurrentInstance()
    const lowerMyPaymentPath = ref<string>("/lower-my-payment")

    onMounted(() => {
      tagRecordings((vueInstance as unknown) as VueWithHotjar, ["lower_my_payment_card_displayed"])
    })

    return {
      lowerMyPaymentPath
    }
  }
})
</script>

<template>
  <v-sheet
    id="lower-my-payment-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <p id="title">{{ $t('title') }}</p>
    <hr />
    <div id="information-box">
      <div>
        <p> {{ $t('information') }}  </p>
        <img aria-hidden="true" src="~@/assets/images/refinance-arrow.png" width="87" height="108" alt="refinance-arrow" />
      </div>
      <p id="sub-information"> {{ $t('subInformation') }}</p>
    </div>
    <div id="lower-my-payment-button">
      <router-link
        :to="lowerMyPaymentPath">
        <button
          type="button">
          <span id="button-text">{{ $t('button') }}</span>
          <span id="button-image"><img alt="arrow" src="~@/assets/arrow-back-gray.svg" /></span>
        </button>
      </router-link>
    </div>
  </v-sheet>
</template>

<style lang="scss" scoped>
#lower-my-payment-container {
  display: block;
  margin: 1em 0.25em;
  box-sizing: border-box;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;
}

#title {
  font: normal normal bold 20px/24px Open Sans;
  padding: 20px 0;
  margin: 0 auto;
  color: $simplic-dark-text;
}

#information-box {
  background-color: $simplic-background;
  padding: 5px 25px 5px;
  width: 100%;
  #sub-information{
    font: normal normal normal 14px Open Sans;
    padding: 0;
    max-width: 100%;
  }
  div {
    display: flex;
  }
  p {
    color: $simplic-dark-text;
    font: normal normal bold 24px "Bree Serif";
    text-align: left;
    max-width: 80%;
    padding-top: 15px;
  }
}

#lower-my-payment-button {
  button {
    background-color: $simplic-yellow;
    padding: 10px;
    margin: 20px 20px;
    width: 90%;
    border-radius: 8px;

    #button-text {
      font: normal normal bold 16px/24px Open Sans;
      float: left;
      color: $simplic-dark-text;
    }

    #button-image {
      float: right;
      padding-top: 3px;
    }
  }

  router-link {
    text-decoration: none;
    color: inherit;
  }
}
</style>
<i18n>
{
  "pt": {
    "title": "Renegociação",
    "information": "Reduza o valor das parcelas",
    "subInformation": "estendendo o seu contrato atual.",
    "button": "Eu quero!"
  }
}
</i18n>
