<script setup lang="ts">
import { ref, computed, watch, defineProps, withDefaults, defineEmits, defineExpose } from "vue"
import { InputValidationRules } from "vuetify"
import { useVuetify } from "@/lib/account/helpers"

type DatePickerProps = {
  id?: string;
  label?: string;
  value?: string;
  locale?: string;
  rules?: [];
}

const props = withDefaults(defineProps<DatePickerProps>(), {
  id: "",
  label: "",
  value: "",
  locale: "en",
  rules: () => []
})

const emit = defineEmits<{
  (event: "input", value: string): void
}>()

const internalValue = ref(props.value)
const menu = ref(false)
const vuetify = useVuetify()

const readonly = computed(() => {
  return !vuetify?.breakpoint.mdAndUp
})

const isoDate = computed({
  get(): string {
    return internalValue.value
  },
  set(newValue: string) {
    internalValue.value = newValue
    emit("input", newValue)
  }
})

const isoToLocalized = (iso: string, locale: string): string => {
  if (iso.length !== 10) {
    return ""
  }

  const [year, month, day] = iso.split("-")

  switch (locale) {
  case "pt":
    return `${day}/${month}/${year}`
  case "en":
  default:
    return `${month}/${day}/${year}`
  }
}

const localizedToIso = (localized: string, locale: string): string => {
  switch (locale) {
  case "pt":
    if (localized.length === 10) {
      const [day, month, year] = localized.split("/")
      return `${year}-${month}-${day}`
    }
    break
  case "en":
  default:
    if (localized.length === 10) {
      const [month, day, year] = localized.split("/")
      return `${year}-${month}-${day}`
    }
    break
  }

  return ""
}

const formattedDate = computed({
  get(): string {
    return isoToLocalized(internalValue.value, props.locale)
  },
  set(newValue: string) {
    const isoValue = localizedToIso(newValue, props.locale)
    internalValue.value = isoValue
    emit("input", isoValue)
  }
})

const modifiedRules = computed(() => {
  return (props.rules as InputValidationRules).map((validator) => {
    if(typeof validator === "function") {
      return (localizedDate: string) => validator(
        localizedToIso(localizedDate, props.locale)
      )
    } else {
      return validator
    }
  })
})

watch(() => props.value, (newValue: string) => {
  internalValue.value = newValue
})

const handleTabout = () => {
  menu.value = false
}

defineExpose({
  menu,
  isoDate,
  formattedDate,
  readonly,
  handleTabout,
  internalValue,
  modifiedRules
})
</script>

<template>
  <v-menu
    ref="menu"
    v-model="menu"
    role="menu"
    offset-y
    v-bind="$attrs"
    max-width="290px"
    min-width="290px">
    <template #activator="{ on }">
      <v-text-field
        :id="id"
        v-model="formattedDate"
        v-mask="'##/##/####'"
        :aria-activedescendent="menu"
        :label="label"
        prepend-icon="mdi-calendar"
        :readonly="readonly"
        autocomplete="off"
        maxlength="10"
        :rules="modifiedRules"
        v-bind="{...$attrs}"
        role="textbox"
        @keydown.tab="handleTabout"
        v-on="on" />
    </template>
    <v-date-picker
      :id="id"
      v-model="isoDate"
      role="menu"
      no-title
      v-bind="$attrs"
      reactive
      color="primary"
      :locale="locale"
      @input="menu = false" />
  </v-menu>
</template>
