<script setup lang="ts">
import { defineProps, onMounted, ref, Ref, withDefaults } from "vue"
import { useAccountDetailsStore } from "@/store"

type CurrentLoanDetailsProps = {
  mostRecentLoan?: object;
}

withDefaults(defineProps<CurrentLoanDetailsProps>(), {
  mostRecentLoan: null
})

const { paymentData } = useAccountDetailsStore()
const lastDueDate: Ref<string> = ref("")
const expand: Ref<boolean> = ref(false)

const seeMoreClick = () => {
  expand.value = !expand.value
}

const convertDate = (date: string) => {
  const dateOnly = date.substring(0, 10)
  const split = dateOnly.split("-") // year-month-day
  return split[2] + "/" + split[1] + "/" + split[0]
}

const translateStatus = (status: string) => {
  if(status.includes("paid")) {
    return "Quitado"
  } else if(status.includes("current")) {
    return "Atual"
  } else if(status.includes("approved") || status.includes("issued")) {
    return "Aprovada"
  } else if(status.includes("applied")) {
    return "Solicitação iniciada"
  } else if(status.includes("progress")) {
    return "Aguardando aprovação"
  } else if(status.includes("withdrawn")) {
    return "Cancelada"
  } else if(status.includes("decline")) {
    return "Rejeitada"
  } else {
    return status
  }
}

const calculateLastPaymentDate = () => {
  if (paymentData.value && paymentData.value.installment_amount != "") {
    const installments = paymentData.value.unpaid_installments
    installments.sort((a: any, b: any) => {
      return +new Date(a.installment_due_date) - +new Date(b.installment_due_date)
    })

    const paymentDate = new Date(installments[installments.length - 1].installment_due_date)

    lastDueDate.value = paymentDate.toISOString()
  }
}

onMounted(() => {
  calculateLastPaymentDate()
})

defineExpose({
  translateStatus,
  convertDate,
  seeMoreClick,
  expand
})
</script>

<template>
  <div>
    <p class="small-bold-text shift-left">{{ $t('loanAmount') }}</p>
    <p class="big-bold-text shift-left">{{ parseFloat(mostRecentLoan.amount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}</p>
    <table id="loan-details-table">
      <tr>
        <th>{{ $t('loanDate') }}</th>
        <td>{{ convertDate(mostRecentLoan.created_at) }}</td>
      </tr>
      <hr aria-hidden="true" />
      <tr>
        <th>{{ $t('lastDueDate') }}</th>
        <td>{{ convertDate(lastDueDate) }}</td>
      </tr>
      <hr aria-hidden="true" />
      <tr>
        <th>{{ $t('numberOfInstallments') }}</th>
        <td>{{ parseInt(paymentData.num_installments, 10) }} {{ $t('installments') }}</td>
      </tr>
      <hr aria-hidden="true" />
      <tr>
        <th>{{ $t('installmentAmount') }}</th>
        <td>{{ parseFloat(paymentData.installment_amount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}</td>
      </tr>
      <hr aria-hidden="true" />
    </table>
    <div id="more-links-container">
      <span id="see-more-link" class="link" @click="seeMoreClick"><p>{{ $t('seeMore') }}</p></span>
      <span v-if="!expand" class="expandable-arrow" @click="seeMoreClick"><img alt="arrow to expand" src="~@/assets/images/svg/collapsed_table_icon.svg" /></span>
      <span v-if="expand" class="expandable-arrow" @click="seeMoreClick"><img alt="arrow to collapse" src="~@/assets/images/svg/expanded_table_icon.svg" /></span>
    </div>
    <v-expand-transition>
      <p v-if="expand" id="link-container">
        <router-link id="opt-in-link" class="link" to="/opt_in">{{ $t('changePayment') }}</router-link>
        <br />
        <router-link id="contract-link" class="link" to="/contract">{{ $t('seeContract') }}</router-link>
      </p>
    </v-expand-transition>
  </div>
</template>

<style lang="scss" scoped>
#current-loans--header {
  font: normal normal bold 20px/24px Open Sans;
  padding: 20px 0;
  color: $simplic-dark-text;
}

.shift-left {
  text-align: left;
  padding-left: 4%;
}

.small-bold-text {
  font: normal normal bold 16px/24px Open Sans;
  color: $simplic-dark-text;
  padding-top: 15px;
  margin: 5px auto;
}

.big-bold-text {
  font: normal normal bold 24px/32px Open Sans;
  color: $simplic-dark-text;
}

#loan-details-table {
  background-color: $simplic-background;
  padding: 5px 4% 5px;
  width: 100%;

  hr {
    background-color: $simplic-light-gray;
    border: none;
    height: 1px;
    clear: left;
  }

  th {
    float: left;
    text-align: left;
    margin: 0;
    padding: 10px 0;
    font: normal normal normal 12px/20px Open Sans;
    color: $simplic-dark-text;
  }

  td {
    float: right;
    text-align: right;
    margin: 0;
    padding: 10px 0;
    font: normal normal bold 12px/20px Open Sans;
    color: $simplic-dark-text;
  }
}

.link {
  padding-bottom: 5px;
  margin: 0;
  color: $simplic-blue;
}

a {
  color: $simplic-blue;
  font: normal normal bold 12px/20px Open Sans;
}

#more-links-container {
  overflow: auto;
  &::after {
    content: "";
    clear: both;
    display: table;
  }

  #see-more-link {
    text-align: left;
    color: #185399;
    font: normal normal bold 14px/20px Open Sans;
    text-decoration: underline $simplic-blue;
    display: inline-block;
    width: 50%;
    float: left;

    p {
      width: 100%;
      padding: 5px 10px;
      margin: 0;
    }
  }

  .expandable-arrow {
    float: right;
    padding-top: 2px;
    padding-right: 10px;
  }
}

#link-container {
  text-align: left;
  padding-left: 5%;
  padding-bottom: 15px;

  p {
    font: normal normal bold 12px/20px Open Sans;
    padding-top: 10px;
    padding-left: 10px;
    margin: 0;
  }
}
</style>
<i18n>
{
  "pt": {
    "loanDate": "Data do Empréstimo",
    "lastDueDate": "Data da última parcela",
    "loanAmount": "Valor do Empréstimo",
    "numberOfInstallments": "Número de parcelas",
    "installmentAmount": "Valor da parcela",
    "installments": "vezes",
    "seeMore": "Ver mais",
    "changePayment": "Forma de pagamento",
    "seeContract": "Ver Contrato"
  }
}
</i18n>
