import { client } from "@/api/secure-service"

interface PostUniversalMetricsEventPayload {
  brand: string;
  product: string;
  customer_identifier_type: string;
  customer_identifier: string;
  event_type: string;
  channel: string;
  event_context: JSON;
}

export async function postUniversalMetricsEvent (accountID: number, eventType: string): Promise<void> {
  const eventContext = {} as JSON
  const payload: PostUniversalMetricsEventPayload = {
    "brand": "simplic",
    "product": "simplic_near_prime",
    "customer_identifier_type": "internal",
    "customer_identifier": accountID.toString(),
    "event_type": eventType,
    "channel": "organic",
    "event_context": eventContext
  }

  try {
    await client().post("/user-interactions/universal-metric-event", payload)
  } catch(err) {
    return Promise.reject(err)
  }
}
