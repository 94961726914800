<template>
  <v-card
    id="phone-verification-card"
    ref="card">
    <v-card-text class="phone-number">
      <div id="phone-number-container">
        <div id="phone-number-input">
          <label class="cell-label-text">
            {{ $t('cellLabel') }}
          </label>
          <div class="phone-field-container">
            <v-text-field
              ref="phoneNumberInput"
              v-model="phoneNumber"
              v-mask="'(##) #####-####'"
              :class="{'valid-phone': showPhoneNumberValid, 'invalid-phone': showPhoneNumberInvalid}"
              type="tel"
              outlined
              single-line
              dense
              :rules="[
                rules.minPhoneLength.validate({
                  value: phoneNumber,
                  length: 10,
                  message: $t('invalidNumber')
                })
              ]"
              :validate-on-blur="true"
              @focus="editNumber"
              @blur="blurNumber" />
            <img src="~@/assets/field-edit.svg" alt="field-edit" />
          </div>
        </div>
        <div
          ref="validationChips"
          class="request-code-button">
          <v-btn
            v-if="!editingPhone"
            class="cta-btn"
            type="action"
            :depressed="true"
            :loading="loading"
            @click="sendCode">
            <span class="btn-text">{{ $t('requestCode') }}</span>
          </v-btn>
          <v-btn
            v-if="editingPhone"
            class="cta-btn"
            type="action"
            :depressed="true"
            :loading="loading"
            @click="updateNumber">
            <span class="btn-text">{{ $t('save') }}</span>
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { logError } from "@/plugins/sentry"
import { defineComponent, Ref, ref } from "vue"
import { PhoneMinLengthValidator } from "@/lib/validators/phone-min-length"
import { useAccountStore, useCookieStore } from "@/store"
import { CodeRequestPayload, CodeRequestResponse, EditPhonePayload, EditPhoneResponse } from "@/api/secure/frontend-service"
import { requestCode, editPhone } from "@/api/secure"

export default defineComponent({
  name: "PhoneNumberForm",
  setup (_, { emit }) {
    const { phoneNumber, accountID } = useAccountStore()
    const { accessTokensData, updateAccessTokens } = useCookieStore()
    const showPhoneNumberValid: Ref<boolean>  = ref(false)
    const showPhoneNumberInvalid: Ref<boolean>  = ref(false)
    const editingPhone: Ref<boolean> = ref(false)
    const loading: Ref<boolean> = ref(false)

    const validNumber = (numberString: string | undefined) => {
      if (!numberString) {
        return false
      }
      const number = numberString.replace(/\D/g, "")
      return number.length >= 10
    }

    const sendCode = async () => {
      loading.value = true
      if (!phoneNumber.value) {
        return
      }
      if (!validNumber(phoneNumber.value.toString())) {
        return
      }

      const pData: CodeRequestPayload = { which_phone: "main_phone" }
      const response: CodeRequestResponse = await requestCode(pData)
      if (!["success", "active_code_request"].includes(response.status)) {
        const requestCodeMessage = response.message
        logError(requestCodeMessage)
      }
      loading.value = false
      emit("sent-code")
    }

    const blurNumber = () => {
      if (phoneNumber.value && validNumber(phoneNumber.value.toString())) {
        showPhoneNumberInvalid.value = false
        showPhoneNumberValid.value = true
      } else {
        showPhoneNumberInvalid.value = true
        showPhoneNumberValid.value = false
      }
    }

    const updateNumber = async () => {
      loading.value = true
      const pData: EditPhonePayload = { account_id: accountID.value, main_phone: phoneNumber.value }
      const response: EditPhoneResponse = await editPhone(pData)
      if (response.status != "success") {
        const editPhoneMessage = response.message
        logError(editPhoneMessage)
      }
      accessTokensData.value.phone_number = phoneNumber.value
      updateAccessTokens()
      loading.value = false
      editingPhone.value = false
      emit("phone-edited")
    }

    const editNumber = () => {
      // change Request Code button to Save Phone button
      editingPhone.value = true
    }

    return {
      blurNumber,
      editNumber,
      updateNumber,
      sendCode,
      validNumber,
      phoneNumber,
      showPhoneNumberValid,
      showPhoneNumberInvalid,
      editingPhone,
      loading,
      rules: {
        minPhoneLength: PhoneMinLengthValidator
      }
    }
  }
})
</script>
<style lang="scss">
#phone-verification-card {
    margin: 2em auto;
    background-color: #f5f5f5;

    strong {
      font-weight: bold;
    }
    .request-code-button {
      padding-top: 32px;
      position: relative;
    }
    .cta-btn {
      background-color: $simplic-yellow;
      color: $simplic-copy;
      width: 179px;
      height: 44px;
      .btn-text {
        font: normal normal bold 14px/16px Open Sans;
        letter-spacing: 0;
        color: #4A4A4A;
        opacity: 1;
      }
    }
    label {
      display: block;
      text-align: left;
    }
    hr {
      margin: 1.5em auto;
    }
  .cell-label-text {
    padding-bottom: 10px;
    color: black;
  }
  .phone-field-container {
    position: relative;

    img {
      padding-top: 7px;
      position: absolute;
      top: 2px;
      right: 5px;
    }
    .v-input__slot {
      background-color: #ffffff;
      height: 45px;
    }
    input {
      border: none;
      background-color: white;
      margin-bottom: 0;
      color: #00000099;
      padding-bottom: 10px;
    }
    .invalid-phone {
      .v-input__slot {
        border: 1px solid rgb(208, 2, 27);
      }
      fieldset {
        border: none;
      }
      .v-messages__wrapper {
        color: rgb(208, 2, 27);
      }
    }
    .valid-phone {
      .v-input__slot {
        border: 1px solid #05a006;
      }
      fieldset {
        border: none;
      }
    }
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding: 0;
    }
    .v-messages__message {
      padding-top: 4px;
    }
  }
  @media (min-width: 768px) {
    #phone-number-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      #phone-number-input, .request-code-button {
        width: 48%;
      }
    }
  }
}

@media (max-width: 768px) {
  #phone-number-container {
    display: block;
    text-align: center;

    #phone-number-input {
      width: 100%;
    }
    .request-code-button {
      padding-top: 0;
      padding-bottom: 10px;
      width: 100%;

      .cta-btn {
        width: 100%;
      }
    }
  }
}
</style>

/* eslint-disable @intlify/vue-i18n/no-html-messages */
<i18n>
{
  "pt": {
    "cellLabel": "Número do celular com DDD",
		"requestCode": "Solicitar Código",
    "save": "Salvar",
		"invalidNumber": "Por favor, preencha um número de telefone válido."
  }
}
</i18n>
/* eslint-enable @intlify/vue-i18n/no-html-messages */
