<script setup lang="ts">
import { onMounted, withDefaults, defineProps, defineEmits, computed } from "vue"
import { useRoute } from "vue-router/composables"

type WhatsAppConsentInfoProps = {
  cpfFirstTwo?: string;
  cpfSecondTwo?: string;
  partialPhone?: string;
}

const props = withDefaults(defineProps<WhatsAppConsentInfoProps>(), {
  cpfFirstTwo: "",
  cpfSecondTwo: "",
  partialPhone: ""
})

const route = useRoute()
const emit = defineEmits<{
  (event: "whatsapp_consent_shown", url: string): void;
}>()

const maskedCPF = computed(() => {
  return "XXX.XXX.X" + props.cpfFirstTwo + "-" + props.cpfSecondTwo
})

const maskedPhone = computed(() => {
  return "(XX) XXXXX-" + props.partialPhone
})

onMounted(() => {
  if (route) {
    emit("whatsapp_consent_shown", process.env.VUE_APP_FULL_URL + route.path)
  }
})

</script>

<template>
  <div>
    <p id="welcome-text"> {{ $t('doYouWantToOptIn') }} </p>
    <div v-if="cpfFirstTwo && cpfSecondTwo && partialPhone">
      <p> {{ $t('confirmDetails') }} </p>
      <div class="info-box">
        <div class="inline">
          <p>{{ $t('cpf') }}</p>
          <p>{{ maskedCPF }}</p>
        </div>
        <div class="inline">
          <p>{{ $t('phone') }}</p>
          <p>{{ maskedPhone }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#resources-container {
  display: block;
  margin: 1em 0.25em;
  box-sizing: border-box;
  text-align: center;
}
#welcome-text {
  padding-top: 10px;
  font-weight: bold;
}
.info-box {
  width: 50%;
  margin: 0 auto;
}
.inline {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
}
</style>
<i18n>
{
  "pt": {
    "doYouWantToOptIn": "Receba atualizações sobre sua elegibilidade para empréstimos, atualizações de conta e ofertas especiais via WhatsApp!",
    "confirmDetails": " Confirme seus dados abaixo:",
    "cpf": "CPF",
    "phone": "Telefone"
  }
}
</i18n>
