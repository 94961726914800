<template>
  <div>
    <p>{{ $t("footerInfo1") }}</p>
    <p>{{ $t("footerInfo2") }}</p>
    <template v-if="open">
      <p>{{ $t("footerInfo3") }}</p>
      <p>{{ $t("footerInfo4") }}</p>
      <p>{{ $t("footerInfo5") }}</p>
    </template>
    <v-btn class="show-hide-button" outlined @click="toggle">
      {{ open ? $t("showLess") : $t("showMore") }}
    </v-btn>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue"
export default defineComponent({
  name: "Legal",
  setup() {
    const open = ref(false)

    const toggle = () => {
      open.value = !open.value
    }

    return {
      open,
      toggle
    }
  }
})
</script>
<style lang="scss" scoped>
@import '~/src/scss/variables.scss';

.show-hide-button {
  align-self: center;
  max-width: 200px;
  display: block;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  color: $simplic-white !important;
  border-radius: 0.25em;

  &:hover, &:active {
    background-color: $simplic-white;
    color: $simplic-green !important;
  }
}
</style>
<i18n>
{
  "pt": {
    "footerInfo1": "Simplic.com.br pertence e é operado pela LH1010 Serviços de Correspondente Bancário Ltda. (“Simplic”), uma sociedade limitada registrada sob o CNPJ/MF 17.103.297/0001-13, com sede a Avenida Engenheiro Luiz Carlos Berrini, 1748 – 22º andar– suite 2203 – Cidade Monções, Município de São Paulo, Estado de São Paulo, CEP 04571-000. O Simplic não é uma instituição financeira. Somos um correspondente bancário da Banco Afinz S/A Banco Múltiplo (\"Afinz\", antiga Sorocred) registrada sob o CNPJ/MF 04.814.563/0001-74 e possuímos uma plataforma online que facilita o acesso de clientes a produtos e serviços ofertados por instituições financeiras parceiras.",
    "footerInfo2": "A taxa de juros praticada no produto de crédito pessoal pode variar de 15,80% a 17,90% a.m. (481,44% a 621.38% a.a.). O Simplic tem o compromisso de total transparência com nossos clientes. Antes de iniciar o preenchimento de uma proposta, será exibido de forma clara: a taxa de juros utilizada, tarifas aplicáveis, impostos (IOF) e o custo efetivo total (CET). Nossa central de atendimento está disponível para esclarecimento de dúvidas sobre quaisquer dos valores apresentados.",
    "footerInfo3": "Os correspondentes bancários são empresas contratadas por instituições financeiras e demais instituições autorizadas pelo Banco Central do Brasil para a prestação de serviços de atendimento aos clientes e usuários dessas instituições. Entre os correspondentes mais conhecidos, encontram-se as lotéricas e o banco postal.",
    "footerInfo4": "O Simplic não cobra quaisquer valores diretamente dos clientes. Somos remunerados apenas pelas instituições financeiras parceiras. Caso você receba um e-mail ou seja contatado por alguém se fazendo passar pelo Simplic e cobrando quaisquer taxas para fechar negócios em nossa plataforma, encerre o contato imediatamente e fale com nossa central de atendimento, pois você pode estar sendo vítima de uma tentativa de fraude.",
    "footerInfo5": "O atraso no pagamento de uma ou mais prestações do seu contrato de empréstimo pessoal pode ter como consequência, dentre outras, a inclusão do seu nome nos cadastros de inadimplentes dos órgãos de proteção ao crédito, o protesto de títulos e, ainda, o ajuizamento de ações judiciais de cobrança. Para evitar maiores problemas e restrições ao seu crédito na praça, mantenha sempre o pagamento de suas parcelas em dia e, em caso de dúvidas, entre em contato com nossa central de atendimento.",
    "showMore": "Saiba mais +",
    "showLess": "Veja menos -"
  }
}
</i18n>
