<template>
  <v-sheet
    id="resources-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <p id="resources--header">{{ $t('resources') }}</p>
    <hr />
    <resource-card
      v-if="firstResource"
      :title="firstResource.title"
      :body="firstResource.body"
      :link="firstResource.link" />
    <resource-card
      v-if="secondResource"
      :title="secondResource.title"
      :body="secondResource.body"
      :link="secondResource.link" />
    <p id="visit-faq">{{ $t('checkOutOtherTips') }} <a id="faq-link" href="https://www.simplic.com.br/blog">{{ $t('blog') }}</a></p>
  </v-sheet>
</template>
<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue"
import ResourceCard from "@/components/account-home/ResourceCard.vue"
import { ResourceInformation } from "@/api/secure/account-home-service"
import { useAccountDetailsStore } from "@/store"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "Resources",
  components: { ResourceCard },
  setup() {
    const { loans, loanApplications } = useAccountDetailsStore()
    const firstResource = ref()
    const secondResource = ref()
    const vueInstance = getCurrentInstance()

    const getLoanStatus = () => {
      let status = ""
      if (loans.value?.length) {
        status = loans.value[0].loan_status
      }
      return status
    }

    const getLoanAppStatus = () => {
      let status = ""
      if (loanApplications.value?.length) {
        status = loanApplications.value[0].status
      }
      return status
    }

    const populateResources = () => {
      const loanStatus: string = getLoanStatus()
      const loanAppStatus: string = getLoanAppStatus()
      if(loanAppStatus === "lp" || loanAppStatus === "ready_for_lp") {
        firstResource.value = {
          title: "Organização financeira: 4 métodos práticos para começar hoje mesmo",
          body: "Neste artigo, vamos apresentar quatro métodos práticos para organizar suas finanças e ter com uma vida financeira mais equilibrada.",
          link: "https://www.simplic.com.br/blog/organizacao-finaceira-quatro-metodos-praticos/"
        } as ResourceInformation
        secondResource.value = {
          title: "Na Mídia",
          body: "Acompanhe os destaques da Simplic na imprensa",
          link: "https://www.simplic.com.br/blog/na-midia/"
        } as ResourceInformation
      } else if (loanAppStatus === "withdrawn") {
        firstResource.value = {
          title: "Passo a passo para solicitar um empréstimo pessoal na Simplic",
          body: "Confira o passo a passo para solicitar um empréstimo pessoal online com a Simplic e garanta segurança e facilidade no processo.",
          link: "https://www.simplic.com.br/blog/passo-a-passo-para-solicitar-um-emprestimo-pessoal-na-simplic/"
        } as ResourceInformation
        secondResource.value = {
          title: "Na Mídia",
          body: "Acompanhe os destaques da Simplic na imprensa",
          link: "https://www.simplic.com.br/blog/na-midia/"
        } as ResourceInformation
      } else if (loanAppStatus === "underwriting_declined" || loanAppStatus === "lp_declined" || loanAppStatus === "ebs_declined") {
        firstResource.value = {
          title: "7 dicas certeiras para você aumentar seu score de crédito",
          body: "Confira quais são as dicas ideais para você descobrir como aumentar score de crédito!",
          link: "https://www.simplic.com.br/blog/7-dicas-certeiras-para-voce-aumentar-seu-score-de-credito/"
        } as ResourceInformation
        secondResource.value = {
          title: "Na Mídia",
          body: "Acompanhe os destaques da Simplic na imprensa",
          link: "https://www.simplic.com.br/blog/na-midia/"
        } as ResourceInformation
      } else if (loanAppStatus === "underwriting_approved") {
        firstResource.value = {
          title: "Preciso declarar meu empréstimo no imposto de renda?",
          body: "Se o valor obtido de empréstimo ou do crédito pessoal for inferior a R$5.000,00, não há a necessidade de mencioná-lo em sua declaração.",
          link: "https://www.simplic.com.br/blog/preciso-declarar-meu-emprestimo-no-imposto-de-renda/"
        } as ResourceInformation
        secondResource.value = {
          title: "10 serviços online para te fazer economizar",
          body: "Confira 10 opções que serão uma mão na roda no dia a dia – e ainda grandes amigos do seu orçamento!",
          link: "https://www.simplic.com.br/blog/meu-planner-financeiro-2022-baixe-agora/"
        } as ResourceInformation
      } else if (loanStatus === "current") {
        firstResource.value = {
          title: "10 Dicas para cuidar da sua saúde financeira",
          body: "A saúde financeira é desejada por todos, mas para conquistá-la é preciso um árduo trabalho de organização e disciplina.",
          link: "https://www.simplic.com.br/blog/10-dicas-para-sua-saude-financeira/"
        } as ResourceInformation
        secondResource.value = {
          title: "Na Mídia",
          body: "Acompanhe os destaques da Simplic na imprensa",
          link: "https://www.simplic.com.br/blog/na-midia/"
        } as ResourceInformation
      } else if (loanStatus === "paid_off") {
        firstResource.value = {
          title: "Precisando de dinheiro? Saiba 8 dicas para ganhar dinheiro na internet",
          body: "Se você ainda não sabe como usar a internet para fazer dinheiro, não se preocupe. Vamos falar um pouco sobre os principais métodos disponíveis.",
          link: "https://www.simplic.com.br/blog/precisando-de-dinheiro-saiba-8-dicas-para-ganhar-dinheiro-na-internet/"
        } as ResourceInformation
        secondResource.value = {
          title: "Conexão bancária - Empréstimo Simplic",
          body: "É simples e seguro e te ajuda a encontrar ofertas de empréstimo de acordo com seu perfil. Saiba mais.",
          link: "https://www.simplic.com.br/blog/conexao-bancaria-para-contratar-emprestimo-simplic-como-fazer/"
        } as ResourceInformation
      } else {
        firstResource.value = {
          title: "Na Mídia",
          body: "Acompanhe os destaques da Simplic na imprensa",
          link: "https://www.simplic.com.br/blog/na-midia/"
        } as ResourceInformation
        secondResource.value = {
          title: "Conexão bancária - Empréstimo Simplic",
          body: "É simples e seguro e te ajuda a encontrar ofertas de empréstimo de acordo com seu perfil. Saiba mais.",
          link: "https://www.simplic.com.br/blog/conexao-bancaria-para-contratar-emprestimo-simplic-como-fazer/"
        } as ResourceInformation
      }
    }

    onMounted(() => {
      populateResources()
      tagRecordings((vueInstance as unknown) as VueWithHotjar, ["resources_card_displayed"])
    })

    return {
      firstResource,
      secondResource
    }
  }
})
</script>
<style lang="scss" scoped>
#resources-container {
  display: block;
  margin: 1em 0.25em;
  box-sizing: border-box;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;
}

#resources--header {
  font: normal normal bold 20px/24px Open Sans;
  padding: 20px 0;
  margin: 0 auto;
  color: $simplic-dark-text;
}

#visit-faq {
  font: normal normal normal 12px/24px Open Sans;
  padding-top: 10px;
  padding-bottom: 7px;
  color: $simplic-dark-text;
}

#faq-link {
  color: $simplic-blue;
  font-weight: bold;
}
</style>
<i18n>
{
  "pt": {
    "resources": "Saiba Mais",
    "checkOutOtherTips": "Confira outras dicas no nosso",
    "blog": "blog"
  }
}
</i18n>
