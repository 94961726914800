import moment from "moment/moment"
import VueI18n from "@/plugins/i18n"

const getAllowedDates = (weekdaysOnly: boolean): (date: string) => boolean => {
  return (date: string) => {
    if (!weekdaysOnly) {
      return true
    }

    const d = moment(date)

    return ![0, 6].includes(d.weekday())
  }
}

const validate = (isoDate: string, minDaysFromNow: number, maxDaysFromNow: number): boolean => {
  const date = moment(isoDate)
  const today = moment.now()
  const daysFromToday = date.diff(today, "days")

  if (minDaysFromNow !== -1) {
    if (daysFromToday < minDaysFromNow!) {
      return false
    }
  }

  if (maxDaysFromNow !== -1) {
    if (daysFromToday > maxDaysFromNow!) {
      return false
    }
  }

  return true
}

/**
 * getLessThanMaxRule creates a method testing if a date string is less than maxDaysFromNow days away.
 * Dates in the past always fail this rule.
 *
 * @param maxDaysFromNow The max number of days a date is allowed to be from today.
 *
 * @return A method whose return value is True if the provided date string is less than the max number of days away from today, otherwise
 * an error string is returned.
 */
const getLessThanMaxRule = (maxDaysFromNow: number): (value: string) => string | boolean => {
  return (value: string): string | boolean => {
    const date = moment(value)
    const today = moment.now()
    const days = date.diff(today, "days")

    if(days > maxDaysFromNow) {
      return VueI18n.t("tooLate", { n: maxDaysFromNow }).toString()
    } else {
      return true
    }
  }
}

/**
 * getGreaterThanMinRule creates a method testing if a date string is greater than the minimum number
 * of days from today. Dates in the past always fail this rule.
 *
 * @param minDaysFromNow The minimum number of days a date string is allowed to be from today.
 *
 * @return A method whose return value is True if the provided date string is at least minDaysFromNow
 * in the future. An error string is returned otherwise.
 */
const getGreaterThanMinRule = (minDaysFromNow: number): (value: string) => string | boolean => {
  return (value: string) => {
    const date = moment(value)
    const today = moment.now()
    const days = date.diff(today, "days")

    if(days < minDaysFromNow) {
      return VueI18n.t("tooEarly").toString()
    } else {
      return true
    }
  }
}

export { getAllowedDates, validate, getLessThanMaxRule, getGreaterThanMinRule }
