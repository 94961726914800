<script setup lang="ts">
import { useLPStore } from "@/store"
import { ref, withDefaults, defineProps, defineExpose } from "vue"

type HeroProps = {
  completed?: boolean;
  optedIn?: boolean;
  optedInFeatureEnabled?: boolean;
  optedInSavings: object;
}

withDefaults(defineProps<HeroProps>(), {
  completed: false,
  optedIn: false,
  optedInFeatureEnabled: false
})

const { sendOptInRequest } = useLPStore()
const submittedOptIn = ref(false)

const onOptIn = async () => {
  submittedOptIn.value = true
  await sendOptInRequest()
}

defineExpose({
  onOptIn
})

</script>

<template>
  <div class="hero-card-container">
    <v-card class="hero-card">
      <v-row v-if="!optedIn && optedInFeatureEnabled" align="center">
        <v-col v-if="!submittedOptIn" class="hero-text with-opt-in" md="8" cols="12">
          <h3>{{ $t('headerOptIn') }}</h3>
          {{ $t('subHeaderOptIn', { savings: optedInSavings.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }) }}
          <v-btn id="opt-in" class="cta-button" @click="onOptIn">
            {{ $t('optInButton') }}
          </v-btn>
          <span class="footnote">{{ $t('optInFootnote') }}</span>
        </v-col>
        <v-col v-else class="hero-text after-opt-in" md="8" cols="12">
          <h3>{{ $t('optInSuccess') }}</h3>
        </v-col>
        <v-col md="4" cols="12" class="hero-image-container">
          <v-img
            src="~@/assets/images/man-holding-phone.png"
            class="hero-image"
            :max-width="197"
            :max-height="226"
            contain
            aspect-ratio="1" />
        </v-col>
      </v-row>
      <v-row v-else-if="completed">
        <v-col class="hero-text" md="8" cols="12">
          <h2>{{ $t('headerCompleted') }}</h2>
          <p>{{ $t('subHeaderCompleted') }}</p>
        </v-col>
        <v-col md="4" cols="12" class="hero-image-container">
          <v-img
            src="~@/assets/images/man-thumbs-up.png"
            class="hero-image"
            :max-width="197"
            :max-height="226"
            contain
            aspect-ratio="1" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="hero-text" md="8" cols="12">
          <h2>{{ $t('header') }}</h2>
          <p>{{ $t('subHeader') }}</p>
        </v-col>
        <v-col md="4" cols="12" class="hero-image-container">
          <v-img
            src="~@/assets/images/man-holding-phone.png"
            class="hero-image"
            :max-width="197"
            :max-height="226"
            contain
            aspect-ratio="1" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style lang="scss">
  @import '~/src/scss/variables.scss';
  #loan-processing {
    .hero-card-container {
      .hero-card {
        background-color: #f4f4f4;
        box-shadow: 0 3px 6px #00000029;
        border: 1px solid $simplic-outline-gray;
        border-radius: 0;
        overflow: hidden;

        div {
          margin: 0 0 -6px 0;
          padding: 0 4px;
        }
      }

      .hero-text {
        text-align: center;

        p {
          font-family: $font-family-open-sans;
          font-size: 13px;
          font-weight: 600;
          padding-bottom: .8em;
        }

        h2 {
          font-family: $font-family-bree-serif;
          padding-bottom: 1.2em;
          margin-top: 1.5em;
          font-size: 1.7em;
        }
      }

      .with-opt-in {
        font-size: 13px;

        h3 {
          padding-top: 0;
          font-weight: normal;
        }
      }

      .after-opt-in {
        font-size: 13px;

        h3 {
          padding-top: 0;
          font-weight: normal;
        }
      }

      .hero-image-container {
        display: flex;
        justify-content: center;

        .hero-image {
          max-height: 226px;
          max-width: 197px;
          height: 100%;
        }
      }

      .footnote {
        display: inline-block;
        font-size: 0.7em;
        line-height: 1.5;
        font-weight: normal;
      }

      .cta-button {
        margin: 1em auto;
        background-color: $simplic-yellow;
        text-transform: uppercase;
        color: $simplic-copy;
        padding: 1.5em 1.5em;
        font-size: 0.9em;
        font-weight: bold;
        letter-spacing: 0;
        width: 100%;
        max-width: 300px;
      }
    }
  }
</style>

<i18n>
{
  "pt": {
    "header": "Agora falta pouco!",
    "subHeader": "Para finalizar a análise de sua proposta de empréstimo, realize os passos abaixo.",
    "headerCompleted": "Documentos enviados!",
    "subHeaderCompleted": "Agora é só aguardar. Assim que finalizarmos a análise, entraremos em contato por email.",
    "headerOptIn": "Aumente suas chances de aprovação caso o débito automático esteja ativado!",
    "subHeaderOptIn": "Com o débito ativado você economiza {savings} por mês!",
    "optInButton": "Autorizar* O Débito!",
    "optInFootnote": "*A autorização inclue débitos após o vencimento e por lançamentos parciais e sobre o meu limite de crédito em conta (cheque especial), se houver.",
    "optInSuccess": "O débito automático de suas parcelas foi autorizado com sucesso e, caso você seja aprovado, pagará taxas menores por isso! Fique atento às pendências de documentos abaixo."
  }
}
</i18n>
