<template>
  <div id="payment-confirmation-headline-container">
    <h2>
      {{ selectedOffer.total_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
    </h2>
    <span v-if="selectedOffer.number_of_installments == 1">{{ $t('upFront') }}</span>
    <span v-else>{{ $t('installmentAmountSubtitle', {offerCount: selectedOffer.number_of_installments}) }}</span>

    <span id="installment-details">
      <span v-if="selectedOffer.total_amount == selectedOffer.installment_amount">
        <p id="no-remaining-installments">{{ $t('installmentDetails', {offerCount: selectedOffer.number_of_installments, installmentAmt: installmentAmount(selectedOffer)}) }}</p>
      </span>
      <span v-else-if="installmentAmount(selectedOffer) === downPaymentAmount(selectedOffer)">
        <p id="no-remaining-installments">{{ $t('installmentDetails', {offerCount: selectedOffer.number_of_installments, installmentAmt: installmentAmount(selectedOffer)}) }}</p>
      </span>
      <span v-else>
        <p>{{ $t('downPaymentDetails', {downPaymentAmt: downPaymentAmount(selectedOffer)}) }}</p>
        <p id="remaining-installments-details">{{ $t('remainingInstallmentsDetails', {installmentAmt: installmentAmount(selectedOffer), remainingInstallments: selectedOffer.number_of_installments - 1 }) }}</p>
      </span>
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue"
import { usePaymentPlansStore } from "@/store"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"

export default defineComponent({
  setup() {
    const { selectedOffer, withDownPayment } = usePaymentPlansStore()

    const downPaymentAmount = (offer: PaymentPlanOffer) => {
      return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }

    const installmentAmount = (offer: PaymentPlanOffer) => {
      if (!withDownPayment.value) {
        return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      if (offer.number_of_installments == 1) {
        return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      const remainingPaymentsAmount = (offer.total_amount - offer.installment_amount) / (offer.number_of_installments - 1)
      if (Math.abs(remainingPaymentsAmount - offer.installment_amount) > 0.1) {
        return remainingPaymentsAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
      return offer.installment_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }

    return {
      selectedOffer,
      downPaymentAmount,
      installmentAmount
    }
  }
})
</script>
<style lang="scss" scoped>
  #payment-confirmation-headline-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1em auto 2em auto;

    h2 {
      font-family: $font-family-sans-serif;
      letter-spacing: 1px;
      font-size: 2em;
    }
    #installment-details {
      font-weight: bold;

      p {
        margin-top: 12px;
        margin-bottom: -12px;
        text-align: center;
      }
    }
  }
</style>
<i18n>
{
  "pt": {
    "installmentAmountSubtitle": "em {offerCount} parcelas mensais",
    "upFront": "à vista",
    "installmentDetails": "{offerCount} x {installmentAmt}",
    "downPaymentDetails": "Entrada de {downPaymentAmt}",
    "remainingInstallmentsDetails": "+ {remainingInstallments} x {installmentAmt}"
  }
}
</i18n>
