<template>
  <div id="app">
    <div id="belvo" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, defineProps, withDefaults } from "vue"
import { useRoute } from "vue-router/composables"
import { loadScript } from "@/lib/component/belvo-widget"

type BelvoWidgetProps = {
  accountID?: number;
  bankName: string;
  accessToken: string;
}

const props = withDefaults(defineProps<BelvoWidgetProps>(), {
  accountID: 0
})

const route = useRoute()

onMounted(() => {
  // Executing the function to load the widget after mount, since we need
  // the template to be loaded
  const belvoURL = "https://cdn.belvo.io/belvo-widget-1-stable.js"
  loadScript(belvoURL, route.path, props.bankName, props.accountID, props.accessToken)
})
</script>
