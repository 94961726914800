<template>
  <div>
    <SimplicExpressHeader v-if="connections.belvo_access_tokens || connections.simplic_express_iframe || requestErrored" />
    <BelvoWidget
      v-if="connections.belvo_access_tokens"
      :account-id="accountID.toString()"
      :bank-name="bankName"
      :access-token="connections.belvo_access_tokens.access" />
    <KlaviOpenBanking
      v-else-if="connections.klavi_consent_url"
      :redirect="connections.klavi_consent_url" />
    <ExpressiFrame v-else-if="connections.simplic_express_iframe || requestErrored" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue"
import { getBankConnection, FetchBankConnectionResponse, FetchBankConnectionParams } from "@/api/secure"
import { useAccountStore, useCookieStore, useEbsStore, useNotificationsStore } from "@/store"
import ExpressiFrame from "@/components/loan-processing/ExpressiFrame.vue"
import BelvoWidget from "@/components/loan-processing/BelvoWidget.vue"
import { logError } from "@/lib/analytics"
import KlaviOpenBanking from "@/components/loan-processing/KlaviOpenBanking.vue"
import SimplicExpressHeader from "@/components/loan-processing/SimplicExpressHeader.vue"

export default defineComponent({
  name: "BankConnector",
  components: { KlaviOpenBanking, BelvoWidget, ExpressiFrame, SimplicExpressHeader },
  setup() {
    const { accountID, fullName, cpf } = useAccountStore()
    const { analyticDataLayer } = useCookieStore()
    const connections = ref<FetchBankConnectionResponse>({} as FetchBankConnectionResponse)
    const requestErrored = ref(false)
    const genericError = "Ocorreu um erro enquanto processávamos o seu pedido. Por favor, tente novamente."
    const bankName = analyticDataLayer.value.bank_display_name?.toLowerCase() || ""

    const params = {
      accountID: accountID.value,
      loanNumber: analyticDataLayer.value.loanNumber,
      loanApplicationID: analyticDataLayer.value.loan_application_id,
      name: encodeURIComponent(fullName.value),
      cpf: cpf.value,
      bank: bankName
    } as FetchBankConnectionParams
    async function fetchBankConnection(params: FetchBankConnectionParams) {
      await getBankConnection(params)
        .then((data) => {
          const { src, checkingDocumentUUID } = useEbsStore()
          src.value = data.simplic_express_iframe?.src || ""
          checkingDocumentUUID.value = data.simplic_express_iframe?.checking_document_uuid || ""
          connections.value = data
          requestErrored.value = false
        })
        .catch((error) => {
          requestErrored.value = true
          const { errorMessage } = useNotificationsStore()
          errorMessage(genericError)
          logError(error)
        })
    }

    onMounted(async () => {
      await fetchBankConnection(params)
    })

    return {
      fetchBankConnection,
      connections,
      requestErrored,
      accountID,
      bankName
    }
  }
})
</script>
