<script setup lang="ts">
import { getCurrentInstance, onMounted, Ref, ref, withDefaults, defineProps } from "vue"
import { useAccountDetailsStore } from "@/store"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

type MakeAPaymentCardProps = {
  mostRecentLoan?: object;
}

const props = withDefaults(defineProps<MakeAPaymentCardProps>(), {
  mostRecentLoan: undefined
})

const { paymentData } = useAccountDetailsStore()
const buttonPath: Ref<string> = ref("")
const paymentAmount: Ref<number> = ref(0)
const nextDueDate: Ref<string> = ref("")
const inDefault: Ref<boolean> = ref(false)
const highPayment: Ref<boolean> = ref(false)
const vueInstance = getCurrentInstance()

const convertDate = (input: string) => {
  const date = new Date(input)
  const dateString = date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
  return dateString.substring(0, 10)
}

const formatDate = (date: string) => {
  const dateOnly = date.substring(0, 10)
  const split = dateOnly.split("-") // year-month-day
  let month: string
  if (split[1] === "01") {
    month = "janeiro"
  } else if (split[1] === "02") {
    month = "fevereiro"
  } else if (split[1] === "03") {
    month = "março"
  } else if (split[1] === "04") {
    month = "abril"
  } else if (split[1] === "05") {
    month = "maio"
  } else if (split[1] === "06") {
    month = "junho"
  } else if (split[1] === "07") {
    month = "julho"
  } else if (split[1] === "08") {
    month = "agosto"
  } else if (split[1] === "09") {
    month = "setembro"
  } else if (split[1] === "10") {
    month = "outubro"
  } else if (split[1] === "11") {
    month = "novembro"
  } else {
    month = "dezembro"
  }
  return (parseInt(split[2], 10) + " de " + month + " de " + split[0])
}

const calculateNextPaymentDate = () => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  let paymentDate = tomorrow
  if (!inDefault.value && paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "" && paymentData.value.unpaid_installments.length > 0) {
    const installments = paymentData.value.unpaid_installments
    installments.sort((a: any, b: any) => {
      return +new Date(a.installment_due_date) - +new Date(b.installment_due_date)
    })
    if (installments[0].installment_due_date) {
      paymentDate = new Date(installments[0].installment_due_date)
    }
  }
  nextDueDate.value = formatDate(paymentDate.toISOString())
}

const calculatePaymentAmount = () => {
  if (!inDefault.value && paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "") {
    paymentAmount.value = parseFloat(paymentData.value.installment_amount)
    return
  }
  let amount = 0
  if(paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "" && paymentData.value.unpaid_due_installments.length > 0) {
    for(let i = 0; i < paymentData.value.unpaid_due_installments.length; i++) {
      amount += parseFloat(paymentData.value.unpaid_due_installments[i].outstanding_balance)
    }
  }
  paymentAmount.value = amount
}

const isInDefault = () => {
  inDefault.value = false

  if (paymentData && paymentData.value?.installment_amount != null && paymentData.value?.installment_amount != "" && paymentData.value.unpaid_due_installments.length > 0) {
    inDefault.value = true
    return
  }
}

const checkAndUpdatePaymentAmount = () => {
  calculatePaymentAmount()
  highPayment.value = paymentAmount.value > 3000
}

onMounted(() => {
  isInDefault()
  calculateNextPaymentDate()
  checkAndUpdatePaymentAmount()
  if(inDefault.value){
    buttonPath.value = "/loan-status"
  } else {
    buttonPath.value = "/current-loan"
  }

  if (props.mostRecentLoan && props.mostRecentLoan.is_open && paymentData && paymentData.value && paymentData.value.installment_amount != "") {
    tagRecordings((vueInstance as unknown) as VueWithHotjar, ["make_a_payment_card_displayed"])
  }
})

defineExpose({
  formatDate,
  convertDate
})
</script>

<template>
  <v-sheet
    v-if="mostRecentLoan && mostRecentLoan.is_open && paymentData && paymentData.installment_amount != ''"
    id="make-payment-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <div v-if="inDefault" id="text-and-image" class="shift-left">
      <img alt="red warning icon" src="~@/assets/images/svg/red_warning_icon.svg" />
      <p id="in-default--header">{{ $t('overdue') }}</p>
    </div>
    <p class="small-bold-text shift-left">{{ $t('nextPaymentDueDate') }}</p>
    <p class="big-bold-text shift-left">{{ nextDueDate }}</p>
    <hr id="divider" aria-hidden="true" />
    <p v-if="!highPayment && !inDefault" class="small-bold-text shift-left">{{ $t('paymentAmount') }}</p>
    <p v-if="!highPayment && inDefault" class="small-bold-text shift-left">{{ $t('openValue') }}</p>
    <p v-if="!highPayment" class="big-bold-text shift-left">{{ paymentAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}</p>
    <p v-if="highPayment" class="small-bold-text shift-left special-text">{{ $t('specialCondition') }}<br />{{ $t('clickMakeAPayment') }}</p>
    <table>
      <tr>
        <th>{{ $t('loanOriginationDate') }}</th>
        <td>{{ convertDate(mostRecentLoan.created_at) }}</td>
      </tr>
      <hr aria-hidden="true" />
      <tr>
        <th>{{ $t('repaymentTerms') }}</th>
        <td>{{ parseInt(paymentData.num_installments, 10) }} {{ $t('installments') }}</td>
      </tr>
    </table>
    <div id="button-container">
      <router-link
        :to="buttonPath">
        <button
          id="make-payment-card-button"
          type="button">
          <span id="make-a-payment">{{ (inDefault) ? $t('makeAPayment') : $t('viewDetails') }}</span>
          <span id="arrow-img-span"><img alt="arrow" src="~@/assets/arrow-back-gray.svg" /></span>
        </button>
      </router-link>
      <p v-if="inDefault" id="link-container"><router-link id="view-details-link" to="/current-loan">{{ $t('viewDetails') }}</router-link></p>
    </div>
  </v-sheet>
</template>

<style lang="scss" scoped>
#make-payment-container {
  display: block;
  margin: 1em 0.25em;
  box-sizing: border-box;
  position: relative;
  outline: 1px solid $simplic-outline-gray;

  .shift-left {
    padding-left: 4%
  }

  #text-and-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-top: 15px;

    #in-default--header {
      font: normal normal bold 20px/24px Open Sans;
      color: #DB2435;
      flex-grow: 1;
      margin: 0;
    }
  }

  #divider {
    background-color: $simplic-light-gray;
    border: none;
    height: 1px;
    clear: left;
    margin: auto;
    width: 93%;
  }

  .small-bold-text {
    font: normal normal bold 16px/24px Open Sans;
    color: $simplic-dark-text;
    padding-top: 15px;
    margin: 5px auto;
  }

  .big-bold-text {
    font: normal normal bold 24px/32px Open Sans;
    color: $simplic-dark-text;
  }

  .special-text {
    width: 99%;
    padding-bottom: 10px;
  }

  table {
    background-color: $simplic-background;
    padding: 5px 25px 5px;
    width: 100%;

    hr {
      background-color: $simplic-light-gray;
      border: none;
      height: 1px;
      clear: left;
    }

    th {
      float: left;
      text-align: left;
      margin: 0;
      padding: 10px 0;
      font: normal normal normal 12px/20px Open Sans;
      color: $simplic-dark-text;
    }

    td {
      float: right;
      text-align: right;
      margin: 0;
      padding: 10px 0;
      font: normal normal bold 12px/20px Open Sans;
      color: $simplic-dark-text;
    }
  }

  #button-container {
    width: 100%;
    text-align: left;
    padding: 20px 20px;
    padding-bottom: 10px;

    button {
      background-color: $simplic-yellow;
      padding: 10px;
      width: 100%;
      border-radius: 8px;

      #make-a-payment {
        font: normal normal bold 16px/24px Open Sans;
        float: left;
        color: $simplic-dark-text;
      }

      #arrow-img-span {
        float: right;
        padding-top: 3px;
      }
    }

    #link-container {
      padding-top: 10px;

      #view-details-link {
        color: $simplic-blue;
        font: normal normal bold 12px/20px Open Sans;
        text-decoration: none;
      }
    }
  }
}
</style>
<i18n>
{
  "pt": {
    "overdue": "Em atraso",
    "nextPaymentDueDate": "Próximo vencimento",
    "paymentAmount": "Valor da próxima parcela",
    "openValue": "Valor em aberto",
    "loanOriginationDate": "Data do empréstimo",
    "repaymentTerms": "Número de parcelas",
    "makeAPayment": "Faça um pagamento",
    "viewDetails": "Ver Condições",
    "installments": "vezes",
    "specialCondition": "Temos uma condição especial:",
    "clickMakeAPayment": "Clique em 'Faça um pagamento' e veja a oferta"
  }
}
</i18n>
