<script setup lang="ts">
import { computed, ref, Ref, defineProps, withDefaults, defineExpose } from "vue"
import type {
  Meta,
  QuestionData,
  DateTimeQuestionData,
  InputQuestionData, NumberQuestionData, TextAreaQuestionData, RadioQuestionData
} from "@/types/questionnaireTypes"
import Datepicker from "@/components/Datepicker.vue"
import {
  getDateTimeMinDaysFromNowRule,
  getDateTimeMaxDaysFromNowRule,
  getStringRequired,
  getInputMinLengthRule,
  getInputMaxLengthRule,
  getRequired,
  getNumberMinRule,
  getNumberMaxRule, getTextAreaMinRule, getTextAreaMaxRule, getAllowedDates, Rule
} from "@/lib/component/question"

type QuestionProps = {
  id?: string;
  value: QuestionData;
  meta: Meta;
}

const props = withDefaults(defineProps<QuestionProps>(), {
  id: undefined
})

const question = props.value
const meta = props.meta
const datepickerRef: Ref = ref(null)

const rules = computed(() => {
  const rules: Rule[]  = []

  const radio = () => {
    const radioQuestionData = question as QuestionData & RadioQuestionData

    if(radioQuestionData.validators === undefined) {
      return
    }

    if(radioQuestionData.validators.required) {
      rules.push(getStringRequired(meta))
    }
  }

  const select = () => {
    if(question.validators === undefined) {
      return
    }

    if(question.validators.required) {
      rules.push(getStringRequired(meta))
    }
  }

  const datetime = () => {
    const dateTimeQuestions = question as QuestionData & DateTimeQuestionData

    if(dateTimeQuestions.validators === undefined) {
      return
    }

    if(dateTimeQuestions.validators.required) {
      rules.push(v => (typeof(v) == "string" && v.length > 0) ? true : meta.required_message || "required")
    }

    if(dateTimeQuestions.validators.min_days_from_now !== undefined) {
      rules.push(getDateTimeMinDaysFromNowRule(meta, dateTimeQuestions.validators.min_days_from_now))
    }

    if(dateTimeQuestions.validators!.max_days_from_now !== undefined) {
      rules.push(getDateTimeMaxDaysFromNowRule(meta, dateTimeQuestions.validators.max_days_from_now))
    }
  }

  const input = () => {
    const inputQuestionDate = question as QuestionData & InputQuestionData

    if (inputQuestionDate.validators === undefined) {
      return
    }

    if(inputQuestionDate.validators.required) {
      rules.push(getStringRequired(meta))
    }
    const inputMin = inputQuestionDate.validators.min_length
    if(inputMin !== undefined) {
      rules.push(getInputMinLengthRule(meta, inputMin))
    }
    const inputMax = inputQuestionDate.validators.max_length
    if(inputMax !== undefined) {
      rules.push(getInputMaxLengthRule(meta, inputMax))
    }
  }

  const number = () => {
    const numberQuestionData = question as QuestionData & NumberQuestionData

    if(numberQuestionData.validators === undefined) {
      return
    }

    if(numberQuestionData.validators.required) {
      rules.push(getRequired(meta))
    }
    const numberMin = numberQuestionData.validators.min
    if(numberMin !== undefined) {
      rules.push(getNumberMinRule(meta, numberMin))
    }
    const numberMax = numberQuestionData.validators.max
    if(numberMax !== undefined) {
      rules.push(getNumberMaxRule(meta, numberMax))
    }
  }

  const textarea = () => {
    const textQuestionData = question as QuestionData & TextAreaQuestionData

    if(textQuestionData.validators === undefined) {
      return
    }

    if(textQuestionData.validators.required) {
      rules.push(getStringRequired(meta))
    }

    const textAreaMin = textQuestionData.validators.min_length
    if(textAreaMin !== undefined) {
      rules.push(getTextAreaMinRule(meta, textAreaMin))
    }

    const textAreaMax = textQuestionData.validators.max_length
    if(textAreaMax !== undefined) {
      rules.push(getTextAreaMaxRule(meta, textAreaMax))
    }
  }

  switch(question.type) {
  case "radio":
    radio()
    break
  case "select":
    select()
    break
  case "datetime":
    datetime()
    break
  case "input":
    input()
    break
  case "number":
    number()
    break
  case "textarea":
    textarea()
    break
  }

  return rules
})

/**
 * allowedChars determines if the typed character is a single digit number
 * @param event The keyboard event
 *
 * @returns True if the char typed is a single digit number, false otherwise.
 */
const allowedChars = (event: KeyboardEvent) => {
  if ((/[^0-9]/.test(event.key)) || event.key.length > 1) {
    event.preventDefault()
    return false
  }

  return true
}

const allowedDates = getAllowedDates(question)

defineExpose({
  rules,
  allowedDates,
  allowedChars
})
</script>

<template>
  <div>
    <div v-if="question.type=='radio'" class="questionnaire--question--radio">
      <v-radio-group v-model="question.answer" :rules="rules">
        <legend v-if="question.question">{{ question.question }}</legend>
        <v-radio
          v-for="option in question.options"
          :key="option.key"
          color="rgb(24, 83, 153)"
          :label="option.text"
          :value="option.key" />
      </v-radio-group>
    </div>
    <div v-else-if="question.type=='select'" class="questionniare--question--select">
      <label v-if="question.question" :for="id">{{ question.question }}</label>
      <v-select
        :id="id"
        v-model="question.answer"
        :items="question.options"
        item-value="key"
        :placeholder="question.placeholder"
        :rules="rules" />
    </div>
    <div v-else-if="question.type=='datetime'" class="questionniare--question--datepicker">
      <label v-if="question.question" :for="id">{{ question.question }}</label>
      <datepicker
        :id="id"
        ref="datepickerRef"
        v-model="question.answer"
        :placeholder="question.placeholder"
        :locale="meta.locale"
        :rules="rules"
        :allowed-dates="allowedDates" />
    </div>
    <div v-else-if="question.type=='input'" class="questionniare--question--text">
      <label v-if="question.question" :for="id">{{ question.question }}</label>
      <v-text-field
        :id="id"
        v-model="question.answer"
        :placeholder="question.placeholder"
        :rules="rules" />
    </div>
    <div v-else-if="question.type=='textarea'" class="questionniare--question--textarea">
      <label v-if="question.question" :for="id">{{ question.question }}</label>
      <v-textarea
        :id="id"
        v-model="question.answer"
        outlined
        :placeholder="question.placeholder"
        :rules="rules" />
    </div>
    <div v-else-if="question.type=='number'" class="questionniare--question--number">
      <label v-if="question.question" :for="id">{{ question.question }}</label>
      <v-text-field
        v-if="question.mask"
        :id="id"
        v-model="question.answer"
        v-mask="question.mask"
        type="number"
        :placeholder="question.placeholder"
        :rules="rules"
        @keydown="allowedChars" />
      <v-text-field
        v-else
        :id="id"
        v-model="question.answer"
        type="number"
        :placeholder="question.placeholder"
        :rules="rules"
        @keydown="allowedChars" />
    </div>
  </div>
</template>
