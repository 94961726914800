<template>
  <v-row>
    <v-btn v-if="$vuetify.breakpoint.mobile" id="nav-drawer--cta--button" width="90%" class="mx-auto" @click.stop="logout">
      {{ $t("logOut") }}
    </v-btn>
    <v-list-item-title v-else class="dropdown-link" @click.stop="logout">
      {{ $t("logOut") }}
    </v-list-item-title>
    <v-dialog
      v-model="showDialog"
      max-width="275px">
      <v-card>
        <v-card-title class="title">
          <span id="header-text" style="color:white" class="text-h5">{{ $t("areYouSure") }}</span>
          <v-spacer />
          <v-icon style="color: white;" @click="showDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="sub-text">
          {{ $t("chatWithAgentNudge") }}
        </v-card-text>
        <v-card-actions id="button-container">
          <v-col>
            <v-row id="select-an-option" class="button-row sub-text">{{ $t("selectAnOption") }}</v-row>
            <v-row class="button-row">
              <a :href="contact.whatsApp.url" target="_blank" @click="whatsAppClick">
                <v-btn id="whatsapp-button" class="button">
                  <img id="whatsapp-icon" alt="WhatsApp" class="button-icon" src="~@/assets/images/svg/btn-ico_whatsapp.svg" />
                  <span>{{ $t('whatsApp') }}</span>
                </v-btn>
              </a>
            </v-row>
            <v-row class="button-row">
              <a :href="chatUrl" target="_blank" @click="chatClick">
                <v-btn class="button">
                  <v-icon class="button-icon">mdi-chat</v-icon>
                  <span>{{ $t('chat') }}</span>
                </v-btn>
              </a>
            </v-row>
            <v-row class="button-row">
              <v-btn class="button" @click="closeDialog">{{ $t("closeDialog") }}</v-btn>
            </v-row>
            <v-row class="button-row">
              <v-btn class="button" to="/logout">{{ $t("logOut") }}</v-btn>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import { useCookieStore } from "@/store"
import Vue from "vue"
import analytics from "@/lib/analytics"
import contact from "@/config/contact"

export default {
  name: "LogoutDialog",
  data(): any {
    return {
      showDialog: false,
      chatUrl: "/chat"
    }
  },
  computed: {
    contact(): any {
      return contact
    }
  },
  methods: {
    logout: function (this: Vue): void {
      const { analyticDataLayer } = useCookieStore()
      if (analyticDataLayer.value.inDefaultNoPmtPlan == true) {
        analytics.plugins.optimizely.trackEvent("exit_collection_pop_shown")
        this.$data.showDialog = true
      } else {
        this.$data.showDialog = false
        this.$router.push("/logout")
      }
    },
    whatsAppClick: function(): void {
      analytics.plugins.optimizely.trackEvent("exit_collection_pop_whatsapp_clicked")
    },
    chatClick: function(this: Vue): void {
      analytics.plugins.optimizely.trackEvent("exit_collection_pop_chat_clicked")
    },
    closeDialog: function(this: Vue): void {
      this.$data.showDialog = false
      analytics.plugins.optimizely.trackEvent("exit_collection_pop_voltar_clicked")
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/src/scss/variables.scss';

.title {
  background-color: $simplic-green;
}

.dropdown-link {
  margin-left: 0.5em;
  color: $simplic-dark-text !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.button-icon {
  width: 25px;
  margin-right: 5px;
}

.button-row {
  padding-bottom: 0.5em;
  justify-content: center;
}

#header-text {
  color: white;
  text-align: center;
  line-height: 1.15;
  font-size: 1.5em;
  font-family: "Bree Serif" , serif !important;
}

.sub-text {
  padding-top: 10px !important;
  font-family: $font-family-sans-serif;
  color: #4a4a4a !important;
  font-size: 1em;
}

.button {
  width: 190px;
  height: auto;
  background-color: $simplic-yellow !important;

  font-size: x-large;
  font-weight: bold;
  text-transform: capitalize;
  color: $simplic-copy !important;
}

#nav-drawer--cta--button {
  background-color: $simplic-yellow;
  color: $simplic-copy;
  font-weight: bold;
  display: block;
}

#select-an-option {
  font-weight: bold;
}

</style>
<i18n>
{
  "pt": {
    "logOut": "Sair",
    "areYouSure": "Tem certeza?",
    "chatWithAgentNudge": "Você possui parcelas em aberto, deseja realmente sair da sua área pessoal?",
    "selectAnOption": "Falar com atendente:",
    "whatsApp": "WhatsApp",
    "chat": "Chat",
    "closeDialog": "Voltar"
  }
}
</i18n>
