<script setup lang="ts">
import { computed, ref, watch, withDefaults, defineProps, defineEmits, defineExpose } from "vue"

type AccountTypeListProps = {
  bankType: string;
  accountType?: string;
  caixaAccountType?: string;
  rules?: [];
  disabled?: boolean;
}

const props = withDefaults(defineProps<AccountTypeListProps>(), {
  accountType: "",
  caixaAccountType: "",
  rules: () => [],
  disabled: false
})

const emit = defineEmits<{
  (event: "select", value: string): void
}>()

const currentSelection = ref<string>("")

const selectBradesco = () => {
  if (props.accountType == "") {
    currentSelection.value = ""
  } else {
    currentSelection.value = props.accountType == "checking" ? "corrente" : "poupança"
  }
}

const selectCaixa = () => {
  currentSelection.value = props.caixaAccountType
}

type DisplayAccount = {
  text: string;
  value: string;
}

const bradesco: DisplayAccount[] = [
  { text: "Por favor selecione", value: "" },
  { text: "Conta Corrente Individual - 01", value: "corrente" },
  { text: "Conta Poupança Individual - 02", value: "poupança" }
]

const caixa: DisplayAccount[] =  [
  { text: "Por favor selecione", value: "" },
  { text: "Conta corrente (nova numeração) - 3701", value: "3701" },
  { text: "Conta poupança (nova numeração) - 1288", value: "1288" },
  { text: "Conta corrente - 001", value: "001" }
]


const items = computed((): DisplayAccount[] => {
  if (props.bankType == "237") {
    return bradesco
  } else if (props.bankType == "104") {
    return caixa
  }

  return []
})


const updateCurrentSelectionFromBankType = () => {
  if (props.bankType !== "") {

    if (props.bankType == "237") {
      selectBradesco()
      return
    }

    if (props.bankType == "104") {
      selectCaixa()
      return
    }

    return
  }

  currentSelection.value = ""
}

updateCurrentSelectionFromBankType()

watch(() => props.bankType, () => {
  updateCurrentSelectionFromBankType()
})

const onSelect = (value: string) => {
  currentSelection.value = value
  emit("select", value)
}

const index = items.value.findIndex((element: any) => element.value === currentSelection.value)
const textFieldValue = index >= 0 ? items.value[index].text : ""

defineExpose({
  currentSelection,
  items,
  onSelect
})
</script>

<template>
  <v-text-field
    v-if="disabled"
    label="Tipo de Conta"
    :value="textFieldValue"
    :readonly="true"
    :messages="['só leitura']"
    :persistent-placeholder="true" />
  <v-select
    v-else
    :items="items"
    label="Tipo de Conta"
    :value="currentSelection"
    :outlined="!disabled"
    :filled="disabled"
    :rules="rules"
    :readonly="disabled"
    item-text="text"
    item-value="value"
    @change="onSelect" />
</template>

<style scoped lang="scss">
</style>
