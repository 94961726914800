<template>
  <div id="wrapper">
    <BankConnector />

    <div v-if="showUploadDocsButton" id="documents-link-wrapper">
      <a id="documents-link" href="/lp/income">{{ $t('documentsLink') }}</a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue"
import { useLPStore } from "@/store"
import BankConnector from "@/components/loan-processing/BankConnector.vue"
export default defineComponent({
  name: "BorderEbs",
  components: { BankConnector },
  setup() {
    const { lpType } = useLPStore()
    const noDocUploadStatuses = ["opt_in_lp", "ebs_lp"]
    const showUploadDocsButton = !noDocUploadStatuses.includes(lpType.value)

    return {
      showUploadDocsButton
    }
  }
})
</script>
<style lang="scss" scoped>
  #wrapper {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
  }

  #documents-link-wrapper {
    text-align: center;
    padding: 20px;
  }

  #documents-link {
    text-decoration: none;
    text-align: center;
    color: #185399;
  }
</style>
<i18n>
{
  "pt": {
    "documentsLink": "Ou enviar documentos"
  }
}
</i18n>
