<script setup lang="ts">
import { ref, defineProps, withDefaults, defineEmits, defineExpose } from "vue"
import { updateWhatsAppConsent } from "@/api/secure"
import { publicUpdateWhatsAppConsent } from "@/api/secure/account-home-service"
import { useNotificationsStore } from "@/store"
import VueI18n from "@/plugins/i18n"
import { useRoute } from "vue-router/composables"

type WhatsAppConsentButtonProps = {
  accountID: number;
  token?: string;
  checkbox?: boolean;
  fullWidth?: boolean;
}

const props = withDefaults(defineProps<WhatsAppConsentButtonProps>(), {
  token: "",
  checkbox: false,
  fullWidth: false
})

const emit = defineEmits(["whatsapp_consent_given"])
const route = useRoute()

const { successMessage, errorMessage } = useNotificationsStore()
const updateInProgress = ref(false)
const consentGiven = ref(false)

const updateConsent = async () => {
  updateInProgress.value = true
  await updateWhatsAppConsent(props.accountID)
    .then(() => {
      consentGiven.value = true
      successMessage(VueI18n.t("whatsappConsentSuccess").toString())
      emit("whatsapp_consent_given", process.env.VUE_APP_FULL_URL + route.path)
    })
    .catch(() => {
      errorMessage(VueI18n.t("whatsappConsentError").toString())
    })
    .finally(() => {
      updateInProgress.value = false
    })
}

const updateConsentWithToken = async () => {
  updateInProgress.value = true
  await publicUpdateWhatsAppConsent(props.token ? props.token : "")
    .then(() => {
      consentGiven.value = true
      successMessage(VueI18n.t("whatsappConsentSuccess").toString())
      emit("whatsapp_consent_given", process.env.VUE_APP_FULL_URL + route.path)
    })
    .catch(() => {
      errorMessage(VueI18n.t("whatsappConsentError").toString())
    })
    .finally(() => {
      updateInProgress.value = false
    })
}

const buttonClass = () => {
  if (props.fullWidth) {
    return "button-full-width"
  }
}

defineExpose({
  updateConsentWithToken,
  updateConsent
})
</script>

<template>
  <div id="button-container">
    <button
      :class="buttonClass()"
      :block="$vuetify.breakpoint.name === 'xs'"
      :disabled="updateInProgress || !checkbox"
      @click="accountID ? updateConsent() : updateConsentWithToken()">
      <span class="button-span">
        <v-icon v-if="consentGiven">mdi-check-bold</v-icon>
        <span class="button-span-text">{{ consentGiven ? $t('submitted') : $t('submit') }}</span>
      </span>
    </button>
  </div>
</template>

<style scoped lang="scss">
#button-container {
  padding-top: 20px;
  padding-bottom: 20px;

  .button-full-width {
    width: 100%;
  }

  button {
    background-color: $simplic-yellow;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 8px;
    font-weight: bold;
    color: #333333;

    .button-span {
      font: normal normal bold 16px/24px Open Sans;
      color: $simplic-dark-text;
    }

    .button-span-text {
      height: 24px;
      vertical-align: middle;
    }
  }
  button:disabled {
    opacity: 0.5;
  }
}
</style>

<i18n>
{
  "pt": {
    "submit": "Enviar",
    "submitted": "Enviado"
  }
}
</i18n>
