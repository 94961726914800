<script setup lang="ts">
import { Carousel, Slide } from "vue-carousel"
import { computed, ref, onBeforeMount, withDefaults, defineProps, defineEmits, defineExpose } from "vue"
import { EligibleProductDetails } from "@/api/secure"
import RefinanceQuotesCard from "@/components/account-home/RefinanceQuotesCard.vue"

type RefinanceQuotesProps = {
  productDetails: EligibleProductDetails;
  quotes: [];
  showCarousel?: boolean;
  currentInstallmentAmount: number;
}

const props = withDefaults(defineProps<RefinanceQuotesProps>(), {
  showCarousel: false
})

const emit = defineEmits<{
  (event: "quoteSelected", quote: any): void
}>()

const activeQuoteIndex = ref<number>(-1)
const currentQuotes = ref<any[]>([])
const sortedQuotes = ref<any[]>([])
const groupedQuotes = ref<any>({})

const getRefinanceCardId = (index: number, isPartial: boolean, quote: any) => {
  if (isPartial && quote !== null) {
    return "refinance-card-" + sortedQuotes.value.indexOf(quote)
  }
  return "refinance-card-" + index
}

const selectedQuoteIndex = computed(() => {
  return activeQuoteIndex.value >= 0 ? activeQuoteIndex.value : -1
})

const quoteSelected = function (quote: any) {
  activeQuoteIndex.value = sortedQuotes.value.indexOf(quote)
  emit("quoteSelected", quote)
}

const quoteSelectedMobile = function (quote: any) {
  emit("quoteSelected", quote)
}

const isSelectedQuote = function(index: number, isPartial: boolean, quote: any) {
  if (isPartial && quote !== null) {
    return selectedQuoteIndex.value === sortedQuotes.value.indexOf(quote)
  }
  return selectedQuoteIndex.value === index
}

const mouseOverHandler = function(index: number, isPartial: number, quote: any) {
  if (isPartial && quote !== null) {
    activeQuoteIndex.value = sortedQuotes.value.indexOf(quote)
  } else {
    activeQuoteIndex.value = index
  }
}

const mouseOutHandler = function() {
  activeQuoteIndex.value = -1
}

const upsellPercentage = function(amount: any) {
  return Math.round((amount / props.currentInstallmentAmount - 1) * 100)
}

onBeforeMount(async() => {
  const current: any[] = []
  if (props.quotes && props.quotes.length > 2) { // partial refi
    props.quotes.forEach((quote: any) => {
      if (quote.InstallmentAmount == props.currentInstallmentAmount) { // selected installment amount quotes
        current.push(quote)
        currentQuotes.value.push(quote)
      } else { // group remaining quotes by installment amount
        if (typeof groupedQuotes.value[quote.InstallmentAmount] === "undefined") {
          groupedQuotes.value[quote.InstallmentAmount] = [quote]
        } else {
          groupedQuotes.value[quote.InstallmentAmount].push(quote)
        }
      }
    })
    sortedQuotes.value = current

    const keys = Object.keys(groupedQuotes.value)
    keys.sort()

    for (let i = 0; i < keys.length; i++) {
      groupedQuotes.value[keys[i]].forEach((quote: any) => {
        sortedQuotes.value.push(quote)
      })
    }
  } else { // refi
    currentQuotes.value = props.quotes
  }
})

defineExpose({
  selectedQuoteIndex
})
</script>

<template>
  <div v-if="currentQuotes.length > 0">
    <div>
      <h2 id="installment-value-p3">
        {{ $t("installmentValue") }}
        <h2 id="installment-value">{{ currentInstallmentAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}</h2>
      </h2>
      <div class="refinance-quotes-container">
        <ul
          v-if="!showCarousel"
          class="refinance-quotes-section">
          <li
            v-for="(quote, i) in currentQuotes"
            :key="i"
            @mouseover="mouseOverHandler(i, false, null)"
            @mouseout="mouseOutHandler">
            <refinance-quotes-card
              :id="getRefinanceCardId(i, false, null)"
              :quote="quote"
              :product-details="productDetails"
              :active="isSelectedQuote(i, false, null)"
              @buttonClicked="quoteSelected" />
          </li>
        </ul>
        <carousel
          v-else
          :per-page="1"
          :navigate-to="selectedQuoteIndex"
          pagination-active-color="#01a201"
          pagination-color="#9b9b9b"
          class="refi-carousel"
          style="margin-top: 0;">
          <slide
            v-for="(quote, i) in currentQuotes"
            :key="i">
            <refinance-quotes-card
              :id="getRefinanceCardId(i, false, null)"
              :quote="quote"
              :product-details="productDetails"
              class="refinance-card-slide"
              @buttonClicked="quoteSelectedMobile" />
          </slide>
        </carousel>
      </div>
    </div>
    <div v-if="Object.keys(groupedQuotes).length !== 0">
      <div
        v-for="(quoteList, installmentAmount, index) in groupedQuotes"
        :key="index">
        <hr aria-hidden="true" />
        <div class="upsell-text">
          <p>
            {{ $t("increaseInstallmentTextFirst") }}{{ upsellPercentage(installmentAmount) }}{{ $t("increaseInstallmentTextSecond") }}
          </p>
        </div>
        <h2 id="installment-value-p3">
          {{ $t("installmentValue") }}
          <h2 id="installment-value">{{ Number(installmentAmount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}</h2>
        </h2>
        <div class="refinance-quotes-container">
          <ul
            v-if="!showCarousel"
            class="refinance-quotes-section">
            <li
              v-for="(quote, i) in quoteList"
              :key="i"
              @mouseover="mouseOverHandler(i, true, quote)"
              @mouseout="mouseOutHandler">
              <refinance-quotes-card
                :id="getRefinanceCardId(i, true, quote)"
                :quote="quote"
                :product-details="productDetails"
                :active="isSelectedQuote(i, true, quote)"
                @buttonClicked="quoteSelected" />
            </li>
          </ul>
          <carousel
            v-else
            :per-page="1"
            :navigate-to="selectedQuoteIndex"
            pagination-active-color="#01a201"
            pagination-color="#9b9b9b"
            class="refi-carousel"
            style="margin-top: 0;">
            <slide
              v-for="(quote, i) in quoteList"
              :key="i">
              <refinance-quotes-card
                :id="getRefinanceCardId(i, true, quote)"
                :quote="quote"
                :product-details="productDetails"
                class="refinance-card-slide"
                @buttonClicked="quoteSelectedMobile" />
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.refinance-quotes-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
}
.refinance-quotes-container {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  min-height: 325px;
}

.refinance-quotes-header span {
  align-self: baseline;
}

ul.refinance-quotes-section {
  list-style: none;
  min-height: 280px;
}
ul.refinance-quotes-section li {
  padding-left: 12px;
  padding-right: 12px;
}
.refinance-card-slide {
  margin: 1px 10px 1px 10px;
}

#installment-value-p3 {
  font: normal normal bold 16px Open Sans;
  padding: 15px;
  color: $simplic-dark-text;
}

#installment-value {
  font: normal normal bold 24px Open Sans;
  color: $simplic-green;
}

.upsell-text {
  margin-top:auto;
  margin-bottom:auto;
  text-align:left;
  padding: 0 15px;
  p {
    font: normal normal bold 17px Open Sans;
    color: $simplic-dark-text;
    margin: 0 auto;
  }
}

hr {
    background-color: $simplic-light-gray;
    border: none;
    height: 1px;
    clear: left;
    margin-bottom: 10px;
  }

.refi-carousel {
  padding-bottom: 20px;
}

@media screen and (max-width: 520px) {
  .refinance-quotes-header span {
    align-self: auto;
  }
}
</style>
<i18n>
  {
    "pt": {
      "installmentValue": "Valor da Parcela: ",
      "increaseInstallmentTextFirst": "Aumente o valor da sua parcela em ",
      "increaseInstallmentTextSecond": "% e receba agora um valor extra em sua conta:"
    }
  }
  </i18n>
