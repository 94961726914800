<template>
  <div>
    <v-navigation-drawer id="nav-drawer" app width="85%" :value="navDrawerOpen" @input="updateDrawer">
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-list-item-title id="nav-drawer--logo-container">
            <router-link to="/account_home"><img id="nav-drawer--logo" height="45px" contain :alt="$t('simplic')" src="~@/assets/header-logo.png" /></router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list
        nav>
        <template v-for="item in items">
          <v-list-item
            :key="item.title"
            :to="item.path"
            link>
            <v-list-item-icon>
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="nav-drawer--link">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`list-item-divider-${item.title}`" />
        </template>
      </v-list>
      <div id="nav-drawer--cta--container">
        <span id="nav-drawer--cta--phone-number"><v-icon dark>mdi-whatsapp</v-icon>{{ contact.whatsApp.display }}</span>
        <v-btn id="nav-drawer--cta--button" width="90%" class="mx-auto" @click="logIn">
          {{ $t("login") }}
        </v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script lang="ts">
import contact from "@/config/contact"
import { defineComponent } from "vue"
import { useSessionStore } from "@/store"
export default defineComponent({
  name: "NavigationDrawerUnAuth",
  setup: (_, { emit  }) => {
    const updateDrawer = (payload: boolean) => {
      emit("input", payload)
    }
    const { logIn, navDrawerOpen } = useSessionStore()
    const items = [
      {
        title: "Como Funciona",
        path: "/como-funciona",
        icon: "mdi-format-list-numbered"
      },
      {
        title: "Quem Somos",
        path: "/quem_somos",
        icon: "mdi-account-multiple"
      },
      {
        title: "Ajuda",
        path: "/faq",
        icon: "mdi-help-box"
      },
      {
        title: "Blog",
        path: "/blog",
        icon: "mdi-file-document-multiple"
      }
    ]
    return {
      updateDrawer,
      items,
      contact,
      logIn,
      navDrawerOpen
    }
  }
})
</script>
<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
  #nav-drawer {
    background-color: $simplic-green;
    max-width: 375px;
    .nav-drawer--link {
      color: $simplic-white;
      font-weight: bold;
      letter-spacing: 0.05em;
    }
  }
  #nav-drawer--logo {
    margin: 6px auto 2px auto;
  }
  #nav-drawer--logo-container {
    background-color: $simplic-white;
    text-align: center;
  }
  #nav-drawer--cta--container {
    position: absolute;
    bottom: 3em;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #nav-drawer--cta--button {
    background-color: $simplic-yellow;
    color: $simplic-dark-text;
    font-weight: bold;

    &:hover {
      background-color: $simplic-button-hover;
    }
  }
  #nav-drawer--cta--phone-number {
    display: block;
    margin: 1em auto;
    font-weight: bold;
    color: $simplic-white;

    i {
      margin-right: 0.5em;
    }
  }
</style>
<i18n>
{
  "pt": {
    "simplic": "Simplic",
    "login": "LOGIN"
  }
}
</i18n>
