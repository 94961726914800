<script setup lang="ts">
import { computed } from "vue"
import { useCookieStore, useLPStore } from "@/store"
import DocumentUpload from "@/components/loan-processing/DocumentUpload.vue"

const { activities, getActivities } = useLPStore()
const { analyticDataLayer } = useCookieStore()
const loanNumber = analyticDataLayer.value.loanNumber

const activity = computed(() => {
  for (const activity of activities.value) {
    if (activity.name != "income_verification") {
      continue
    }

    return activity
  }

  return null
})

const status = computed(() => {
  return activity.value?.status
})

const reason = computed(() => {
  return activity.value?.reason
})

const uploaded = async () => {
  if (loanNumber) {
    await getActivities(loanNumber, {})
  }
}

defineExpose({
  uploaded
})
</script>

<template>
  <div id="wrapper">
    <div id="heading-text">
      <div v-if="status === 'invalid'" id="error">
        <p v-if="reason && reason !== ''">{{ reason }}</p>
        <p v-else>{{ $t('errorText') }}</p>
        <hr id="error-hr" />
      </div>
      <p v-if="status === 'in_review'" v-html="$t('textInReview')" />
      <p v-else>{{ $t('text') }}</p>
    </div>
    <div id="clearfix" />

    <h2 id="upload-document-text">{{ $t('uploadDocumentText') }}</h2>
    <v-sheet
      id="doc-upload"
      elevation="1"
      class="ma-md-5">
      <DocumentUpload
        document-type="bank_statement"
        @document-uploaded="uploaded" />
      <ul id="income-card-info">
        <li v-html="$t('incomeCardText')" />
      </ul>
    </v-sheet>
  </div>
</template>

<style lang="scss" scoped>
  #wrapper {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
  }

  #heading-text {
    float: left;
    padding: 20px 20px 5px 20px;
  }

  #income-card-info {
    padding: 0 20px 10px 20px;
    font: 16px Open Sans;
  }

  #doc-upload {
    padding: 20px;
  }

  @media screen and (max-width: 950px) {
    #heading-text {
      float: none;
      width: 90%;
      margin: auto;
      padding: 10px;
    }
  }

  #error {
    color: #D60000;
  }

  #clearfix {
    clear: both;
  }

  #upload-document-text {
    padding-left: 20px;
    font: 20px Open Sans;
    font-weight: 500;
  }

  #error-hr {
    color: #707070;
    opacity: .23;
    height: 2px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
  }

</style>
<i18n>
{
  "pt": {
    "text": "Caso não tenha feito a sua validação via Simplic Express, você pode anexar o seu comprovante de renda abaixo.",
    "textInReview": "<strong>No momento, estamos analisando os seus documentos.</strong> Fique atento ao seu e-mail, pois podemos solicitar informações adicionais.",
    "uploadDocumentText": "Enviar documento",
    "incomeCardText": "O comprovante de renda deve ser um <strong>extrato bancário dos últimos 90 dias</strong> da conta bancária cadastrada na proposta.<br><br><strong>Atenção:</strong> para sua segurança, o extrato enviado deve mostrar a titularidade da conta cadastrada na <strong>sua proposta, ou seja, o seu CPF, nome e o número de conta cadastrada</strong> na proposta devem estar identificados nos documentos.",
    "errorText": "O documento enviado é inválido. Envie um novo documento para análise. Lembre-se que ele precisa estar nítido e não pode haver cortes na foto."
  }
}
</i18n>
