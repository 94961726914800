<template>
  <div id="loan-history-container">
    <p id="loan-history--header">
      {{ $t('loanHistory') }}
    </p>
    <hr />
    <div v-if="loaded">
      <loan-history-card :loans="pastLoans" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, Ref, onMounted, getCurrentInstance } from "vue"
import LoanHistoryCard from "@/components/account-home/LoanHistoryCard.vue"
import { useAccountDetailsStore } from "@/store"
import { Loan } from "@/api/secure/account-home-service"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "LoanHistory",
  components: { LoanHistoryCard },
  setup() {
    const { loans } = useAccountDetailsStore()
    const pastLoans: Ref<Loan[]> = ref([])
    const loaded: Ref<boolean> = ref(false)
    const vueInstance = getCurrentInstance()

    const getPastLoans = () => {
      if (loans != null && loans.value) {
        for(let i = 0; i < loans.value.length; i++) {
          if (loans.value[i].loan_status === "paid_off" || loans.value[i].loan_status === "sold") {
            pastLoans.value.push(loans.value[i])
          }
        }
      }
    }

    onMounted(() => {
      loaded.value = false
      getPastLoans()
      loaded.value = true

      tagRecordings((vueInstance as unknown) as VueWithHotjar, ["loan_history_view_displayed"])
    })

    return {
      pastLoans,
      loaded,
      getPastLoans
    }
  }
})
</script>
<style lang="scss" scoped>
#loan-history-container {
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: 1em 0.25em;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;

  #loan-history--header {
    font: normal normal bold 20px/24px Open Sans;
    padding: 20px 0;
    margin: 0 auto;
  }
}
</style>
<i18n>
{
  "pt": {
    "loanHistory": "Histórico de empréstimos"
  }
}
</i18n>
