<template>
  <VContainer id="account-home-banner-background" class="pa-6 pa-sm-12" fluid>
    <Breadcrumbs />
    <h1 v-if="accountHomeHeadlineComputed != ''" id="account-home-banner-text">
      {{ accountHomeHeadlineComputed }}
    </h1>
  </VContainer>
</template>
<script setup lang="ts">
import { useSessionStore } from "@/store"
import Breadcrumbs from "@/components/Breadcrumbs.vue"

const { accountHomeHeadlineComputed } = useSessionStore()

</script>
<style lang="scss" scoped>
  #account-home-banner-text {
    color: white;
    text-align: center;
    line-height: 1.15;
    font-size: 1.5em;
  }
  #account-home-banner-background {
    background-image: url("~@/assets/account_home_banner_background.jpg");
    background-size: cover;
    background-color: green;
    width: 100%;
    min-height: 1.5em;
    padding: 35px 48px 48px 48px !important;
  }
  @media (min-width: 375px) {
    #account-home-banner-text {
      line-height: 1.5;
      font-size: 2em;
    }
  }
  @media (max-width: 375px) {
    #account-home-banner-background {
      padding: 35px 48px 48px 20px !important;
    }
    #account-home-banner-text {
      padding-top: 7px;
      padding-left: 28px;
    }
  }
</style>
