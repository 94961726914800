<template>
  <div>
    <p>{{ $t('instructions') }}</p>
    <serasa-questionnaire-form :cpf="cpf" :account-i-d="accountID" @submit-score-range="acceptScoreRange" />
  </div>
</template>

<script lang="ts">
import { useAccountStore, useCookieStore } from "@/store"
import { defineComponent, ref } from "vue"
import SerasaQuestionnaireForm from "@/components/loan-processing/SerasaQuestionnaireForm.vue"
import { SerasaScoreRange, updateActivities } from "@/api/secure/lp-service"

//This page mainly added to demonstrate integration of the Serasa Questionnaire Form
export default defineComponent({
  name: "SerasaQuestionnairePage",
  components: { SerasaQuestionnaireForm },
  setup() {
    const { accountID, cpf } = useAccountStore()
    const { analyticDataLayer } = useCookieStore()
    const loanNumber = analyticDataLayer.value.loanNumber
    const scoreRange = ref<SerasaScoreRange | null>(null)
    const minSuccessfulScore = 719 // 719 to 1000 is low fraud risk

    const acceptScoreRange = async (sr: SerasaScoreRange) => {
      scoreRange.value = sr
      if (scoreRange.value.score >= minSuccessfulScore && loanNumber != null) {
        await updateActivities(loanNumber, [{ type: "identity_verification", new_status: "success" }])
          .catch()
      } else {
        await updateActivities(loanNumber!, [{ type: "identity_verification", new_status: "failure" }])
          .catch()
      }
      window.location.href = "/lp"
    }

    return {
      accountID,
      cpf,
      scoreRange,
      acceptScoreRange
    }
  }
})
</script>

<i18n>
{
  "pt": {
    "instructions": "Responda as perguntas abaixo selecionando a opção correta. Depois clique em Enviar."
  }
}
</i18n>
