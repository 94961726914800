<script setup lang="ts">
import { checkCode } from "@/api/secure"
import { onMounted, Ref, ref, defineEmits, defineExpose } from "vue"
import { requestCode } from "@/api/secure"
import { CodeCheckPayload, CodeRequestPayload, CodeRequestResponse } from "@/api/secure/frontend-service"
import { useLPStore } from "@/store"
import { logError } from "@/lib/analytics"

const emit = defineEmits<{
  (event: "phoneNumberValidated", validated: boolean): void
  (event: "sent-code"): void
}>()

let resendTimeout = 90000 // 1 minute 30 seconds
const loading: Ref<boolean> = ref(false)
const { activities } = useLPStore()
const showCodeInvalid: Ref<boolean> = ref(false)
const codeSubmitting: Ref<boolean> = ref(false)
const code: Ref<string> = ref("")
const resendCodeEnabled = ref(false)
const resendCountdown = ref(resendTimeout / 1000)
const countdownTimer = ref()

const validateCode = async () => {
  const c = code.value
  if (c.length !== 4) {
    return false
  }
  codeSubmitting.value = true
  const pData: CodeCheckPayload = {
    code: c,
    which_phone: "main_phone",
    main_phone_verification_1: c[0],
    main_phone_verification_2: c[1],
    main_phone_verification_3: c[2],
    main_phone_verification_4: c[3]
  }
  const response: CodeRequestResponse = await checkCode(pData)
  codeSubmitting.value = false
  if (response.status !== "valid_code") {
    showCodeInvalid.value = true
    code.value = ""
    emit("phoneNumberValidated", false)
    return
  }
  showCodeInvalid.value = false
  emit("phoneNumberValidated", true)
  activities.value.forEach(activityData => {
    if(activityData.name == "phone_verification") {
      activityData.status = "success"
    }
  })
}

const onChange = (c: string) => {
  code.value = c
}

const onFinish = (c: string) => {
  code.value = c
  validateCode()
}

const countdown = () => {
  resendCountdown.value--
  if (resendCountdown.value === 0) {
    resendCodeEnabled.value = true
    clearInterval(countdownTimer.value)
  }
}

const resendCodeTimer = () => {
  resendCodeEnabled.value = false
  if (process.env.VUE_APP_ENVIRONMENT !== "production") {
    resendTimeout = 10000
  }
  resendCountdown.value = resendTimeout / 1000
  countdownTimer.value = setInterval(countdown, 1000)
  setTimeout(() => {
    resendCodeEnabled.value = true
    clearInterval(countdownTimer.value)
  }, resendTimeout)
}

const resendCode = async () => {
  loading.value = true
  const pData: CodeRequestPayload = { which_phone: "main_phone" }
  const response: CodeRequestResponse = await requestCode(pData)
  if (!["success", "active_code_request"].includes(response.status)) {
    const requestCodeMessage = response.message
    logError(requestCodeMessage)
  }
  loading.value = false
  resendCodeTimer()
  emit("sent-code")
}

onMounted(() => {
  resendCodeTimer()
  if ("OTPCredential" in window) {
    window.addEventListener("DOMContentLoaded", () => {
      const input = document.querySelector("input[autocomplete=\"one-time-code\"]")
      if (!input) return
      const ac = new AbortController()
      const form = input.closest("form")
      if (form) {
        form.addEventListener("submit", () => {
          ac.abort()
        })
      }
      navigator.credentials.get({
        otp: { transport:["sms"]},
        signal: ac.signal
      } as never).then((otp: (Credential & { code?: string }) | null) => {
        code.value = otp?.code || ""
        if (form) form.submit()
      }).catch(err => {
        logError(err)
      })
    })
  }
})

defineExpose({
  loading,
  resendCodeTimer,
  resendCode,
  resendCodeEnabled,
  resendCountdown,
  showCodeInvalid,
  codeSubmitting,
  code,
  onChange,
  onFinish,
  validateCode
})
</script>

<template>
  <div id="code-verification-container">
    <v-divider />
    <p v-html="$t('enterSMSCode')" />
    <div class="code-and-button">
      <v-form
        id="code-validation-form"
        ref="form"
        @submit.prevent="validateCode">
        <div id="code-input-container">
          <v-otp-input
            v-model="code"
            class="code-input"
            length="4"
            placeholder="0"
            type="number"
            autocomplete="one-time-code"
            @change="onChange"
            @finish="onFinish" />
        </div>
        <div class="validate-btn-container">
          <v-btn
            ref="validate"
            class="validate-btn"
            type="action"
            :depressed="true"
            :submit="true"
            :loading="codeSubmitting"
            @click="validateCode">
            <span class="validate-btn-text">{{ $t('validate') }}</span>
          </v-btn>
        </div>
        <div v-if="showCodeInvalid" class="code-verification-error-message">
          {{ $t('invalidCode') }}
        </div>
      </v-form>
    </div>
    <div class="clearfix" />
    <div>
      <v-btn
        class="cta-btn resend-code-button"
        type="action"
        :depressed="true"
        :disabled="!resendCodeEnabled"
        :loading="loading"
        @click="resendCode">
        <span class="btn-text">
          {{ resendCodeEnabled ? $t('resendCode') : `${$t('resendCode')} (${resendCountdown})` }}
        </span>
      </v-btn>
    </div>
    <div class="clearfix" />
  </div>
</template>

<style lang="scss">
.resend-code-button {
  border: 1px solid $simplic-yellow;
  background-color: #fff !important;
  &:hover {
    background-color: $simplic-yellow !important;
    .btn-text {
      color: #fff !important;
    }
  }
  &:disabled {
    border: none;
    cursor: not-allowed;
    pointer-events: all !important;
    .btn-text {
      color: #616161 !important;
    }
  }
  .btn-text {
    font: normal normal bold 14px/16px Open Sans;
    letter-spacing: 0;
    color: #4A4A4A;
    opacity: 1;
  }
}
#code-verification-container {
  text-align: center;

  p {
    padding-top: 20px;
  }

  .code-and-button{
    #code-validation-form {
      text-align: center;
      width: 400px;
      margin: auto;
      #code-input-container {
        width: 300px;
        height: 100px;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;

        .code-input {
          padding: 7px 7px 0 7px;
          .v-input__slot {
            width: 45px;
            height: 44px;
          }
          .v-text-field {
            text-align: center;
          }
        }
      }
    }
    .validate-btn-container {
      width: 100px;
      height: 100px;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;

      .validate-btn {
        background-color: $simplic-yellow;
        color: $simplic-copy;
        .validate-btn-text {
          font: normal normal bold 14px/16px Open Sans;
          letter-spacing: 0;
          color: #4A4A4A;
          opacity: 1;
        }
      }
    }
    .code-verification-error-message {
      font-weight: bold;
      font-size: 15px;
      color: #D60000;
      display: inline-flex;
      padding-bottom: 15px;
    }
  }
}
@media screen and (max-width: 880px) {
  #code-verification-container {
    p {
      margin: 0 auto;
    }
    .code-and-button {
      #code-validation-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        #code-input-container {
          padding-top: 20px;
          float: none;
          order: 1;
          width: 100%;
        }
        .v-text-field__details {
          display: none;
        }
        .validate-btn-container {
          padding-top: 30px;
          order: 3;
          width: 100%;
            .validate-btn {
              float: none;
              right: 0;
              height: 44px;
              width: 100%;
          }
        }
        .code-verification-error-message {
          padding-top: 10px;
          padding-bottom: 0;
          order: 2;
        }
      }
    }
  }
}
</style>

/* eslint-disable @intlify/vue-i18n/no-html-messages */
<i18n>
{
  "pt": {
    "enterSMSCode": "<strong>Por favor, verifique o código de validação que enviamos por SMS.</strong>",
		"validate": "Validar",
		"invalidCode": "Código inválido. Por favor, tente novamente.",
    "resendCode": "Reenviar código"
  }
}
</i18n>
/* eslint-enable @intlify/vue-i18n/no-html-messages */
