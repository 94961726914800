var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{ref:"card",attrs:{"id":"phone-verification-card"}},[_c('v-card-text',{staticClass:"phone-number"},[_c('div',{attrs:{"id":"phone-number-container"}},[_c('div',{attrs:{"id":"phone-number-input"}},[_c('label',{staticClass:"cell-label-text"},[_vm._v(" "+_vm._s(_vm.$t('cellLabel'))+" ")]),_c('div',{staticClass:"phone-field-container"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],ref:"phoneNumberInput",class:{'valid-phone': _vm.showPhoneNumberValid, 'invalid-phone': _vm.showPhoneNumberInvalid},attrs:{"type":"tel","outlined":"","single-line":"","dense":"","rules":[
              _vm.rules.minPhoneLength.validate({
                value: _vm.phoneNumber,
                length: 10,
                message: _vm.$t('invalidNumber')
              })
            ],"validate-on-blur":true},on:{"focus":_vm.editNumber,"blur":_vm.blurNumber},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('img',{attrs:{"src":require("@/assets/field-edit.svg"),"alt":"field-edit"}})],1)]),_c('div',{ref:"validationChips",staticClass:"request-code-button"},[(!_vm.editingPhone)?_c('v-btn',{staticClass:"cta-btn",attrs:{"type":"action","depressed":true,"loading":_vm.loading},on:{"click":_vm.sendCode}},[_c('span',{staticClass:"btn-text"},[_vm._v(_vm._s(_vm.$t('requestCode')))])]):_vm._e(),(_vm.editingPhone)?_c('v-btn',{staticClass:"cta-btn",attrs:{"type":"action","depressed":true,"loading":_vm.loading},on:{"click":_vm.updateNumber}},[_c('span',{staticClass:"btn-text"},[_vm._v(_vm._s(_vm.$t('save')))])]):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }