<script setup lang="ts">
import { onMounted, ref } from "vue"
import WhatsAppConsentInfo from "@/components/whatsapp/WhatsAppConsentInfo.vue"
import WhatsAppConsentCheckbox from "@/components/whatsapp/WhatsAppConsentCheckbox.vue"
import WhatsAppConsentButton from "@/components/whatsapp/WhatsAppConsentButton.vue"
import { useAccountStore } from "@/store"
import { getWhatsAppDisplayDetails } from "@/api/secure/account-home-service"
import { useRoute } from "vue-router/composables"

const { accountID } = useAccountStore()
const partialPhone = ref("")
const partialCPFFirst = ref("")
const partialCPFSecond = ref("")
const consentCheckbox = ref(false)
const route = useRoute()

onMounted(async () => {
  if (!accountID.value) {
    await getWhatsAppDisplayDetails(route.params.token).then((response) => {
      partialPhone.value = response.partial_phone
      const cpf = response.partial_cpf
      partialCPFFirst.value = cpf.substring(0, 2)
      partialCPFSecond.value = cpf.substring(2, 4)
    })
  }
})

const consentCheckboxClicked = (checkbox: boolean): void => {
  consentCheckbox.value = checkbox
}

defineExpose({
  consentCheckbox,
  consentCheckboxClicked
})
</script>

<template>
  <div>
    <div v-if="$vuetify.breakpoint.name === 'xs'">
      <v-container ma-0 pa-0>
        <v-row no-gutters>
          <v-col cols="9">
            <whats-app-consent-info :partial-phone="partialPhone" :cpf-first-two="partialCPFFirst" :cpf-second-two="partialCPFSecond" />
          </v-col>
          <v-col cols="3">
            <div>
              <img src="../../assets/images/svg/whatsapp-phone-illustration.svg" alt="whatsapp-phone-illustration" />
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" no-gutters>
            <whats-app-consent-checkbox @consentCheckbox="consentCheckboxClicked" />
            <whats-app-consent-button :account-i-d="accountID" :token="$route.params.token" :checkbox="consentCheckbox" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-container ma-0 pa-0>
        <v-row no-gutters>
          <v-col cols="10">
            <div>
              <whats-app-consent-info :partial-phone="partialPhone" :cpf-first-two="partialCPFFirst" :cpf-second-two="partialCPFSecond" />
              <whats-app-consent-checkbox @consentCheckbox="consentCheckboxClicked" />
            </div>
            <div>
              <whats-app-consent-button :account-i-d="accountID" :token="$route.params.token" :checkbox="consentCheckbox" />
            </div>
          </v-col>
          <v-col cols="2">
            <img src="../../assets/images/svg/whatsapp-phone-illustration.svg" alt="whatsapp-phone-illustration" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .whats-app-phone-icon {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
</style>
