<template>
  <div id="loan-processing">
    <Hero :completed="showCompletedHeader()" :opted-in="optInStatus()" :opted-in-feature-enabled="showOptIn" :opted-in-savings="optInSavings()" />
    <div v-if="pendingActivities.length > 0 || invalidPhoneVerification">
      <h3 class="activity-header">{{ $t("needsValidation") }}</h3>
      <Activity
        v-if="invalidPhoneVerification"
        key="phone_verification"
        status="invalid"
        :name="activityLinks['phone_verification']"
        :show-link="false"
        :header="activityHeader('phone_verification')">
        <p>{{ $t("invalid.phoneVerification.instructions") }}</p>
        <p>
          <a
            :href="contact.phoneCity.url">{{ $t("invalid.phoneVerification.phoneCityLink", {phone: contact.phoneCity.display}) }}</a>
          {{ $t("invalid.phoneVerification.phoneCityText") }}
        </p>
        <p>
          <a :href="contact.phoneOther.url">{{ contact.phoneOther.display }}</a>
          {{ $t("invalid.phoneVerification.phoneOtherText") }}
        </p>
        <p>
          <a :href="contact.whatsApp.url">{{ contact.whatsApp.display }}</a>
          {{ $t("invalid.phoneVerification.whatsAppText") }}
        </p>
      </Activity>
      <template v-for="activity in pendingActivities">
        <Activity
          v-if="activityLinks[activity.name]"
          :key="activity.name"
          :status="activityStatus(activity)"
          :name="activityLinks[activity.name]"
          :show-link="true"
          :header="activityHeader(activity.name)" />
      </template>
    </div>
    <div v-if="underReviewActivities.length > 0">
      <h3 class="activity-header">{{ $t("needsReview") }}</h3>
      <template v-for="activity in underReviewActivities">
        <Activity
          v-if="activityLinks[activity.name]"
          :key="activity.name"
          :status="activityStatus(activity)"
          :name="activityLinks[activity.name]"
          :show-link="true"
          :header="activityHeader(activity.name)" />
      </template>
    </div>
    <div v-if="completedActivities.length > 0">
      <h3 class="activity-header">{{ $t("completed") }}</h3>
      <template v-for="activity in completedActivities">
        <Activity
          v-if="activityLinks[activity.name]"
          :key="activity.name"
          :status="activityStatus(activity)"
          :header="activityHeader(activity.name)" />
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, Ref, ref } from "vue"
import analytics from "@/lib/analytics"
import Hero from "@/components/loan-processing/Hero.vue"
import Activity from "@/components/loan-processing/Activity.vue"
import { useLPStore } from "@/store"
import { LPActivityData } from "@/api/secure/lp-service"
import lodash from "lodash"
import contact from "@/config/contact"

export default defineComponent({
  name: "LoanProcessing",
  components: { Hero, Activity },
  setup() {
    const { activities, directDebitOptIn, primaryOffer, secondaryOffer } = useLPStore()
    const showOptIn: Ref<boolean> = ref(false)

    const pendingActivities = computed(() => lodash.filter(activities.value, (activity) => {
      // invalid phone verification is handled by template
      if (activity.name === "phone_verification" && activity.status === "invalid") {
        return false
      }

      return ["in_progress", "invalid"].includes(activity.status)
    }))
    const underReviewActivities = computed(() => lodash.filter(activities.value, (activity) => activity.status === "in_review"))
    const completedActivities = computed(() => lodash.filter(activities.value, (activity) => !["in_progress", "in_review", "invalid"].includes(activity.status)))

    const activityLinks = {
      "phone_verification": "ConfirmTelephone",
      "identity_verification": "ConfirmIdentity",
      "income_verification": "ConfirmEBS"
    }

    const activityStatus = (activity: LPActivityData) => {
      if (["success", "failure"].includes(activity.status)) {
        return "complete"
      }

      return activity.status
    }

    const invalidPhoneVerification = computed(() => {
      for (const activity of activities.value) {
        if (activity.name === "phone_verification" && activity.status === "invalid") {
          return true
        }
      }

      return false
    })

    const showCompletedHeader = () => {
      return pendingActivities.value.length == 0 && !invalidPhoneVerification.value
    }

    const optInStatus = () => {
      return directDebitOptIn.value
    }

    const optInSavings = () => {
      const savingsAmount = Number(secondaryOffer?.value?.first_installments_amount) - Number(primaryOffer?.value?.first_installments_amount)
      if (isNaN(savingsAmount)) {
        return 0
      }

      return Math.abs(savingsAmount)
    }

    const showOptInFeature = () => {
      showOptIn.value = analytics.plugins.optimizely.isFeatureEnabled("opt_in_on_lp_page")
    }

    onMounted(async () => {
      analytics.track("lp_page_viewed")
      showOptInFeature()
    })

    return {
      activities,
      activityLinks,
      activityStatus,
      directDebitOptIn,
      optInStatus,
      optInSavings,
      showOptIn,
      pendingActivities,
      completedActivities,
      underReviewActivities,
      invalidPhoneVerification,
      contact,
      showCompletedHeader
    }
  },
  methods: {
    activityHeader(activityName: string) {
      switch (activityName) {
      case("phone_verification"):
        return this.$t("activities.phoneVerification")
      case("identity_verification"):
        return this.$t("activities.identityVerification")
      case("income_verification"):
        return this.$t("activities.incomeVerification")
      }
    }
  }
})
</script>
<style lang="scss" scoped>
#loan-processing {
  .invalid-phone-verification {
    padding-top: 1.5em;
  }

  .activity-header {
    padding: 1.5em 0 1em 0;
    font-size: 1em;
  }

  a {
    color: #165399;
    font-weight: bold;
  }

  .activity-container {
    p {
      font-size: .9em;
      margin: 1em 0 .6em 0;
    }
  }
}
</style>
<i18n>
{
  "pt": {
    "needsValidation": "Necessário validação",
    "needsReview": "Em análise",
    "completed": "Enviado",
    "activities": {
      "phoneVerification": "Validação de telefone",
      "identityVerification": "Comprovação de identidade",
      "incomeVerification": "Comprovação de renda"
    },
    "invalid": {
      "phoneVerification": {
        "instructions": "Para concluirmos a análise da sua proposta, solicitamos que entre em contato com a nossa Central de Atendimento nos telefones abaixo ou via WhatsApp.",
        "phoneCityText": "(Capitais)",
        "phoneCityLink": "(11) {phone}",
        "phoneOtherText": "(Demais Regiões)",
        "whatsAppText": "(WhatsApp)"
      }
    }
  }
}
</i18n>
