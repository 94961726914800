import { Module } from "vuex"
import store, { State, useAccountStore, useCookieStore, useNotificationsStore } from "@/store"
import { buildHelpers } from "@/store/composition-helpers"
import { WritableComputedRef } from "vue"
import { updateField, getField } from "vuex-map-fields"
import { BarcodePayload, callToConfirmOffer, callToFetchBoletoBarcode, callToFetchPixBarcode, callToGetBoletoPdf, callToGetPixPdf, ConfirmOfferPayload, generatePaymentPlanOffersV2, PaymentPlan, PaymentPlanOffer, PaymentPlanOffersPayload } from "@/api/secure/sysopen-gateway-service"
import { logError } from "@/plugins/sentry"
import { paymentPlanGetters } from "./getters"
import analytics from "@/lib/analytics"

const { mapGettersAndSettersToComputed } = buildHelpers<PaymentPlansStore>(
  () => store, "paymentPlans"
)

interface PaymentPlansStore {
  loading: boolean;
  offers: PaymentPlanOffer[] | null;
  selectedOffer: PaymentPlanOffer | null;
  confirmed: boolean;
  boletoBarcode: string;
  boletoPdf: string;
  pixBarcode: string;
  pixPdf: string;
  firstDueDate: string | null;
  downPaymentAmount: string | null;
  withDownPayment: boolean | null;
  paymentPlans: PaymentPlan[];
  currentPaymentMethod: string;
  paymentMethodVariation: string;
}

export const newPaymentPlansStoreState = (): PaymentPlansStore => {
  return {
    offers: [],
    selectedOffer: null,
    loading: true,
    confirmed: false,
    boletoBarcode: "",
    boletoPdf: "",
    pixBarcode: "",
    pixPdf: "",
    firstDueDate: null,
    downPaymentAmount: null,
    withDownPayment: false,
    paymentPlans: [],
    currentPaymentMethod: "",
    paymentMethodVariation: ""
  }
}
const paymentPlansState: PaymentPlansStore = newPaymentPlansStoreState()

interface PaymentPlansAccessors {
  loading: WritableComputedRef<boolean>;
  offers: WritableComputedRef<PaymentPlanOffer[]>;
  currentPaymentMethod: WritableComputedRef<string>;
  paymentMethodVariation: WritableComputedRef<string>;
  paymentPlans: WritableComputedRef<PaymentPlan[]>;
  selectedOffer: WritableComputedRef<PaymentPlanOffer | null>;
  confirmed: WritableComputedRef<boolean>;
  boletoBarcode: WritableComputedRef<string>;
  boletoPdf: WritableComputedRef<string>;
  pixBarcode: WritableComputedRef<string>;
  pixPdf: WritableComputedRef<string>;
  firstDueDate: WritableComputedRef<string | null>;
  downPaymentAmount: WritableComputedRef<string>;
  withDownPayment: WritableComputedRef<boolean>;
  fetchOffers: (date: string, downPaymentAmount: string) => Promise<void>;
  selectOffer: (offer: PaymentPlanOffer) => void;
  confirmOffer: () => Promise<boolean>;
  showGenericError: () => void;
  fetchBarcodeByPaymentMethod: () => Promise<boolean>;
  getBoletoPdf: () => Promise<boolean>;
  getPixPdf: () => Promise<boolean>;
}

function usePaymentPlansStore(): PaymentPlansAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(paymentPlansState) as Array<keyof PaymentPlansStore>)
  const genericErrorMessage = "Tivemos um problema ao processar o seu pedido, por favor, tente novamente mais tarde ou entre em contato com a nossa central de atendimento"
  const { loading, offers, selectedOffer, confirmed, boletoBarcode, boletoPdf, pixPdf, pixBarcode, paymentPlans, currentPaymentMethod, paymentMethodVariation } = mapFields
  const { accountID, cpf } = useAccountStore()
  const { analyticDataLayer } = useCookieStore()
  const { errorMessage } = useNotificationsStore()

  const showGenericError = () => {
    errorMessage(genericErrorMessage)
  }

  const setPaymentMethodVariation = () => {
    paymentMethodVariation.value = "boleto"
    currentPaymentMethod.value = "Boleto"
  }

  const fetchOffers = async (date: string, downPaymentAmount: string) => {
    loading.value = true

    const defaultMaxDaysInDefault = 1041
    let maxDaysInDefault = analyticDataLayer.value.maxDaysInDefault

    if (maxDaysInDefault === undefined || maxDaysInDefault === null) {
      logError("analyticDataLayer.value.maxDaysInDefault is null. Sent to sysopen as 1041")
      maxDaysInDefault = defaultMaxDaysInDefault
    }

    if (downPaymentAmount === undefined || downPaymentAmount == null) {
      downPaymentAmount = "000"
    }

    const payload: PaymentPlanOffersPayload = {
      cpf: cpf.value.toString(),
      first_due_date: date,
      down_payment_amount: downPaymentAmount.toString(),
      max_days_in_default: maxDaysInDefault || 0
    }
    try {
      const response = await generatePaymentPlanOffersV2(accountID.value, payload)
      setPaymentMethodVariation()

      if (response.payment_plan_offers?.length) {
        offers.value = response.payment_plan_offers
        analytics.track("payment_plan_offers_loaded")
      } else {
        throw new Error("no offers were returned by sysopen gateway")
      }
    } catch (e) {
      const extras = {
        offers: offers.value
      }
      logError(e, extras)
      showGenericError()
    } finally {
      loading.value = false
    }
  }
  const selectOffer = (offer: PaymentPlanOffer | null) => {
    selectedOffer.value = offer
  }
  const confirmOffer = async () => {
    loading.value = true
    try {
      if (!selectedOffer.value) {
        throw new Error("cannot confirm offer that was not selected")
      }
      const payload: ConfirmOfferPayload = {
        payment_plan_offer_id: selectedOffer.value.payment_plan_offer_id,
        payment_plan_code: selectedOffer.value.payment_plan_code,
        cpf: cpf.value.toString()
      }
      const response = await callToConfirmOffer(accountID.value, payload)
      if (response.payment_plans?.length > 0) {
        paymentPlans.value = response.payment_plans
        confirmed.value = true

        analytics.track("payment_plan_offer_selected", { payment_method: currentPaymentMethod.value })
      } else {
        throw new Error("unable to confirm offer")
      }
      return true
    } catch (e) {
      const extras = {
        selected_offer: selectedOffer.value,
        payment_plans: paymentPlans.value
      }
      logError(e, extras)
      showGenericError()
      return false
    } finally {
      loading.value = false
    }
  }
  const fetchBarcodeByPaymentMethod = async (): Promise<boolean> => {
    loading.value = true

    try {
      const payload: BarcodePayload = {
        cpf: cpf.value.toString(),
        payment_plan_id: paymentPlans.value[0].id || "",
        installment_due_date: selectedOffer.value?.first_due_date || ""
      }
      const pix = await callToFetchPixBarcode(accountID.value, payload)
      const boleto = await callToFetchBoletoBarcode(accountID.value, payload)
      if (pix.barcode && boleto.barcode) {
        pixBarcode.value = pix.barcode
        boletoBarcode.value = boleto.barcode
      } else {
        throw new Error("unable to fetch pix+boleto barcode")
      }
      return true
    } catch (e) {
      logError(e)
      showGenericError()
      return false
    } finally {
      loading.value = false
    }
  }
  const getPdf = async (paymentMethod: string): Promise<boolean> => {
    loading.value = true

    try {
      let response = null
      const payload: BarcodePayload = {
        cpf: cpf.value.toString(),
        payment_plan_id: paymentPlans.value[0]?.id || "",
        installment_due_date: selectedOffer.value?.first_due_date || ""
      }

      if (paymentMethod === "Pix") {
        response = await callToGetPixPdf(accountID.value, payload)
      } else {
        response = await callToGetBoletoPdf(accountID.value, payload)
      }

      if (!response.pdf) {
        throw new Error(`unable to fetch ${paymentMethod} pdf`)
      }

      if (paymentMethod === "Pix") {
        pixPdf.value = response.pdf
      } else {
        boletoPdf.value = response.pdf
      }

      return true
    } catch (e) {
      const extras = {
        payment_method: paymentMethod,
        payment_plan_id: paymentPlans.value[0]?.id || "",
        installment_due_date: selectedOffer.value?.first_due_date || ""
      }
      logError(e, extras)
      showGenericError()
      return false
    } finally {
      loading.value = false
    }
  }
  const getBoletoPdf = async (): Promise<boolean> => {
    return await getPdf("Boleto")
  }
  const getPixPdf = async (): Promise<boolean> => {
    return await getPdf("Pix")
  }

  return {
    ...mapFields,
    fetchOffers,
    selectOffer,
    showGenericError,
    confirmOffer,
    fetchBarcodeByPaymentMethod,
    getBoletoPdf,
    getPixPdf
  } as PaymentPlansAccessors
}

const paymentPlans: Module<PaymentPlansStore, State> = {
  namespaced: true,
  state: paymentPlansState,
  getters: { ...paymentPlanGetters, getField },
  mutations: { updateField }
}

export default paymentPlans
export { paymentPlansState, usePaymentPlansStore, PaymentPlansStore, PaymentPlansAccessors }
export * from "./getters"
