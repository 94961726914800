<script setup lang="ts">
import { ref, defineProps, defineExpose } from "vue"
import {
  createLowerMyPaymentLoanAgreement,
  EligibleProductDetails,
  Quote
} from "@/api/secure/account-home-service"
import { useAccountStore } from "@/store"
import { logError } from "@/lib/analytics"
import { interestRate, brazilDateFormat } from "@/lib/formatters/helpers"

type LowerMyPaymentQuoteDetailsProps = {
  productDetails: EligibleProductDetails;
  quote: Quote;
}

const props = defineProps<LowerMyPaymentQuoteDetailsProps>()

const { accountID } = useAccountStore()
const optedIn = ref(false)
const topTableData = [
  {
    text: "Valor da parcela",
    value: props.quote.InstallmentAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  }
]
const bottomTableData = [
  {
    text: "Valor pago até agora",
    value: props.productDetails.paid_amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  },
  {
    text: "Saldo devedor do contrato atual",
    value: props.productDetails.loan_balance.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  },
  {
    text: "Quantidade de parcelas",
    value: props.quote.InstallmentsRequested
  },
  {
    text: "Sua parcela vence todo dia",
    value: props.quote.FirstInstallmentDueDate && props.quote.FirstInstallmentDueDate.slice(-2)
  },
  {
    text: "Data da primeira parcela",
    value: brazilDateFormat(props.quote.FirstInstallmentDueDate)
  },
  {
    text: "Data da última parcela",
    value: brazilDateFormat(props.quote.LastInstallmentDueDate)
  },
  {
    text: "IOF",
    value: props.quote.IOF.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  },
  {
    text: "Tarifas",
    value: props.quote.Fee.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
  },
  {
    text: "Taxa de juros",
    value: interestRate(props.quote.InterestRate, "a.m.")
  },
  {
    text: "Taxa de juros anual",
    value: interestRate(props.quote.APR, "a.a.")
  },
  {
    text: "CET",
    value: interestRate(props.quote.CET, "a.a.")
  }
]

async function createLMPAgreement() {
  await createLowerMyPaymentLoanAgreement(accountID.value.toString(), props.quote, optedIn.value, optedIn.value).then(() => {
    window.location.href = "/contract-new?contract_attempted=true"
  }).catch((error) => {
    logError(error)
  })
}

defineExpose({
  createLMPAgreement
})
</script>

<template>
  <div class="details-box">
    <h1 class="header">{{ $t('title') }}</h1>
    <table class="table green-table">
      <tr v-for="data in topTableData" :key="data.text">
        <td class="key">{{ data.text }}</td>
        <td class="value">{{ data.value }}</td>
      </tr>
    </table>
    <table class="table grey-table">
      <tr v-for="data in bottomTableData" :key="data.text">
        <td class="key">{{ data.text }}</td>
        <td class="value">{{ data.value }}</td>
      </tr>
    </table>
    <div class="consent-box">
      <v-checkbox v-model="optedIn" :label="`${$t('consent')}`" />
    </div>
    <div>
      <button
        v-if="optedIn"
        id="enabled"
        type="button"
        @click="createLMPAgreement">
        <span id="button-text">{{ $t('viewContract') }}</span>
        <span id="button-image"><img aria-labelledby="button-text" :alt="$t('arrowIcon')" src="~@/assets/arrow-back-gray.svg" /></span>
      </button>
      <button
        v-else
        id="disabled"
        type="button"
        :disabled="true">
        <span id="button-text">{{ $t('viewContract') }}</span>
        <span id="button-image"><img aria-labelledby="button-text" :alt="$t('arrowIcon')" src="~@/assets/arrow-back-gray.svg" /></span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.details-box {
  width: 98%;
  margin: 0 auto;
}

.header {
  font: normal bold 20px/24px Bree Serif;
  text-align: center;
  padding: 10px;
}

.table {
  margin-left: auto;
  margin-right: auto;
  td {
    padding: 10px;
  }
  .key {
    font: normal normal normal 12px/18px Open Sans;
  }
  .value {
    font: normal normal bold 14px/20px Open Sans;
    text-align: right;
  }
}

.green-table {
  width: 100%;
  background-color: $simplic-green;
  td {
    color: $simplic-white;
  }
}

.grey-table {
  width: 100%;
  background-color: $simplic-background;
  td {
    color: $simplic-dark-text;
    border-bottom: 1px solid $simplic-outline-gray;
  }
}

.consent-box {
  p {
    color: $simplic-dark-text;
    font: normal normal normal 14px/20px Open Sans;
  }
}

button {
  padding: 10px;
  margin: 20px 20px;
  width: 90%;
  border-radius: 8px;

  #button-text {
    font: normal normal bold 16px/24px Open Sans;
    float: left;
    color: $simplic-dark-text;
  }

  #button-image {
    float: right;
    padding-top: 3px;
  }
}

#disabled {
  background-color: $simplic-disabled-button-background;

  #button-text {
    color: $simplic-disabled-button-text;
  }
}
#enabled {
  background-color: $simplic-yellow;
}
</style>

<i18n>
{
  "pt": {
    "title": "Detalhes da Renegociação",
    "consent": "Autorizo o débito automático das parcelas pela Afinz (Sorocred), inclusive após o vencimento e por lançamentos parciais e sobre o meu limite de crédito em conta (cheque especial), se houver, do novo contrato de Renegociação.",
    "viewContract": "Veja o seu novo contrato",
    "arrowIcon": "Ver contrato"
  }
}
</i18n>
