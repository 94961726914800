import jmespath from "jmespath"
import {
  QuestionnaireData,
  PageData,
  QuestionData
} from "@/types/questionnaireTypes"

interface StrippedQuestionnaireData {
  pages: StrippedPageData[];
}

interface StrippedPageData {
  name: string;
  questions: StrippedQuestionData[];
}

interface StrippedQuestionData {
  name: string;
  answer?: string;
}

function prepareQuestion(question: QuestionData): StrippedQuestionData {
  return {
    name: question.name,
    answer: question.answer
  }
}

function preparePage(page: PageData): StrippedPageData {
  return {
    name: page.name,
    questions: page.questions.map(prepareQuestion)
  }
}

function prepareQuestionnaire(questionnaire: QuestionnaireData): StrippedQuestionnaireData {
  return {
    pages: questionnaire.pages.map(preparePage)
  }
}

export const shouldDisplayQuestion = (questionnaire: QuestionnaireData, pageName: string, question: QuestionData): boolean | object => {
  const query = question.display_if
  if(query === undefined) {
    return true
  }

  // don't allow self reference
  const strippedQuestionnaire = prepareQuestionnaire(questionnaire)

  const page = strippedQuestionnaire.pages.find(p => p.name == pageName)

  if(page !== undefined) {
    page.questions = page.questions.filter(q => q.name != question.name)
  }

  return jmespath.search(strippedQuestionnaire, query)
}

export function shouldDisplayPage(questionnaire: QuestionnaireData, page: PageData): boolean {
  const query = page.display_if
  if(query === undefined) {
    return true
  }

  // don't allow self reference
  const strippedQuestionnaire = prepareQuestionnaire(questionnaire)
  strippedQuestionnaire.pages.filter(p => p.name != page.name)

  return jmespath.search(strippedQuestionnaire, query)
}

function pagesBefore(questionnaire: QuestionnaireData, currentPage: number): PageData[] {
  return questionnaire.pages.slice(0, currentPage)
}

function pagesAfter(questionnaire: QuestionnaireData, currentPage: number): PageData[] {
  return questionnaire.pages.slice(currentPage + 1)
}

export function isFirstPage(questionnaire: QuestionnaireData, currentPage: number): boolean {
  const before = pagesBefore(questionnaire, currentPage)
  return before.every(page => !shouldDisplayPage(questionnaire, page))
}

export function isLastPage(questionnaire: QuestionnaireData, currentPage: number): boolean {
  const after = pagesAfter(questionnaire, currentPage)
  return after.every(page => !shouldDisplayPage(questionnaire, page))
}

/**
 * getNextPage finds the next PageData after the given currentPage parameter. If the currentPage
 * is the last page, currentPage will be returned.
 * @param questionnaire The questionnaire being iterated over.
 * @param currentPage The current page
 *
 * @returns The next page index. If the currentPage is the last page, currentPage will be returned.
 */
export function getNextPage(questionnaire: QuestionnaireData, currentPage: number): number {
  const after = pagesAfter(questionnaire, currentPage)
  const adjust = after.findIndex(page => shouldDisplayPage(questionnaire, page))
  return currentPage + 1 + adjust
}

export function getPreviousPage(questionnaire: QuestionnaireData, currentPage: number): number {
  const before = pagesBefore(questionnaire, currentPage)
  const reversed = before.reverse()
  const reversedIndex = reversed.findIndex(page => shouldDisplayPage(questionnaire, page))

  return reversed.length - 1 - reversedIndex
}
