<template>
  <div
    v-if="displayOptionalTasks && displayWhatsApp"
    id="optional-tasks-container">
    <div id="page-container">
      <VCard
        id="account-home-card"
        class="mx-auto pa-4">
        <whats-app-consent-card v-if="displayWhatsApp" :account-i-d="accountID" />
      </VCard>
    </div>
  </div>
</template>

<script lang="ts">
import { getWhatsAppConsentAgreed } from "@/api/secure/whatsapp-consent"
import WhatsAppConsentCard from "@/components/whatsapp/WhatsAppConsentCard.vue"
import { useAccountStore } from "@/store"
import { Ref, defineComponent, ref } from "vue"

export default defineComponent({
  name: "OptionalTasks",
  components: { WhatsAppConsentCard },
  setup() {
    const { accountID } = useAccountStore()
    const displayOptionalTasks: Ref<boolean> = ref(false)
    const displayWhatsApp: Ref<boolean> = ref(false)
    const confirmRegex = new RegExp(".*confirmation$")
    const lpRegex = new RegExp(".*/lp$")

    return {
      displayOptionalTasks,
      displayWhatsApp,
      accountID,
      confirmRegex,
      lpRegex
    }
  },
  watch: {
    async $route (to) {
      if (this.confirmRegex.test(to.path) || this.lpRegex.test(to.path)) {
        const agreed = await getWhatsAppConsentAgreed(this.accountID)
        this.displayWhatsApp = !agreed
        this.displayOptionalTasks = this.displayWhatsApp
      }
    }
  }
})

</script>

<style lang="scss">
	hr { border-color: #f5f5f5; }

  #optional-tasks-container {
    background-color: #f5f5f5;
    padding-top: 30px;
  }
  #account-home-card {
    border: 1px solid grey;
    border-radius: 0;
    min-height: 30vh;
  }
  #page-container {
    max-width: 950px;
    margin: 0 auto;
    box-sizing: border-box;
  }
</style>
