<script setup lang="ts">
import { ref, computed, defineProps, defineEmits, defineExpose } from "vue"
import BankList from "@/views/profile-update/BankList.vue"
import { useAccountDetailsStore } from "@/store"
import { BankAccountFields } from "@/api/secure/account-home-service"
import bankInfo from "@/config/bankInfo"
import AccountTypeList from "@/views/profile-update/AccountTypeList.vue"
import BrazilVTextField from "@/components/input/BrazilVTextField.vue"
import { required } from "@/lib/validators/common"
import { resetFormValidation, isFormValid } from "@/lib/component/helpers"
import type { VForm } from "@/lib/component/helpers"

type BankAccountProps = {
  disabled?: boolean
}

withDefaults(defineProps<BankAccountProps>(), {
  disabled: false
})

const emit = defineEmits<{
  (event: "bankAccountFormInvalid"): void,
  (event: "bankAccountFormValid"): void
}>()

const accountDetails = useAccountDetailsStore()
const bankID = accountDetails.bankAccount.value?.bank_identification_number
const bankBranch = accountDetails.bankAccount.value?.bank_branch
const accountType = accountDetails.bankAccount.value?.account_type
const accountNumber = accountDetails.bankAccount.value?.account_number

const initialBankID = bankID == null ? "" : bankID
const initialBankBranch = bankBranch == null ? "" : bankBranch
const initialAccountType = accountType == null ? "" : accountType
const initialAccountNumber = accountNumber == null ? "" : accountNumber

const bankIDRef = ref<string>(initialBankID)
const bankBranchRef = ref<string>(initialBankBranch)
const accountTypeRef = ref<string>(initialAccountType)
const accountTypeSelection = ref<string>("")
const accountNumberRef = ref<string>("")

const isCaixaSavingsAccount = (accountType: string): boolean => {
  // Caixa savings account types are 013, 1288
  return accountType == "013" || accountType == "1288"
}

const isLongCaixaAccountType = (accountType: string): boolean => {
  return accountType == "1288" || accountType == "3701"
}

if (bankIDRef.value == "104") {
  const firstPart = initialAccountNumber.substring(0, 4)
  accountNumberRef.value = isLongCaixaAccountType(firstPart) ? initialAccountNumber.substring(4) : initialAccountNumber.substring(3)
  accountTypeSelection.value = isLongCaixaAccountType(firstPart) ? initialAccountNumber.substring(0, 4) : initialAccountNumber.substring(0, 3)
} else {
  accountNumberRef.value = initialAccountNumber
}

const initialSeperatedAccountNumber = accountNumberRef.value

const formRef = ref<VForm>()

const bankBranchMaxLength = 4

const clearValues = () => {
  bankBranchRef.value = ""
  accountTypeRef.value = ""
  accountNumberRef.value = ""

  resetFormValidation(formRef)
  emit("bankAccountFormInvalid")
}

const checkFormValidity = () => {
  if (isFormValid(formRef)) {
    emit("bankAccountFormValid")
  } else {
    emit("bankAccountFormInvalid")
  }
}

const isOriginalBank = (newValue: string): boolean => {
  return newValue == initialBankID
}

const bankBranchCounter = (value: string) => {
  return value.length
}

const onBankBranchBlur = (value: string) => {
  bankBranchRef.value = value
  checkFormValidity()
}

const bankBranchLength = (value: string) => {
  return (value.trim().length === 4) || "Número da agência deve ter 4 digitos."
}

const caixaBankBranch = (bankBranch: string): boolean | string => {
  const errorMsg = "Ainda não trabalhamos com essa agência"

  if (!bankBranch || bankBranch == "3880") {
    return errorMsg
  }

  if (["1288", "3701"].includes(bankBranch)){
    return accountTypeSelection.value === bankBranch ? errorMsg : true
  }

  return true
}

const accountNumberMaxLength = computed(() => {
  if (bankIDRef.value == "") return 0

  const bankInfoFormat = bankInfo[bankIDRef.value].format
  // Newer Caixa accounts have longer account numbers
  if (bankIDRef.value == "104" && isLongCaixaAccountType(accountTypeSelection.value)) {
    return bankInfoFormat.accountNumberLengths[1]
  }

  return bankInfoFormat.accountNumberLengths[0]
})

const accountNumberFormat = (value: string) => {
  if (accountNumberMaxLength.value == 0) return ""

  const unformatted = value.replace(/\D/g, "").trim()
  if (unformatted.length > accountNumberMaxLength.value - 1) {
    return unformatted.substring(0, unformatted.length - 1) + "-" + unformatted.substring(unformatted.length - 1)
  }

  return unformatted
}

const accountNumberCounter = (value: string) => {
  let count = value.length

  if (value.includes("-")) {
    count--
  }

  return count
}

const onAccountNumberBlur = (value: string) => {
  const bankInfoFormat = bankInfo[bankIDRef.value].format
  // most banks have 1 account number length
  let expectedLength = bankInfoFormat.accountNumberLengths[0]
  // Newer Caixa accounts have longer account numbers
  if (bankIDRef.value == "104" && isLongCaixaAccountType(accountTypeSelection.value)) {
    expectedLength = bankInfoFormat.accountNumberLengths[1]
  }

  accountNumberRef.value = value.length == 0 ? value : value.padStart(expectedLength, "0")
  checkFormValidity()
}

const loadValues = () => {
  bankBranchRef.value = initialBankBranch
  accountTypeRef.value = initialAccountType
  accountNumberRef.value = initialAccountNumber

  resetFormValidation(formRef)
  emit("bankAccountFormValid")
}

const onBankSelect = (value: string) => {
  bankIDRef.value = value
  isOriginalBank(bankIDRef.value) ? loadValues() : clearValues()
}

const isOriginalAccountType = (newValue: string): boolean => {
  return newValue == initialAccountType
}

const onAccountTypeSelect = (value: string) => {
  accountTypeSelection.value = value
  if (bankIDRef.value == "237") {
    accountTypeRef.value = value == "corrente" ? "checking" : "savings"
  } else if (bankIDRef.value == "104") {
    accountTypeRef.value = isCaixaSavingsAccount(value) ? "savings" : "checking"
  } else {
    accountTypeRef.value = "checking"
  }

  if (isOriginalBank(bankIDRef.value) && isOriginalAccountType(accountTypeRef.value)) {
    accountNumberRef.value = initialAccountNumber
  } else {
    accountNumberRef.value = ""
  }

  checkFormValidity()
}

const bankIDRules = [
  required
]

const bankBranchRules = [
  required,
  bankBranchLength,
  caixaBankBranch
]

const accountTypeRules = [
  required
]

const accountNumberRules = [
  required
]

const collectData = (): BankAccountFields => {
  return {
    "bank_name": collectBankName(),
    "bank_branch": collectBankBranch(),
    "account_number": collectAccountNumber(),
    "account_type": collectAccountType(),
    "caixa_account_type": collectCaixaAccountType()
  } as BankAccountFields
}

const collectBankName = (): string => {
  if (initialBankID == "") return bankIDRef.value
  return initialBankID == bankIDRef.value ? "" : bankIDRef.value
}

const collectBankBranch = (): string => {
  if (initialBankBranch == "") return bankBranchRef.value
  return initialBankBranch == bankBranchRef.value ? "" : bankBranchRef.value
}

const collectAccountNumber = (): string => {
  const unformattedAccountNumber = accountNumberRef.value.replace(/\D/g, "").trim()
  if (initialSeperatedAccountNumber == "") return unformattedAccountNumber
  return initialSeperatedAccountNumber == accountNumberRef.value ? "" : unformattedAccountNumber
}

const collectAccountType = (): string => {
  if (initialAccountType == "") return accountTypeRef.value
  return (initialAccountType == accountTypeRef.value && initialBankID == bankIDRef.value) ? "" : accountTypeRef.value
}

const collectCaixaAccountType = (): string => {
  if (bankIDRef.value == "104" && initialSeperatedAccountNumber != accountNumberRef.value) {
    return accountTypeSelection.value
  }

  return ""
}

defineExpose({
  collectData,
  bankBranchRules,
  onBankSelect,
  bankBranchRef,
  onBankBranchBlur,
  accountTypeRef,
  accountNumberRef,
  onAccountTypeSelect,
  onAccountNumberBlur,
  bankBranchCounter,
  accountNumberCounter,
  accountNumberFormat,
  checkFormValidity
})
</script>

<template>
  <v-container>
    <v-row>
      <v-col class="col-12">
        <slot name="header" />
        <v-container v-if="$vuetify.breakpoint.mobile" :fluid="true" class="ma-0 pa-0">
          <v-form ref="formRef" class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col class="col-12 ma-0 pa-0">
                <v-list-item>
                  <bank-list
                    id="bank-list-select"
                    :value="bankIDRef"
                    :rules="bankIDRules"
                    :disabled="disabled"
                    @select="onBankSelect" />
                </v-list-item>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col v-if="bankIDRef == '104' || bankIDRef == '237'" class="col-12 ma-0 pa-0">
                <v-list-item>
                  <account-type-list
                    id="account-type-select"
                    :bank-type="bankIDRef"
                    :account-type="accountTypeRef"
                    :caixa-account-type="accountTypeSelection"
                    :rules="accountTypeRules"
                    :disabled="disabled"
                    @select="onAccountTypeSelect" />
                </v-list-item>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="col-12 ma-0 pa-0">
                <v-list-item>
                  <brazil-v-text-field
                    id="bank-branch-input"
                    :value="bankBranchRef"
                    :type="'number'"
                    label="Agência"
                    :counter="bankBranchMaxLength"
                    :counter-value="bankBranchCounter"
                    :rules="bankBranchRules"
                    :disabled="disabled"
                    :max-length="bankBranchMaxLength"
                    @blur="onBankBranchBlur" />
                </v-list-item>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="col-12 ma-0 pa-0">
                <v-list-item>
                  <brazil-v-text-field
                    id="account-number-input"
                    :value="accountNumberRef"
                    :type="'number'"
                    label="Conta Corrente (com dígito de verificação)"
                    :format="accountNumberFormat"
                    :rules="accountNumberRules"
                    :disabled="disabled"
                    :counter="accountNumberMaxLength"
                    :counter-value="accountNumberCounter"
                    :max-length="accountNumberMaxLength"
                    @blur="onAccountNumberBlur" />
                </v-list-item>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container v-else>
          <v-form ref="formRef">
            <v-row>
              <v-col class="col-6">
                <v-list-item>
                  <bank-list
                    id="bank-list-select"
                    :value="bankIDRef"
                    :rules="bankIDRules"
                    :disabled="disabled"
                    @select="onBankSelect" />
                </v-list-item>
              </v-col>
              <v-col v-if="bankIDRef == '104' || bankIDRef == '237'" class="col-6">
                <v-list-item>
                  <account-type-list
                    id="account-type-select"
                    :bank-type="bankIDRef"
                    :account-type="accountTypeRef"
                    :caixa-account-type="accountTypeSelection"
                    :rules="accountTypeRules"
                    :disabled="disabled"
                    @select="onAccountTypeSelect" />
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-6">
                <v-list-item>
                  <brazil-v-text-field
                    id="bank-branch-input"
                    :value="bankBranchRef"
                    :type="'number'"
                    label="Agência"
                    :counter="bankBranchMaxLength"
                    :counter-value="bankBranchCounter"
                    :rules="bankBranchRules"
                    :disabled="disabled"
                    :max-length="bankBranchMaxLength"
                    @blur="onBankBranchBlur" />
                </v-list-item>
              </v-col>
              <v-col class="col-6">
                <v-list-item>
                  <brazil-v-text-field
                    id="account-number-input"
                    :value="accountNumberRef"
                    :type="'number'"
                    label="Conta Corrente (com dígito de verificação)"
                    :format="accountNumberFormat"
                    :rules="accountNumberRules"
                    :disabled="disabled"
                    :counter="accountNumberMaxLength"
                    :counter-value="accountNumberCounter"
                    :max-length="accountNumberMaxLength"
                    @blur="onAccountNumberBlur" />
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col />
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">

.sub-header {
  font-family: "Bree Serif", serif;
  font-weight: 500;
  font-size: 24px;
  color: #05a006;
}

.card-outline {
  outline: 1px solid $simplic-outline-gray;
}

</style>
