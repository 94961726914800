<script lang="ts">
import { defaultMeta } from "@/plugins/meta-tags"

export default {
  metaInfo() {
    return defaultMeta
  }
}
</script>
<script setup lang="ts">
import SimplicHeader from "@/components/header/SimplicHeader.vue"
import SimplicFooter from "@/components/footer/SimplicFooter.vue"
import RouteLayoutSelector from "@/components/layouts/RouteLayoutSelector.vue"
import { DebouncedResizeObserver } from "@/lib/component/resize-observer"

const anyWindow = window as any
anyWindow.ResizeObserver = DebouncedResizeObserver
</script>
<template>
  <v-app id="simplic">
    <v-main>
      <simplic-header />
      <route-layout-selector>
        <router-view />
      </route-layout-selector>
    </v-main>
    <simplic-footer />
  </v-app>
</template>

<style lang="scss" scoped>
#simplic {
  background-color: #f5f5f5;
}
</style>
