import moment from "moment"
import { Meta, QuestionData } from "@/types/questionnaireTypes"

/**
 * Rule describes a rule to be followed for various Question types.
 */
export type Rule = (v: any) => true | string;

/**
 * getRequiredRule constructs the requiredRule for a Question
 *
 * @param meta The Metadata of the question
 */
export const getStringRequired = (meta: Meta): Rule => {
  return (value: string): true | string => {
    if (value.length > 0) {
      return true
    } else {
      return meta.required_message || "required"
    }
  }
}

/**
 * getRequired is a wrapper rule factory for a required rule. The rule will convert the value
 * to a string before using {@link getStringRequired} for evaluation.
 *
 * @param meta The metadata of a Question
 */
export const getRequired = (meta: Meta): Rule => {
  const stringRequired = getStringRequired(meta)

  return (value: any): true | string => {
    return stringRequired(String(value))
  }
}

/**
 * getDateTimeMinDaysFromNowRule constructs the minDaysFromNowRule for a DateTimeQuestion
 *
 * @param meta The Metadata of the question
 * @param minDaysFromNow the minimum number of days from now
 */
export const getDateTimeMinDaysFromNowRule = (meta: Meta, minDaysFromNow: number): Rule => {
  return (value: string): true | string => {
    const date = moment(value)
    const today = moment.now()
    const days = date.diff(today, "days")

    if (days < minDaysFromNow) {
      return meta.too_early_message || "too early"
    } else {
      return true
    }
  }
}

/**
 * getDateTimeMaxDaysFromNowRule constructs the maxDaysFromNowRule for a DateTimeQuestion
 *
 * @param meta The Metadata of the question
 * @param maxDaysFromNow The maximum number of days from now
 */
export const getDateTimeMaxDaysFromNowRule = (meta: Meta, maxDaysFromNow: number): Rule => {
  return (value: string): true | string => {
    const date = moment(value)
    const today = moment.now()
    const days = date.diff(today, "days")
    if(days > maxDaysFromNow) {
      return meta.too_late_message || "too late"
    } else {
      return true
    }
  }
}

/**
 * getInputMinLengthRule constructs the minLengthRule for an InputQuestion
 *
 * @param meta the Metadata of the InputQuestion
 * @param inputMin the minimum input
 */
export const getInputMinLengthRule = (meta: Meta, inputMin: number): Rule => {
  return (value: any) => {
    if(typeof(value) == "string" && value.length >= inputMin) {
      return true
    } else {
      return meta.too_short_message || "too short"
    }
  }
}

/**
 * getInputMaxLengthRule constructs the maxLengthRule for an InputQuestion
 *
 * @param meta the Metadata of the InputQuestion
 * @param inputMax The maximum input
 */
export const getInputMaxLengthRule = (meta: Meta, inputMax: number): Rule => {
  return (value: any) => {
    if(typeof(value) == "string" && value.length <= inputMax){
      return true
    } else {
      return meta.too_long_message || "too long"
    }
  }
}

/**
 * getNumberMinRule constructs the numberMinRule for a NumberQuestion
 *
 * @param meta The Metadata of the NumberQuestion
 * @param numberMin The minimum number
 */
export const getNumberMinRule = (meta: Meta, numberMin: number): Rule => {
  return (value: any): true | string => (Number(value) >= numberMin) ? true : meta.min_message || `minimum: ${numberMin}`
}

/**
 * getNumberMaxRule constructs the numberMaxRule for a NumberQuestion
 *
 * @param meta The Metadata of the NumberQuestion
 * @param numberMax The maximum number
 */
export const getNumberMaxRule = (meta: Meta, numberMax: number): Rule => {
  return (value: any): true | string => (Number(value) <= numberMax) ? true : meta.max_message || `maximum: ${numberMax}`
}

/**
 * getTextAreaMinRule constructs the textAreaMinRule for a TextAreaQuestion
 *
 * @param meta The Metadata of the TextAreaQuestion
 * @param textAreaMin The minimum characters in the text area
 */
export const getTextAreaMinRule = (meta: Meta, textAreaMin: number): Rule => {
  return (value: any): true | string => (typeof(value) == "string" && value.length >= textAreaMin) ? true : meta.too_short_message || "too short"
}

/**
 * getTextAreaMaxRule constructs the textAreaMaxRule for a TextAreaQuestion
 *
 * @param meta the MetaData of the TextAreaQuestion
 * @param textAreaMax the maximum characters in the text area
 */
export const getTextAreaMaxRule = (meta: Meta, textAreaMax: number): Rule => {
  return (value: any): true | string => (typeof(value) == "string" && value.length <= textAreaMax) ? true : meta.too_long_message || "too long"
}

/**
 * getAllowedDates constructs the allowedDates method. allowedDates determines if the provided
 * date is allowed for DateTimeQuestions
 * @param question The QuestionData being used.
 */
export const getAllowedDates = (question: QuestionData): (date: string) => boolean => {
  return (date: string) => {
    if (question.type === "datetime") {
      switch (true) {
      case question.validators && question.validators.weekdays_only:
        if ([0, 6].includes(moment(date).weekday())) {
          return false
        }
      }
    }

    return true
  }
}
