<script setup lang="ts">
import { computed, ref, withDefaults, defineProps, defineEmits } from "vue"
import Datepicker from "@/components/Datepicker.vue"
import { InputValidationRules } from "vuetify"
import {
  getAllowedDates,
  validate,
  getLessThanMaxRule,
  getGreaterThanMinRule
} from "@/lib/component/simplic-date-picker"

type SimplicDatePickerProps = {
  value: string;
  minDaysFromNow?: number;
  maxDaysFromNow?: number;
  id?: string;
  disabled?: boolean;
  weekdaysOnly?: boolean;
}

const props = withDefaults(defineProps<SimplicDatePickerProps>(), {
  minDaysFromNow: -1,
  maxDaysFromNow: -1,
  id: "",
  disabled: false,
  weekdays: false
})

const emit = defineEmits<{
  (event: "input", value: string): void
}>()

const model = props.value
const allowedDates = getAllowedDates(props.weekdaysOnly)

const valid = ref(validate(model, props.minDaysFromNow, props.maxDaysFromNow))

const onInput = (newVal: string) => {
  valid.value = validate(newVal, props.minDaysFromNow, props.maxDaysFromNow)
  emit("input", newVal)
}

const datePickerRules = computed(() => {
  const rules: InputValidationRules = []

  if(props.minDaysFromNow !== -1) {
    rules.push(getGreaterThanMinRule(props.minDaysFromNow))
  }

  if(props.maxDaysFromNow !== -1) {
    rules.push(getLessThanMaxRule(props.maxDaysFromNow))
  }

  return rules
})

</script>

<template>
  <div :id="id">
    <datepicker
      id="date-picker"
      v-model="model"
      :placeholder="$t('firstDueDatePlaceholder')"
      locale="pt"
      :disabled="disabled"
      :rules="datePickerRules"
      :allowed-dates="allowedDates"
      @input="onInput" />
  </div>
</template>

<style lang="scss">
  @import '~/src/scss/variables.scss';
</style>
<i18n>
{
  "pt": {
    "firstDueDatePlaceholder": "Por favor selecione"
  }
}
</i18n>
