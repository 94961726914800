<template>
  <v-sheet
    class="offer-sheet outer-offer-sheet"
    height="450px"
    :max-width="maxWidth"
    :width="width"
    color="white"
    :elevation="2"
    rounded
    tile>
    <v-sheet
      class="offer-sheet inner-offer-sheet"
      :elevation="0"
      rounded>
      <v-skeleton-loader type="image" height="8em" />
    </v-sheet>
    <p>
      <v-skeleton-loader type="text" />
    </p>
    <p>
      <v-skeleton-loader type="text" />
    </p>
    <p>
      <v-skeleton-loader type="paragraph" />
    </p>
    <v-skeleton-loader type="image" height="4em" />
  </v-sheet>
</template>
<script>
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    maxWidth: {
      type: String,
      default: "450px"
    },
    width: {
      type: String,
      default: "auto"
    }
  }
})
</script>

<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
    .offer-sheet {
      color: black;
      padding: 1em;
    }
    .outer-offer-sheet {
      display: block;
      margin: 1em 0.25em;
      box-sizing: border-box;
      position: relative;
    }
    .inner-offer-sheet {
      padding: 0;
      text-align: center;
      width: 100%;
      display: block;
      margin: 0 auto 2em auto;
      h3 {
        font-size: 1.75em;
      }
      .installment-headline-container {
        position: relative;
        padding: 1em;
      }
      .installment-details {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: #dff0d8;
        span {
          font-weight: bold;
        }
      }
    }
</style>
