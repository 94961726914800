<script setup lang="ts">
import { ref, Ref, watch } from "vue"
import VueI18n from "@/plugins/i18n"
import { useRoute } from "vue-router/composables"

const showBreadcrumb: Ref<boolean> = ref(false)
const prevPage: Ref<string> = ref("")
const currPage: Ref<string> = ref("")
const linkPath: Ref<string> = ref("")
const route = useRoute()

const getPrevPath = (path: string) => {
  const split = path.replace(/^\//, "").split("/")
  if(split.length == 2 && split[0] == "lp") {
    return "/lp"
  }
  return "/"
}

const getPageTranslation = (path: string) => {
  let name = ""
  switch(path) {
  case "/make-a-payment-plan":
    name = VueI18n.t("makeAPaymentPlanPage").toString()
    break
  case "/payment-plans/offers":
    name = VueI18n.t("offersPage").toString()
    break
  case "/payment-plans/confirm-payment":
    name = VueI18n.t("confirmPaymentPage").toString()
    break
  case "/payment-plans/pix-confirmation":
    name = VueI18n.t("pixConfirmationPage").toString()
    break
  case "/payment-plans/boleto-confirmation":
    name = VueI18n.t("boletoConfirmationPage").toString()
    break
  case "/lp":
    name = VueI18n.t("lpPage").toString()
    break
  case "/lp/ebs":
    name = VueI18n.t("lpEBSPage").toString()
    break
  case "/lp/identity":
    name = VueI18n.t("lpIdentityPage").toString()
    break
  case "/lp/income":
    name = VueI18n.t("lpIncomePage").toString()
    break
  case "/lp/telefone":
    name = VueI18n.t("lpTelefonePage").toString()
    break
  case "/lp/doc":
    name = VueI18n.t("lpDocPage").toString()
    break
  case "/loan-history":
    name = VueI18n.t("loanHistoryPage").toString()
    break
  case "/current-loan":
    name = VueI18n.t("currentLoanPage").toString()
    break
  case "/top-up-refinance":
    name = VueI18n.t("topUpRefiPage").toString()
    break
  case "/lower-my-payment":
    name = VueI18n.t("lowerMyPaymentPage").toString()
    break
  case "/":
    name = VueI18n.t("homePage").toString()
    break
  }
  return name
}

watch(route, (to) => {
  if (to.path != "/" && !to.path.includes("payment-plans")) {
    showBreadcrumb.value = true
    const prevPath = getPrevPath(to.path)
    const prevName = getPageTranslation(prevPath)
    const currentName = getPageTranslation(to.path)
    linkPath.value = prevPath
    prevPage.value = prevName
    currPage.value = currentName
  } else {
    showBreadcrumb.value = false
  }
})

defineExpose({
  showBreadcrumb,
  getPageTranslation,
  getPrevPath,
  prevPage,
  currPage,
  linkPath
})
</script>

<template>
  <div v-if="showBreadcrumb">
    <p class="p-text">
      <router-link class="link" :to="linkPath">
        <strong>{{ prevPage }}
        </strong>
      </router-link>  {{ $t('currentPage', { page: currPage}) }}
    </p>
  </div>
</template>
<style lang="scss" scoped>
  .p-text {
    margin: 0;
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
  }

  .link {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
  }
</style>
<i18n>
{
  "pt": {
    "currentPage": "/  {page}"
  }
}
</i18n>
