import { AnalyticsPlugin } from "analytics"
import { Config, EventCallback } from "@/lib/analytics/analytics-types"
import { buildAnalyticsCallback } from "@/lib/analytics/helpers"
import { useCookieStore } from "@/store"

interface Satellite {
  // eslint-disable-next-line @typescript-eslint/ban-types
  track: Function;
}

declare let window: { s: unknown; analyticsDataLayer: Record<string, unknown>; _satellite: Satellite } & Window

const adobeLaunchURL = process.env.VUE_APP_ADOBE_LAUNCH_URL || ""
const impl = {
  initialize: () => {
    const adobeLaunch = document.createElement("script")
    adobeLaunch.setAttribute("src", adobeLaunchURL)
    document.head.prepend(adobeLaunch)
  },

  identify: buildAnalyticsCallback(() => {
    if(!window.analyticsDataLayer) {
      window.analyticsDataLayer = useCookieStore().readAnalyticDataLayer()
    }
  }),
  track: buildAnalyticsCallback(({ payload }: EventCallback) => {
    // Adobe Launch listens for body events
    document
      .body
      .dispatchEvent(
        new CustomEvent(payload.event, { bubbles: true, composed: true, detail: payload.properties })
      )
  }),
  loaded: () => {
    return !!window.s
  }
}

export default function adobePlugin(config: Config): AnalyticsPlugin & typeof impl {
  if (config.enabled && adobeLaunchURL == "") {
    console.error("adobe not configured")
    config.enabled = false
  }
  return {
    name: "adobe",
    config: { ...config },
    ...impl
  }
}

