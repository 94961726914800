<template>
  <v-sheet
    v-if="currentLoan && paymentData && paymentData.installment_amount != ''"
    id="current-loan-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <div v-if="loaded">
      <p>{{ $t('proposal') }}<br />{{ currentLoan.loan_agreement.loan_application_id }}</p>
      <hr />
      <current-loan-details-card :most-recent-loan="currentLoan" />
    </div>
  </v-sheet>
</template>
<script lang="ts">
import { defineComponent, ref, Ref, onMounted, getCurrentInstance } from "vue"
import CurrentLoanDetailsCard from "@/components/account-home/CurrentLoanDetailsCard.vue"
import { useAccountDetailsStore } from "@/store"
import { Loan } from "@/api/secure/account-home-service"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

export default defineComponent({
  name: "CurrentLoanDetails",
  components: { CurrentLoanDetailsCard },
  setup() {
    const { loans, paymentData } = useAccountDetailsStore()
    const currentLoan: Ref<Loan|null> = ref(null)
    const loaded: Ref<boolean> = ref(false)
    const vueInstance = getCurrentInstance()

    const getCurrentLoan = () => {
      if (loans != null && loans.value) {
        for(let i = 0; i < loans.value.length; i++) {
          if (loans.value[i].loan_status === "current") {
            currentLoan.value = loans.value[i]
            return
          }
        }
      }
    }

    onMounted(() => {
      loaded.value = false
      getCurrentLoan()
      loaded.value = true

      if (currentLoan.value && paymentData && paymentData.value && paymentData.value.installment_amount != "") {
        tagRecordings((vueInstance as unknown) as VueWithHotjar, ["current_loan_view_displayed"])
      }
    })

    return {
      currentLoan,
      paymentData,
      loaded
    }
  }
})
</script>
<style lang="scss" scoped>
#current-loan-container {
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: 1em 0.25em;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;

  p {
    padding-top: 15px;
    font: normal normal bold 20px/28px Open Sans;
  }
}
</style>
<i18n>
{
  "pt": {
    "proposal": "Proposta:"
  }
}
</i18n>
