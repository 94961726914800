import { Route } from "vue-router"
import { VueWithHotjar } from "@/types"
import scrollToTop from "@/lib/scrollToTop"
import analytics, { logError } from "@/lib/analytics"

export default async function afterEach(appInstance: VueWithHotjar, to: Route, from: Route, accountId: string | null, loanAppId: string | null): Promise<void> {
  try {
    scrollToTop()
    const tags = [to.name]
    if (accountId) {
      tags.push(`${accountId}_account_id`)
    }
    if (loanAppId) {
      tags.push(`${loanAppId}_loan_application_id`)
    }
    appInstance?.$hj("tagRecording", tags)
    analytics.page()
    analytics.track("routeChange", { fromPath: from.path, toPath: to.path })
  } catch (e) {
    if (process.env.VUE_APP_ENVIRONMENT != "development") {
      logError(e)
    }
  }
}
