<script setup lang="ts">
import { ref, onMounted, getCurrentInstance, withDefaults, defineProps, defineExpose } from "vue"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"
import { useAccountDetailsStore } from "@/store"

type PastLoanDetailsProps = {
  previousLoan?: object
}

const props = withDefaults(defineProps<PastLoanDetailsProps>(), {
  previousLoan: undefined
})

const { loans } = useAccountDetailsStore()
const loanHistoryPath = ref("/loan-history")
const displayButton = ref(false)
const vueInstance = getCurrentInstance()

const convertDate = (input: string) => {
  const date = new Date(input)
  const dateString = date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
  return dateString.substring(0, 10)
}

const displayButtonLogic = () => {
  if (loans && loans.value) {
    for(let i = 0; i < loans.value.length; i++) {
      if ((loans.value[i].loan_status === "paid_off" || loans.value[i].loan_status === "sold") && (loans.value[i].payments == null || loans.value[i].obligations == null || loans.value[i].payments.length < 1 || loans.value[i].obligations.length < 1)) {
        displayButton.value = false
        return
      }
    }
  }
  displayButton.value = true
}

onMounted(() => {
  displayButtonLogic()
  if (props.previousLoan) {
    tagRecordings((vueInstance as unknown) as VueWithHotjar, ["past_loan_details_card_displayed"])
  }
})

defineExpose({
  convertDate
})
</script>

<template>
  <v-sheet
    v-if="previousLoan"
    id="past-loan-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <p id="past-loans--header">
      {{ $t('previousLoans') }}
    </p>
    <hr />
    <table id="loan-details-table">
      <tr>
        <th>{{ $t('status') }}</th>
        <td>{{ $t('paidOff') }}</td>
      </tr>
      <hr aria-hidden="true" />
      <tr>
        <th>{{ $t('loanDate') }}</th>
        <td>{{ convertDate(previousLoan.created_at) }}</td>
      </tr>
      <hr aria-hidden="true" />
      <tr>
        <th>{{ $t('loanAmount') }}</th>
        <td>{{ parseFloat(previousLoan.amount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }}</td>
      </tr>
      <hr v-if="displayButton" aria-hidden="true" />
    </table>
    <!-- <p id="contact-link-container"><a id="contact-link" href="">{{ $t('seeContract') }}</a></p> -->
    <div v-if="displayButton" id="button-container">
      <router-link
        :to="loanHistoryPath">
        <button
          id="past-loan-button"
          type="button">
          <span id="view-history-text">{{ $t('viewHistory') }}</span>
          <span id="arrow-img-span"><img alt="arrow" src="~@/assets/arrow-back-gray.svg" /></span>
        </button>
      </router-link>
    </div>
  </v-sheet>
</template>

<style lang="scss" scoped>
#past-loan-container {
  display: block;
  /* height: 290px; */
  margin: 1em 0.25em;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;


  #past-loans--header {
    font: normal normal bold 20px/24px Open Sans;
    padding: 20px 0;
    margin: 0;
    color: $simplic-dark-text;
  }

  #loan-details-table {
    background-color: $simplic-background;
    padding: 5px 25px 5px;
    width: 100%;

    hr {
      background-color: $simplic-light-gray;
      border: none;
      height: 1px;
      clear: left;
    }

    th {
      float: left;
      text-align: left;
      margin: 0;
      padding: 10px 0;
      font: normal normal normal 12px/20px Open Sans;
      color: $simplic-dark-text;
    }

    td {
      float: right;
      text-align: right;
      margin: 0;
      padding: 10px 0;
      font: normal normal bold 12px/20px Open Sans;
      color: $simplic-dark-text;
    }
  }

  #contact-link-container {
    padding-bottom: 10px;
    margin: 0;
    background-color: $simplic-background;
  }

  a {
    color: $simplic-blue;
    font: normal normal bold 12px/20px Open Sans;
  }

  #button-container {
    button {
      background-color: $simplic-yellow;
      padding: 10px;
      margin: 20px 20px;
      width: 90%;
      border-radius: 8px;

      #view-history-text {
        font: normal normal bold 16px/24px Open Sans;
        float: left;
        color: $simplic-dark-text;
      }

      #arrow-img-span {
        float: right;
        padding-top: 3px;
      }
    }

    router-link {
      text-decoration: none;
      color: inherit;
    }
  }
}

@media (max-width: 290px) {
  #past-loan-container {
    height: 330px;
  }
}
@media (max-width: 275px) {
  #past-loan-container {
    height: 380px;
  }
}
</style>
<i18n>
{
  "pt": {
    "previousLoans": "Empréstimos anteriores",
    "status": "Status",
    "loanDate": "Data do Empréstimo",
    "loanAmount": "Valor do Empréstimo",
    "paidOff": "Quitado",
    "viewHistory": "Ver Histórico"
  }
}
</i18n>
