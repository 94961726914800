import { AnalyticsPlugin } from "analytics"
import {
  AnalyticsInstance,
  Config,
  ConfigCallback,
  EventCallback,
  Payload
} from "@/lib/analytics/analytics-types"

import { buildAnalyticsCallback, buildErrorHandlers, buildId } from "@/lib/analytics/helpers"
import {
  Client,
  createInstance, enums, EventTags, ListenerPayload,
  setLogger
} from "@optimizely/optimizely-sdk"

let optimizelyInstance: Client | null

let isInitialized = false

type DecisionListenerPayloadExtended = ListenerPayload & {type: string; decisionInfo?: Record<string, unknown>}

function normalizeProperties({ properties }: Payload): Record<string, number|string|null>{
  const filteredProps: ReturnType<typeof normalizeProperties> = {}
  for(const prop in properties) {
    /* istanbul ignore if */
    if(!Object.prototype.hasOwnProperty.call(properties, prop))
      continue
    const val = properties[prop]
    if(typeof val === "string" || typeof val === "number")
      filteredProps[prop] = val
    else if(val instanceof Error && !!val.message){
      filteredProps[prop] = val.message
    }else if(val){
      filteredProps[prop] = JSON.stringify(val)
    }
  }
  return filteredProps
}

const impl = {
  initialize: ({ instance }: ConfigCallback) => {
    optimizelyInstance = createInstance({
      sdkKey: process.env.VUE_APP_OPTIMIZELY_SDK,
      eventBatchSize: 10,
      eventFlushInterval: 1000,
      errorHandler: {
        handleError: buildErrorHandlers(instance).logError
      }
    })

    // Per this doc: https://docs.developers.optimizely.com/full-stack/docs/notification-listeners - decision is invoked
    optimizelyInstance?.notificationCenter.addNotificationListener<DecisionListenerPayloadExtended>(enums.NOTIFICATION_TYPES.DECISION, (payload) => {
      instance.track("OptimizelyFSLayerDecision", {
        experiment_type: payload.type,
        experiment_name: payload.decisionInfo?.experimentKey ?? "not_set",
        experiment_value: payload.decisionInfo?.variationKey ?? "not_set"
      })
    })

    optimizelyInstance?.onReady().then(() => {
      isInitialized = true
    })
    setLogger(null)
  },
  track: buildAnalyticsCallback(({ payload, instance }: EventCallback) => {
    optimizelyInstance?.track(
      payload.event, buildId(instance), instance.user().traits, normalizeProperties(payload)
    )
  }),

  loaded: () => {
    return isInitialized
  },

  methods: {
    // activate used to get variation of ab test with an experiment impression.
    // traits get merged into the user traits.
    activate(this: { instance: AnalyticsInstance }, experiment: string, attributes?: any): string | null | undefined {
      const combined = { ...attributes, ...this.instance.user().traits }
      return optimizelyInstance?.activate(experiment, buildId(this.instance), combined)
    },

    isFeatureEnabled(this: { instance: AnalyticsInstance }, experiment: string): boolean {
      return optimizelyInstance?.isFeatureEnabled(experiment, buildId(this.instance), this.instance.user().traits) || false
    },

    // used to get variation of ab test without triggering an impression
    getVariation(this: { instance: AnalyticsInstance }, variation: string): string|null {
      return optimizelyInstance?.getVariation(variation, buildId(this.instance), this.instance.user().traits) || null
    },

    getFeatureVariableInteger(this: { instance: AnalyticsInstance }, experiment: string, variableKey: string, id: string): number|null {
      return optimizelyInstance?.getFeatureVariableInteger(experiment, variableKey, id) || null
    },

    trackEvent(this: { instance: AnalyticsInstance }, event: string, eventTags?: EventTags): void {
      optimizelyInstance?.track(event.toString(), buildId(this.instance), this.instance.user().traits, eventTags)
    },

    instance(): typeof optimizelyInstance {
      return optimizelyInstance
    }
  }
}

export default function optimizelyPlugin(config: Config): AnalyticsPlugin & typeof impl {

  if (config.enabled && !process.env.VUE_APP_OPTIMIZELY_SDK) {
    console.error("optimizely not configured")
    config.enabled = false
  }

  return {
    name: "optimizely",
    config: { ...config },
    ...impl
  }
}

