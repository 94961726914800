<template>
  <div>
    <navigation-drawer v-model="navDrawerOpen" />
    <v-app-bar app flat>
      <router-link to="/account_home"><img id="logo" height="45px" contain :alt="$t('simplic')" src="~@/assets/header-logo.png" /></router-link>
      <v-spacer />
      <v-app-bar-nav-icon id="hamburger-icon" aria-label="menu de navegação" @click="toggleNagivationDrawer" />
    </v-app-bar>
  </div>
</template>
<script lang="ts">
import NavigationDrawer from "@/components/header/NavigationDrawerAuth.vue"
import { defineComponent } from "vue"
import { useSessionStore } from "@/store"

export default defineComponent({
  name: "MobileHeaderAuth",
  components: { NavigationDrawer },
  setup() {
    const { navDrawerOpen } = useSessionStore()
    const toggleNagivationDrawer = () => {
      navDrawerOpen.value = !navDrawerOpen.value
    }
    return {
      navDrawerOpen,
      toggleNagivationDrawer
    }
  }
})
</script>
<style lang="scss" scoped>
div {
  background-color: $simplic-white;
}
#hamburger-icon .v-icon {
  font-size: 35px;
}
#logo {
  padding-top: 5px;
}
</style>
<i18n>
{
  "pt": {
    "simplic": "Simplic"
  }
}
</i18n>
