<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref, withDefaults, defineProps } from "vue"
import { useAccountDetailsStore } from "@/store"
import lodash from "lodash"
import LowerMyPaymentQuotes from "@/components/account-home/LowerMyPaymentQuotes.vue"

type LowerMyPaymentProps = {
  useV2Layout?: boolean
}

withDefaults(defineProps<LowerMyPaymentProps>(), {
  useV2Layout: false
})

const { eligibleProducts } = useAccountDetailsStore()
const productDetails = eligibleProducts.value?.eligible_offers?.lower_my_payment?.eligible_product_details
const quotes = eligibleProducts.value?.eligible_offers?.lower_my_payment?.quotes
const sortedQuotes = lodash.orderBy(quotes, ["InstallmentsRequested", ["asc"]])

const windowWidth = ref(window.innerWidth)
const onResize = function() {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  nextTick(() => {
    window.addEventListener("resize", onResize)
  })
})

onUnmounted(() => {
  window.removeEventListener("resize", onResize)
})

const isMobile = function() {
  return windowWidth.value < 700
}
</script>

<template>
  <div id="lower-my-payment">
    <h1 id="title">{{ $t("title") }}</h1>
    <hr />
    <div v-if="useV2Layout" id="v2-layout">
      <div id="information-paragraph">
        <p>{{ $t("preinformation") }}</p>
      </div>
      <hr />
      <LowerMyPaymentQuotes :quotes="sortedQuotes" :product-details="productDetails" :show-carousel="isMobile()" />
      <p>{{ $t("information") }}</p>
      <b>{{ $t("postinformation") }}</b>
    </div>
    <div v-else id="v1-layout">
      <div id="information-paragraph">
        <p>{{ $t("preinformation") }}</p>
        <p>{{ $t("information") }}</p>
        <b>{{ $t("postinformation") }}</b>
      </div>
      <hr />
      <lower-my-payment-quotes :quotes="sortedQuotes" :product-details="productDetails" :show-carousel="isMobile()" />
    </div>
  </div>
</template>

<style lang="scss">
#lower-my-payment {
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: 1em 0.25em;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;
}

#title {
  padding: 15px;
}

#information-paragraph {
  background-color: $simplic-background;
  text-align: left;
  padding: 15px;
}
</style>

<i18n>
{
  "pt": {
    "title": "Reduza o valor das suas parcelas",
    "preinformation": "Diminua o valor de suas parcelas estendendo o seu contrato atual.",
    "information": "Ao aceitar esta oferta, você autoriza o débito automático das parcelas deste contrato de Renegociação, mesmo após o vencimento, por meio de lançamentos parciais e sobre o limite de crédito de sua conta (cheque especial), se aplicável.",
    "postinformation": "Ao renegociar o seu contrato, você receberá o valor simbólico de R$1,00 na sua conta para que possamos validar que a transação ocorreu conforme esperado."
  }
}
</i18n>
