<script setup lang="ts">
import { Ref, ref, onMounted, getCurrentInstance, withDefaults, defineProps, defineExpose } from "vue"
import BrazilVSlider from "@/components/account-home/BrazilVSlider.vue"
import { useAccountDetailsStore } from "@/store"
import lodash from "lodash"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"
import VueLadda from "vue-ladda"
import { useRouter } from "vue-router/composables"

type QuoteSliderProps = {
  canApply?: boolean
}

const props = withDefaults(defineProps<QuoteSliderProps>(), {
  canApply: false
})

const { quotes, loanApplications } = useAccountDetailsStore()
const requestedAmounts: Ref<number[]> = ref([])
const selectedAmount: Ref<number> = ref(500) // default
const selectedInstallment: Ref<number> = ref(6) // default
const allInstallments: Ref<number[]> = ref([3, 6, 9, 12])
const monthlyInstallmentAmount: Ref<number> = ref(0) // default
const sliderMin: Ref<number> = ref(0) // default
const sliderMax: Ref<number> = ref(3000) // default
const sliderStep: Ref<number> = ref(250) // default
const applyNowButton: Ref<boolean> = ref(true)
const laddaLoading: Ref<boolean> = ref(false)
const router = useRouter()
const vueInstance = getCurrentInstance()

const allAmounts = () => {
  requestedAmounts.value = lodash.sortedUniq(
    lodash.sortBy(
      lodash.flatMap(
        quotes.value,
        (q) => (q.AmountRequested)
      )
    )
  )
}

const availableInstallments = () => {
  const availableQuotes = lodash.filter(
    quotes.value,
    (q) => q.AmountRequested === selectedAmount.value
  )

  return lodash.sortedUniq(
    lodash.flatMap(
      lodash.sortBy(availableQuotes, ["InstallmentsRequested"]),
      (q) => (q.InstallmentsRequested)
    )
  )
}

const updateSelectedAmount = () => {
  const firstAmount = requestedAmounts.value[0]
  const lastAmount = requestedAmounts.value[requestedAmounts.value.length - 1]
  if (selectedAmount.value < firstAmount) {
    selectedAmount.value = firstAmount
  } else if (selectedAmount.value > lastAmount) {
    selectedAmount.value = lastAmount
  }
}

const updateSelectedInstallment = () => {
  const installments = availableInstallments()
  const firstInstallment = installments[0]
  const lastInstallment = installments[installments.length - 1]
  if (selectedInstallment.value < firstInstallment) {
    selectedInstallment.value = firstInstallment
  } else if (selectedInstallment.value > lastInstallment) {
    selectedInstallment.value = lastInstallment
  }
}

const updateMonthlyInstallment = () => {
  const quote = quotes.value?.find(q => q.AmountRequested === selectedAmount.value && q.InstallmentsRequested === selectedInstallment.value)
  if (quote) {
    monthlyInstallmentAmount.value = quote.InstallmentAmount
  }
}

const min = () => {
  if (requestedAmounts.value) {
    sliderMin.value = requestedAmounts.value[0]
  }
}

const max = () => {
  if (requestedAmounts.value && requestedAmounts.value.length > 1) {
    sliderMax.value = requestedAmounts.value[requestedAmounts.value.length - 1]
  }
}

const step = () => {
  if (requestedAmounts.value && requestedAmounts.value.length > 1) {
    sliderStep.value = requestedAmounts.value[1] - requestedAmounts.value[0]
  }
}

const installmentExists = (installment: number) => {
  return lodash.includes(availableInstallments(), installment)
}

const setSelectedInstallments = (installment: number) => {
  if (!installmentExists(installment)) {
    // get first available installment
    const installments = availableInstallments()
    selectedInstallment.value = installments[0]
    updateMonthlyInstallment()
    return
  }

  selectedInstallment.value = installment
  updateMonthlyInstallment()
}

const amountChanged = (amount: number) => {
  selectedAmount.value = amount
  const installments = availableInstallments()

  if (lodash.includes(installments, selectedInstallment.value)) {
    updateMonthlyInstallment()
    return
  }

  setSelectedInstallments(selectedInstallment.value)
}

const isThreeInstallments = (installment: number) => {
  return installment === 3

}

const isTwelveInstallments = (installment: number) => {
  return installment === 12

}

const determineButtonText = () => {
  if (loanApplications && loanApplications.value && loanApplications.value?.length > 0) {
    const lastLoanAppStatus = loanApplications.value[0].status
    if (lastLoanAppStatus == "applied" || lastLoanAppStatus == "contract_unsigned" || lastLoanAppStatus == "underwriting_approved") {
      applyNowButton.value = false
    }
  }
}

const enableLaddaLoading = () => {
  laddaLoading.value = true
}

const buttonClasses = (): string => {
  return laddaLoading.value ? "button-disabled ladda-button" : "ladda-button"
}

const submitQuoteToFrontend = async () => {
  if (selectedAmount.value.toString().length !==0 && monthlyInstallmentAmount.value.toString().length !== 0) {
    enableLaddaLoading()
    const quote = {
      amount: selectedAmount.value,
      installment: selectedInstallment.value,
      installment_amount: monthlyInstallmentAmount.value
    }

    const encodedQuote = encodeURIComponent(JSON.stringify(quote))
    await router.push({ name: "submit_quote", query: { quote: encodedQuote } })
  }
}

onMounted(() => {
  allAmounts()
  min()
  max()
  step()
  availableInstallments()
  updateSelectedAmount()
  updateSelectedInstallment()
  updateMonthlyInstallment()
  determineButtonText()

  if (props.canApply) {
    tagRecordings((vueInstance as unknown) as VueWithHotjar, ["quote_slider_card_displayed"])
  }
})

defineExpose({
  submitQuoteToFrontend,
  determineButtonText,
  applyNowButton,
  setSelectedInstallments,
  selectedAmount,
  selectedInstallment,
  amountChanged,
  monthlyInstallmentAmount,
  updateSelectedInstallment,
  updateSelectedAmount
})
</script>

<template>
  <v-sheet
    v-if="canApply"
    id="quotes-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <div class="center-align">
      <p>{{ $t('chooseTheLoanAmount') }}</p>
    </div>
    <hr />
    <p class="p-text">{{ $t('howMuchDoYouNeed') }}</p>
    <div id="selected-amount">
      <p class="bold-real">{{ $t('currencyLabel') }} </p>
      <p>{{ selectedAmount }}</p>
    </div>
    <div v-if="requestedAmounts && requestedAmounts.length > 1" class="slider">
      <brazil-v-slider
        :min="sliderMin"
        :max="sliderMax"
        :step="sliderStep"
        :value="selectedAmount"
        @sliderChanged="amountChanged" />
      <span class="slider-info min">{{ $t('currencyDense', { value: sliderMin }) }}</span>
      <span class="slider-info max">{{ $t('currencyDense', { value: sliderMax }) }}</span>
    </div>
    <div id="monthly-installment">
      <p class="p-text">{{ $t('yourMonthlyInstallment') }}</p>
      <p class="p-text bold">{{ $t("currency", { value: monthlyInstallmentAmount }) }}</p>
    </div>
    <div class="installments">
      <span
        v-for="(i, index) in allInstallments"
        :key="index"
        :class="{
          'installment':true,
          'active':(i === selectedInstallment),
          'disabled': !installmentExists(i),
          'outer-left': isThreeInstallments(i),
          'outer-right': isTwelveInstallments(i)
        }"
        @click="setSelectedInstallments(i)">{{ $t("installmentNum", { num: i }) }}</span>
    </div>
    <div class="center-align">
      <vue-ladda
        id="quote-submit-button"
        :loading="laddaLoading"
        :button-class="buttonClasses()"
        data-style="slide-right"
        @click="submitQuoteToFrontend()">
        <div class="opposite-sides">
          <span v-if="applyNowButton" class="button-text-span">{{ $t('applyNow') }}</span>
          <span v-if="!applyNowButton" class="button-text-span">{{ $t('completeContract') }}</span>
          <v-icon small>$arrowBackGrey</v-icon>
        </div>
      </vue-ladda>
    </div>
  </v-sheet>
</template>

<style lang="scss" scoped>
#quotes-container {
  margin: 1em 0.25em;
  outline: 1px solid $simplic-outline-gray;

  .p-text {
    font: normal normal normal 14px/19px Open Sans;
    padding-left: 15px;
    padding-top: 10px;
    margin-bottom: 10px;
    color: $simplic-dark-text;
  }

  .center-align {
    width: 100%;
    text-align: center;
    color: $simplic-dark-text;
    p {
      font: normal normal bold 20px/24px Open Sans;
      padding: 15px 0 0;
    }
  }

  #how-much {
    font: normal normal normal 14px/19px Open Sans;
    padding-left: 15px;
    padding-top: 10px;
  }

  #selected-amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;

    p {
      font: normal normal 25px/35px Open Sans;
      padding-bottom: 0;
      margin: 0;
      color: $simplic-dark-text;
    }

    .bold-real {
      font-weight: bold;
      padding-right: 5px;
      margin: 0;
      color: $simplic-dark-text;
    }
  }

  .slider {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
  }
  .slider-info {
    position: absolute;
    bottom: 4px;
    color: $simplic-dark-text;
    font-size: 14px;
  }
  .slider-info.min {
    left: 10px;
  }
  .slider-info.max {
    right: 10px;
  }

  #monthly-installment {
    display: flex;
    flex-direction: row;
    align-items: center;

    .bold {
      padding-left: 5px;
      font-weight: bold;
    }
  }

  .installments {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 25px;
  }
  .installments .installment {
    padding: 8px;
    width: 23%;
    background-color: white;
    color: $simplic-dark-gray;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    text-transform: none;
    letter-spacing: 0;
    border: 1px solid $simplic-outline-gray;
    margin-top: -1px;
    margin-left: -1px;
  }

  .installments .installment.outer-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .installments .installment.outer-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .installments .installment.active, .installments .installment:hover {
    color: white;
    background: $simplic-green;
  }
  .installments .installment.disabled, .installments .installment.disabled:hover {
    cursor: default;
    opacity: .6;
    background-color: $simplic-background;
    color: rgba(17, 17, 17, 0.5);
  }
  .amount {
    margin-top: 1.5rem;
    text-align: center;
  }
  .amount span {
    text-align: center;
    color: $simplic-green;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 16px 24px;
    border: 1px solid $simplic-yellow;
    border-radius: 14px;
  }

  button {
    background-color: $simplic-yellow;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 90%;
    border-radius: 8px;

    .button-text-span {
      font: normal normal bold 16px/22px Open Sans;
      float: left;
      color: $simplic-dark-text;
      padding-top: 3px;
    }

    #arrow-img-span {
      float: right;
      padding-top: 3px;
    }
  }

  .button-disabled {
    opacity: 0.5;
  }

  .opposite-sides {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
<i18n>
{
  "pt": {
    "currencyLabel": "R$",
    "currencyDense": "R${value}",
    "currency": "R$ {value}",
    "installmentNum": "{num}X",
    "chooseTheLoanAmount": "Escolha o valor desejado",
    "howMuchDoYouNeed": "De quanto você precisa?",
    "yourMonthlyInstallment": "Sua parcela mensal será de",
    "applyNow": "Solicite já!",
    "completeContract": "Continuar solicitação"
  }
}
</i18n>
