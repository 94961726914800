<template>
  <div>
    <p v-if="!showVerification" v-html="$t('cellParagraph')" />
    <i81n path="" />
    <p v-if="showVerification" class="instructions">
      <span v-html="$t('changePhoneInstructions', { phoneNumber: phoneNumber })" />
      <a href="#" @click.prevent="showVerification = false">{{ $t('changePhone') }}</a>
    </p>
    <div v-if="showVerification">
      <CodeVerification @phoneNumberValidated="phoneNumberValidated" />
    </div>
    <div v-if="!showVerification">
      <PhoneNumberForm @sent-code="showVerification = true" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue"
import { useAccountStore } from "@/store"
import PhoneNumberForm from "@/components/loan-processing/PhoneNumberForm.vue"
import CodeVerification from "@/components/loan-processing/CodeVerification.vue"
import { useRouter } from "vue-router/composables"

export default defineComponent({
  name: "ConfirmTelephone",
  components: { PhoneNumberForm, CodeVerification },
  setup() {
    const showVerification: Ref<boolean> = ref(false)
    const { phoneNumber } = useAccountStore()
    const router = useRouter()
    const goToLP = () => {
      router.push({
        name: "LoanProcessing",
        params: { skipActivities: "true" }
      })
    }
    const phoneNumberValidated = async (validated: boolean) => {
      if (validated) {
        goToLP()
      }
    }
    return {
      phoneNumber,
      phoneNumberValidated,
      showVerification
    }
  }
})
</script>

<style scoped>
.instructions a {
  color: #048a05;
  text-decoration: underline;
  font-weight: bold;
}
</style>
<i18n>
{
  "pt": {
    "cellParagraph": "Preencha o campo abaixo e clique no botão \"<strong>Solicitar Código</strong>.\" Você receberá uma mensagem (<strong>SMS</strong>) em seu celular.",
    "changePhoneInstructions": "Informe o código que enviamos para <br /> {phoneNumber} <br />",
    "changePhone": "Alterar número"
  }
}
</i18n>
