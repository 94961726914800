<script setup lang="ts">
import { ref, computed, Ref, defineProps, withDefaults, defineEmits, defineExpose } from "vue"
import type { QuestionnaireData } from "@/types/questionnaireTypes"
import { shouldDisplayQuestion, getNextPage, isLastPage, isFirstPage, getPreviousPage } from "@/lib/date/conditionalDisplay"
import Question from "./Question.vue"

type QuestionnairePropType = {
  value: QuestionnaireData;
  showPrevious?: boolean;
}

const props = withDefaults(defineProps<QuestionnairePropType>(), {
  showPrevious: true
})

const emit = defineEmits<{
  (event: "page-change", title: string): void;
  (event: "previous-on-first-page"): void;
  (event: "previous"): void;
  (event: "submit"): void;
  (event: "next"): void;
}>()

const model = props.value
// required to fix a bug in v-mask
model.pages.forEach((page: any) => {
  page.questions.forEach((question: any) => {
    if (!question.answer) {
      question.answer = ""
    }
  })
})

const meta = model.meta
const pages = model.pages
const questionnaireName = model.name
const currentPageNumber = ref(0)
const pageCount = pages.length
const form: Ref = ref(null)

const currentPage = computed(() => pages[currentPageNumber.value])
const questions = computed(() =>
  currentPage.value.questions.filter((question: any) =>
    shouldDisplayQuestion(model, currentPage.value.name, question)
  )
)

const previous = () => {
  if(isFirstPage(model, currentPageNumber.value)) {
    emit("previous-on-first-page")
  } else {
    currentPageNumber.value = getPreviousPage(model, currentPageNumber.value)
    emit("page-change", currentPage.value.title)
    emit("previous")
  }
}

const submit = () => {
  if(form.value.validate()) {
    if(isLastPage(model, currentPageNumber.value)) {
      emit("submit")
    } else {
      currentPageNumber.value = getNextPage(model, currentPageNumber.value)
      emit("page-change", currentPage.value.title)
      emit("next")
    }
  }
}

defineExpose({
  pageCount,
  currentPageNumber,
  previous,
  submit
})
</script>

<template>
  <v-form :id="`${questionnaireName}--questionnaire`" ref="form" @submit.prevent="submit">
    <h3 v-if="currentPage.title" class="questionnaire--page-title">{{ currentPage.title }}</h3>
    <question
      v-for="question in questions"
      :id="question.name"
      :key="`${currentPage.name}:${question.name}`"
      :meta="meta"
      class="questionnaire--question"
      :value="question" />
    <v-btn
      id="questionnaire--submit"
      class="questionnaire--button"
      type="submit">
      {{ meta.nextLabel }}
    </v-btn>
    <span id="questionnaire--previous" @click="previous" v-html="meta.previousLabel" />
  </v-form>
</template>
