<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script lang="ts">
import AccountHomeLayout from "@/components/layouts/AccountHomeLayout.vue"
import { defineComponent, watch, ref } from "vue"
import { useSessionStore } from "@/store"
import { useRoute } from "vue-router/composables"
export default defineComponent({
  name: "RouteLayoutSelector",
  setup() {
    const layout = ref(AccountHomeLayout)
    const { accountHomeHeadline } = useSessionStore()
    const route = useRoute()

    watch(
      () => route.meta,
      async meta => {
        const metaLayoutComponentName = meta?.layoutComponentName
        /* istanbul ignore next */
        if (metaLayoutComponentName === "AccountHomeLayout") {
          accountHomeHeadline.value = meta?.accountHomeHeadline
        }
        /* istanbul ignore next */
        if (metaLayoutComponentName && metaLayoutComponentName != layout.value.name) {
          const component = await import(`@/components/layouts/${ meta?.layoutComponentName }.vue`)
          layout.value = component?.default
        }
      },
      { immediate: true }
    )
    return { layout }
  }
})
</script>
