<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from "vue"

const emit = defineEmits(["click"])

type BrazilVBtnProps = {
  text?: boolean;
  ripple?: boolean;
  type?: string;
  disabled?: boolean;
  loading?: boolean;
  submit?: boolean;
  depressed?: boolean;
}

withDefaults(defineProps<BrazilVBtnProps>(), {
  text: false,
  ripple: false,
  type: "primary",
  disabled: false,
  loading: false,
  submit: false,
  depressed: false
})

const clicked = (event: Event): void => {
  emit("click", event)
}
</script>

<template>
  <v-btn
    v-if="submit"
    class="v-btn"
    type="submit"
    :text="text"
    :ripple="ripple"
    :disabled="disabled"
    :loading="loading"
    @click="clicked">
    <slot />
  </v-btn>
  <v-btn
    v-else
    class="v-btn"
    :text="text"
    :ripple="ripple"
    :disabled="disabled"
    :loading="loading"
    :depressed="depressed"
    @click="clicked">
    <slot />
  </v-btn>
</template>

<style scoped lang="scss">
.v-btn {
  background-color: $simplic-yellow !important;
  font: normal normal bold 18px OpenSans;
  border-radius: 8px;
  width: 100%;
}
</style>
