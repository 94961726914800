<script setup lang="ts">
import store, { usePaymentPlansStore } from "@/store"
import { defineExpose, nextTick } from "vue"
import { useRouter } from "vue-router/composables"

const router = useRouter()

const paymentMethods = store.getters["paymentPlans/availablePaymentMethods"]
const { confirmed, confirmOffer, fetchBarcodeByPaymentMethod, loading, currentPaymentMethod, selectedOffer } = usePaymentPlansStore()
const routeToConfirmation = () => {
  router.push({
    name: "BoletoAndPixConfirmation"
  })
}
const onConfirm = async () => {
  if (confirmed.value) {
    routeToConfirmation()
    return
  }
  const confirmOfferResult = await confirmOffer()
  const fetchBarcodeByPaymentMethodResult = await fetchBarcodeByPaymentMethod()

  if (!fetchBarcodeByPaymentMethodResult) {
    router.push("/loan-status")
  }

  if (confirmOfferResult) {
    routeToConfirmation()
  }
  // error display happens in store methods
}

const onBack = () => {
  router.go(-1)
}

const paymentMethodImage = (paymentMethod: string) => {
  return require(`@/assets/images/svg/${paymentMethod.toLowerCase()}_btn.svg`)
}

const scrollToConfirm = () => {
  nextTick().then(() => {
    const element = document.getElementById("confirm-payment-option")
    element?.scrollIntoView({ block: "center", behavior: "smooth" })
  })
}

const setCurrentPaymentMethod = (method: string) => {
  currentPaymentMethod.value = method
  selectedOffer.value = store.getters["paymentPlans/matchingOfferByPaymentMethod"]
  scrollToConfirm()
}

defineExpose({
  paymentMethodImage,
  setCurrentPaymentMethod,
  onConfirm
})

</script>

<template>
  <div v-if="selectedOffer">
    <div id="payment-confirmation-headline-container">
      <h2>
        {{ selectedOffer.total_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
      </h2>
      <span v-if="selectedOffer.number_of_installments == 1">{{ $t('upFront') }}</span>
      <span v-else>{{ $t('installmentAmountSubtitle', {offerCount: selectedOffer.number_of_installments}) }}</span>
      <span id="installment-details">{{ $t('installmentDetails', {offerCount: selectedOffer.number_of_installments, installmentAmount: selectedOffer.installment_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}) }}</span>
      <span id="first-due-date">{{ $t("firstDueDate", {date: new Date(selectedOffer.first_due_date).toLocaleDateString('pt-BR') }) }}</span>
    </div>
    <transition name="slide-down" :duration="500">
      <div
        v-if="paymentMethods.length <= 1 || currentPaymentMethod !== ''"
        id="payment-confirmation-button-container"
        :class="{'payment-methods': paymentMethods.length > 1}">
        <h3>
          {{ $t("confirmationMessage") }}
        </h3>
        <hr />
        <v-btn id="confirm-payment-option" :loading="loading" @click="onConfirm">
          {{ $t('confirm') }}
        </v-btn>
        <span id="back" @click="onBack" v-html="$t('goBack')" />
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
  h3 {
    font-family: $font-family-sans-serif;
    color: $simplic-copy;
    font-size: 1.1em;
  }
  #payment-confirmation-headline-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1em auto 1em auto;

    h2 {
      font-family: $font-family-sans-serif;
      letter-spacing: 1px;
      font-size: 2em;
    }
  }
  #installment-details {
    font-weight: bold;
  }
  #payment-confirmation-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 3em 2em;
    background-color: #DFF0D8;
    text-align: center;
  }
  #payment-confirmation-button-container.payment-methods {
    padding: 1em;
    background-color: #ffffff;;
  }
  #payment-method-container {
    padding: 1.5em 1em;
    .payment-method-btn {
      margin: 1em auto 0 auto;
      padding: 1.25em 2em;
      font-size: 1.5em;
      letter-spacing: 0;
      font-weight: bold;
      max-width: 400px;
      text-transform: capitalize;
    }
    .payment-method-btn.active {
      padding: 1.15em 1.9em;
      border: .1em solid $simplic-yellow;
    }
  }
  #back {
    display: block;
    text-align: center;
    margin-bottom: 1em;
    text-decoration: underline;
    color: #185399;
    &:hover {
      cursor: pointer;
    }
  }
  #confirm-payment-option {
    margin: 1em auto 1.5em auto;
    background-color: $simplic-yellow;
    text-transform: capitalize;
    color: $simplic-copy;
    padding: 1.25em 2em;
    font-size: 1.5em;
    letter-spacing: 0;
    width: 100%;
    font-weight: bold;
    max-width: 400px;
  }
  #first-due-date {
    margin-top: 1em;
  }
</style>
<i18n>
{
  "pt": {
    "installmentAmountSubtitle": "em {offerCount} parcelas mensais",
    "upFront": "à vista",
    "goBack": "&#8249; Voltar",
    "confirm": "Confirmo",
    "installmentDetails": "{offerCount} x {installmentAmount}",
    "firstDueDate": "Data de Vencimento: {date}",
    "confirmationMessage": "Confirmar acordo nas condições escolhidas?"
  }
}
</i18n>
