<script setup lang="ts">
import { ref, withDefaults, defineProps } from "vue"

type SimplicCarouselProps = {
  id?: string
  loading?: boolean
}

withDefaults(defineProps<SimplicCarouselProps>(), {
  id: "",
  loading: false
})

const selectedObject = ref(null)
</script>

<template>
  <v-carousel
    :id="id"
    v-model="selectedObject"
    class="simplic-carousel"
    light
    :show-arrows="true"
    hide-delimiter-background
    :touchless="loading"
    v-bind="$attrs"
    height="560px">
    <slot />
  </v-carousel>
</template>
<style lang="scss">
  @import '~/src/scss/variables.scss';
  .simplic-carousel {
    .v-btn--active {
      background-color: #b0f1b0 !important;
    }
    .v-window__next, .v-window__prev {
      top: auto;
      bottom: 7px;
      z-index: 3;
    }
    .v-window__prev {
      left: -15px
    }
    .v-window__next {
      right: -15px
    }
    @media (min-width: 375px) {
      .simplic-carousel {
        .v-window__prev {
          left: 0
        }
        .v-window__next {
          right: 0
        }
      }
    }
  }
</style>
