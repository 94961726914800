<script setup lang="ts">
import { Ref, ref, onMounted, withDefaults, defineProps, defineExpose } from "vue"
import WhatsAppConsentBanner from "@/components/whatsapp/WhatsAppConsentBanner.vue"
import WhatsAppConsentInfo from "@/components/whatsapp/WhatsAppConsentInfo.vue"
import WhatsAppConsentCheckbox from "@/components/whatsapp/WhatsAppConsentCheckbox.vue"
import WhatsAppConsentButton from "@/components/whatsapp/WhatsAppConsentButton.vue"
import { getWhatsAppConsentAgreed } from "@/api/secure/whatsapp-consent"

type WhatsAppConsentCardProps = {
  accountHome?: boolean;
  accountID?: number;
  whatsAppConsentAgreed?: boolean;
}

const props = withDefaults(defineProps<WhatsAppConsentCardProps>(), {
  accountHome: false,
  accountID: 0,
  whatsAppConsentAgreed: false
})

const consentCheckbox: Ref<boolean> = ref(false)
const accountHomeDisplayCard: Ref<boolean> = ref(false)
const whatsAppConsentAgreed: Ref<boolean> = ref(false)

const consentCheckboxClicked = (checkbox: boolean) => {
  consentCheckbox.value = checkbox
}

const whatsAppConsentAccountHomeDisplayLogic = async () => {
  if (props.accountID && props.accountHome) {
    whatsAppConsentAgreed.value = await getWhatsAppConsentAgreed(props.accountID)
    accountHomeDisplayCard.value = !whatsAppConsentAgreed.value
  }
}

onMounted(async () => {
  await whatsAppConsentAccountHomeDisplayLogic()
})

defineExpose({
  consentCheckboxClicked,
  consentCheckbox
})

</script>

<template>
  <v-sheet v-if="accountHomeDisplayCard || !accountHome" id="whatsapp-consent-container" color="white" :elevation="4" rounded tile>
    <div v-if="$vuetify.breakpoint.name === 'xs'">
      <div v-if="accountHome">
        <whats-app-consent-banner />
      </div>
      <v-card>
        <v-container ma-0 pa-4>
          <v-row no-gutters>
            <v-col cols="9">
              <whats-app-consent-info />
            </v-col>
            <v-col cols="3">
              <div>
                <img src="../../assets/images/svg/whatsapp-phone-illustration.svg" alt="whatsapp-phone-illustration" />
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" no-gutters>
              <whats-app-consent-checkbox ref="whats-app-consent-checkbox-account-home" @consentCheckbox="consentCheckboxClicked" />
              <whats-app-consent-button :full-width="true" :account-i-d="accountID" :token="$route.params.token" :checkbox="consentCheckbox" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-else>
      <div v-if="accountHome">
        <whats-app-consent-banner />
      </div>
      <v-card>
        <v-container ma-0 pa-4>
          <v-row no-gutters>
            <v-col cols="10">
              <div>
                <whats-app-consent-info />
                <whats-app-consent-checkbox class="consent-box" @consentCheckbox="consentCheckboxClicked" />
              </div>
              <div>
                <whats-app-consent-button id="whats-app-consent-button" :account-i-d="accountID" :token="$route.params.token" :checkbox="consentCheckbox" />
              </div>
            </v-col>
            <v-col cols="2">
              <img src="../../assets/images/svg/whatsapp-phone-illustration.svg" alt="whatsapp-phone-illustration" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </v-sheet>
</template>

<style scoped lang="scss">
#whatsapp-consent-container {
  display: block;
  margin: 1em 0.25em;
  box-sizing: border-box;
  outline: 1px solid $simplic-outline-gray;
}
.whats-app-phone-icon {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.consent-box {}
</style>
