import { render, staticRenderFns } from "./SerasaQuestionnairePage.vue?vue&type=template&id=64725ee6"
import script from "./SerasaQuestionnairePage.vue?vue&type=script&lang=ts"
export * from "./SerasaQuestionnairePage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.10_loda_nlv2dggb47nbs5pec7lzsfq3vm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SerasaQuestionnairePage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports