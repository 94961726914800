<script setup lang="ts">
import contact from "@/config/contact"
import { useCookieStore, useSessionStore } from "@/store"
import LogoutDialog from "@/components/dialogs/LogoutDialog.vue"
import { useRoute } from "vue-router/composables"

interface MenuSubitem {
  title: string;
  path: string;
  hide?: boolean;
}

interface MenuItem {
  action: string;
  title: string;
  active?: boolean;
  hide?: boolean;
  subitems?: MenuSubitem[];
  path?: string;
  component?: string;
}

const route = useRoute()
const emit = defineEmits<{
  (event: "input", payload: boolean): void;
}>()

const { navDrawerOpen } = useSessionStore()
const { accountHomeMenu } = useCookieStore()
const displayRefiLink = accountHomeMenu.value?.top_up_refi?.display
const onLPPage = route.path.includes("/lp")
const items = [
  {
    action: "mdi-view-dashboard",
    title: "Início",
    path: "/loan-status",
    subitems: []
  },
  {
    action: "mdi-text-box-multiple",
    title: "Meus Empréstimos",
    active: true,
    subitems: [
      {
        title: "Meu Empréstimo Atual",
        path: "/loan-status",
        hide: onLPPage
      },
      {
        title: "Refinanciamento",
        path: "/top-up-refinance",
        hide: !displayRefiLink
      },
      {
        title: "Histórico Empréstimos",
        path: "/loan_applications"
      }
    ]
  },
  {
    action: "mdi-account-question",
    title: "Sobre Simplic",
    subitems: [
      {
        title: "Como Funciona",
        path: "/como-funciona"
      },
      {
        title: "Quem Somos",
        path: "/quem_somos"
      },
      {
        title: "Ajuda",
        path: "/faq"
      },
      {
        title: "Blog",
        path: "/blog"
      }
    ]
  },
  {
    action: "mdi-account-edit",
    title: "Atualizar Dados",
    path: "/atualizar_dados",
    subitems: []
  },
  {
    action: "mdi-lock-reset",
    title: "Redefinir Senha",
    subitems: [],
    path: "/redefinir_senha"
  }
] as MenuItem[]

const itemsToDisplay = [] as MenuItem[]
items.forEach(item => {
  if (!item.hide) {
    const itemCopy = Object.assign({}, item)
    itemCopy.subitems = itemCopy.subitems?.filter(subitem => {
      return !subitem.hide
    })
    itemsToDisplay.push(itemCopy)
  }
})

const updateDrawer = (payload: boolean) => {
  emit("input", payload)
}
</script>

<template>
  <div>
    <v-navigation-drawer id="nav-drawer" app :value="navDrawerOpen" width="85%" @input="updateDrawer">
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-list-item-title id="nav-drawer--logo-container">
            <router-link to="/account_home"><img id="nav-drawer--logo" height="45px" contain :alt="$t('simplic')" src="~@/assets/header-logo.png" /></router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <template v-for="item in itemsToDisplay">
          <template v-if="item.subitems.length > 0">
            <v-list-group
              :key="item.title"
              v-model="item.active"
              color="white"
              no-action>
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title class="nav-drawer--link" v-text="item.title" />
                </v-list-item-content>
              </template>
              <template #prependIcon>
                <v-icon dark>{{ item.action }}</v-icon>
              </template>
              <template v-for="child in item.subitems">
                <v-list-item :key="child.title" :to="child.path">
                  <v-list-item-content>
                    <v-list-item-title class="nav-drawer--sublink">
                      {{ child.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
            <v-divider :key="`list-group-divider-${item.title}`" />
          </template>
          <template v-else>
            <v-list-item
              :key="item.title"
              :to="item.path"
              link>
              <v-list-item-icon>
                <v-icon dark>{{ item.action }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <component :is="item.component" v-if="item.component" class="nav-drawer--link" :is-mobile="true" />
                <v-list-item-title v-else class="nav-drawer--link">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`list-item-divider-${item.title}`" />
          </template>
        </template>
      </v-list>
      <div id="nav-drawer--cta--container">
        <span id="nav-drawer--cta--phone-number"><v-icon dark>mdi-whatsapp</v-icon>{{ contact.whatsApp.display }}</span>
        <LogoutDialog />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
  #nav-drawer {
    background-color: $simplic-green;
    max-width: 375px;

    .nav-drawer--link, .nav-drawer--link a {
      color: $simplic-white;
      font-weight: bold;
      text-decoration: none;
    }
    .nav-drawer--sublink, .nav-drawer--sublink a {
      color: $simplic-white;
      font-size: 0.9em;
      text-decoration: none;
    }
  }
  #nav-drawer--list-group {
    color: $simplic-white;
  }
  #nav-drawer--logo {
    margin: 6px auto 2px auto;
  }
  #nav-drawer--logo-container {
    background-color: $simplic-white;
    text-align: center;
  }
  #nav-drawer--cta--container {
    position: absolute;
    bottom: 3em;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #nav-drawer--cta--button-container {
    text-decoration: none;
  }
  #nav-drawer--cta--button {
    background-color: $simplic-yellow;
    color: $simplic-copy;
    font-weight: bold;
    display: block;
  }
  #nav-drawer--cta--phone-number {
    display: block;
    margin: 1em auto;
    font-weight: bold;
    color: $simplic-white;

    i {
      margin-right: 0.5em;
    }
  }
  @media (min-width: 375px) {
    #nav-drawer {
      .nav-drawer--link, .nav-drawer--link a {
        font-size: 1em;
        letter-spacing: 0.05em;
      }
      .nav-drawer--sublink, .nav-drawer--sublink a {
        font-size: 1em;
        letter-spacing: 0.05em;
      }
    }
  }
</style>
<i18n>
{
  "pt": {
    "simplic": "Simplic"
  }
}
</i18n>
