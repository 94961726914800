/* eslint-disable @typescript-eslint/ban-types */
import { Module } from "vuex"
import store, { State } from "@/store"
import { cookieState } from "@/store/cookie"
import { buildHelpers } from "@/store/composition-helpers"
import { computed, ComputedRef, WritableComputedRef } from "vue"
import { updateField, getField } from "vuex-map-fields"

const { mapGettersAndSettersToComputed } = buildHelpers<SessionStore>(
  () => store, "session"
)

interface SessionStore {
  isLoggedIn: boolean;
  navDrawerOpen: boolean;
  accountHomeHeadline: string | ComputedRef<string>;
}

export const newSessionState = (): SessionStore => {
  return {
    isLoggedIn:  cookieState && cookieState.accessTokensData.access_token != "",
    navDrawerOpen: false,
    accountHomeHeadline: ""
  }
}
const sessionState: SessionStore = newSessionState()

interface SessionStoreAccessors {
  isLoggedIn: WritableComputedRef<boolean>;
  navDrawerOpen: WritableComputedRef<boolean>;
  accountHomeHeadline: WritableComputedRef<string | ComputedRef<string>>;
  accountHomeHeadlineComputed: ComputedRef<string>;
  logIn: Function;
  logOut: Function;
}

function useSessionStore(): SessionStoreAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(sessionState) as Array<keyof SessionStore>)

  const { isLoggedIn } = mapFields

  function logIn() {
    isLoggedIn.value = true
  }

  function logOut() {
    isLoggedIn.value = false
  }

  const accountHomeHeadlineComputed = computed(() => {
    const headline = mapFields.accountHomeHeadline.value
    if(typeof headline === "string") {
      return headline
    }

    return headline.value
  })

  return {
    ...mapFields,
    accountHomeHeadlineComputed,
    logIn,
    logOut
  } as SessionStoreAccessors
}

const session: Module<SessionStore, State> = {
  namespaced: true,
  state: sessionState,
  getters: { getField },
  mutations: { updateField }
}

export default session
export { sessionState, useSessionStore, SessionStore, SessionStoreAccessors }
