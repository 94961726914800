<script setup lang="ts">
import { getCurrentInstance, onMounted, Ref, ref, withDefaults, defineProps } from "vue"
import { useAccountDetailsStore } from "@/store"
import LoanAppStatusDetailsCard from "@/components/account-home/LoanAppStatusDetailsCard.vue"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"

type LoanAppStatusCardProps = {
  loanAppCardView?: string;
  showLoanAppCard: boolean;
}

const props = withDefaults(defineProps<LoanAppStatusCardProps>(), {
  loanAppCardView: ""
})

const { loans, nearestBusinessDay, nextBusinessDay, loanApplications } = useAccountDetailsStore()
const buttonPath: Ref<string> = ref("/lp")
const fundingDate: Ref<string> = ref("")
const declineDate: Ref<string> = ref("")
const loaded: Ref<boolean> = ref(false)
const vueInstance = getCurrentInstance()

const getFundingDate = () => {
  if (loans && loans.value) {
    const loan = loans.value[0]
    const hour = new Date(loan.created_at).getUTCHours() - 3 // brazil
    if (hour < 16 && nearestBusinessDay.value) { // 4 pm brazil time = 7 pm UTC time
      const formatDate = new Date(nearestBusinessDay.value)
      const dateString = formatDate.toLocaleString("pt-BR", { timeZone: "UTC" })
      fundingDate.value = dateString.substring(0, 10)
    } else if (nextBusinessDay.value) {
      const formatDate = new Date(nextBusinessDay.value)
      const dateString = formatDate.toLocaleString("pt-BR", { timeZone: "UTC" })
      fundingDate.value = dateString.substring(0, 10)
    }
  }
}

const getDateShownOnDecline = () => {
  // loan app applied date + 31 days
  if (loanApplications && loanApplications.value) {
    const createdAt = new Date(loanApplications.value[0].created_at)
    const futureDate = new Date(createdAt)
    futureDate.setDate(futureDate.getDate() + 31)
    const dateString = futureDate.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
    declineDate.value = dateString.substring(0, 10)
  }
}

onMounted(() => {
  loaded.value = false
  if (props.loanAppCardView == "funded") {
    getFundingDate()
  }
  if (props.loanAppCardView == "declined") {
    getDateShownOnDecline()
  }
  loaded.value = true

  if (props.showLoanAppCard && loaded.value) {
    tagRecordings((vueInstance as unknown) as VueWithHotjar, ["loan_app_status_card_displayed"])
  }
})
</script>

<template>
  <v-sheet
    v-if="showLoanAppCard && loaded"
    id="loan-app-status-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <div v-if="loanAppCardView == 'declined'" class="text-and-image-outer">
      <div class="text-and-image-inner">
        <span class="img-span"><img alt="red warning icon" src="~@/assets/images/svg/red_warning_icon.svg" /></span>
        <p class="header red">{{ $t('declined') }}</p>
      </div>
    </div>
    <p v-if="loanAppCardView == 'declined'" class="small-text">{{ $t('checkOut') }} <a href="https://www.simplic.com.br/blog/serasa-score-saiba-como-ter-uma-melhor-nota-na-avaliacao-de-credito/">{{ $t('here') }}</a> {{ $t('mainReasons') }}</p>
    <p v-if="loanAppCardView == 'declined'" class="small-text bold">{{ $t('yourChancesAreHigher', { date: declineDate }) }}</p>
    <div v-if="loanAppCardView == 'withdrawn'" class="text-and-image-outer">
      <div class="text-and-image-inner">
        <span class="img-span"><img alt="red warning icon" src="~@/assets/images/svg/red_warning_icon.svg" /></span>
        <p class="header red">{{ $t('applicationWithdrawn') }}</p>
      </div>
    </div>
    <p v-if="loanAppCardView == 'withdrawn'" class="small-text">{{ $t('lastProposalWasCancelled') }}</p>
    <div v-if="loanAppCardView == 'lp'" class="text-and-image-outer">
      <div class="text-and-image-inner">
        <span class="img-span"><img class="img-icon" alt="red warning icon" src="~@/assets/images/svg/red_warning_icon.svg" /></span>
        <p class="header red">{{ $t('trackStatusOfApplication') }}</p>
      </div>
    </div>
    <div v-if="loanAppCardView == 'approved'" class="text-and-image-outer">
      <div class="text-and-image-inner">
        <span class="img-span"><img class="img-icon" alt="green checkmark" src="~@/assets/images/svg/approved.svg" /></span>
        <p class="header green">{{ $t('approved') }}</p>
      </div>
    </div>
    <div v-if="loanAppCardView == 'funded'">
      <p id="expected-funding-text">{{ $t('expectedFundingDate') }}</p>
      <p id="funding-date">{{ fundingDate }}</p>
      <p class="small-text">{{ $t('untilEndOfBankingHours') }}</p>
    </div>
    <loan-app-status-details-card :loan-app-card-view="loanAppCardView" :show-loan-app-card="showLoanAppCard" />
    <div v-if="loanAppCardView == 'lp'" id="button-container">
      <router-link
        :to="buttonPath">
        <button
          type="button">
          <span id="loan-status">{{ $t('viewDetails') }}</span>
          <span id="arrow-img-span"><img alt="arrow" src="~@/assets/arrow-back-gray.svg" /></span>
        </button>
      </router-link>
    </div>
  </v-sheet>
</template>

<style lang="scss" scoped>
#loan-app-status-container {
  display: block;
  margin: 1em 0.25em;
  box-sizing: border-box;
  position: relative;
  outline: 1px solid $simplic-outline-gray;

  p {
    color: $simplic-dark-text;
  }

  .text-and-image-outer {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 12px;

    .text-and-image-inner{
      overflow: hidden;
      width: 75%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      .img-span {
        display:inline-block;
        margin: 0 auto;
      }

      .img-icon {
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;
      }

      .header {
        font: normal normal bold 24px/32px Open Sans;
        margin: 0;
        width: 100%;
        line-height: 1.3;
        text-align: center;

        &.red {
          color: #DB2435;
          background-color: $simplic-white !important;
        }
        &.green {
          color: $simplic-green;
          background-color: $simplic-white !important;
        }
      }
    }
  }

  .bold {
    font-weight: bold !important;
  }

  .small-text{
    font: normal normal normal 14px/20px Open Sans;
    padding-left: 10px;
  }

  #expected-funding-text {
    font: normal normal 18px/24px Open Sans;
    margin: 3px auto;
    padding-left: 10px;
    padding-top: 10px;
  }

  #funding-date {
    font: normal normal bold 20px/26px Open Sans;
    padding-left: 10px;
    margin: 3px auto;
  }

  #button-container {
    width: 100%;
    text-align: left;
    padding: 20px 20px;

    button {
      background-color: $simplic-yellow;
      padding: 10px;
      width: 100%;
      border-radius: 8px;

      #loan-status {
        font: normal normal bold 16px/24px Open Sans;
        float: left;
        color: $simplic-dark-text;
      }

      #arrow-img-span {
        float: right;
        padding-top: 3px;
      }
    }
  }
}
</style>
<i18n>
{
  "pt": {
    "viewDetails": "Ver detalhes",
    "expectedFundingDate": "O depósito do empréstimo está previsto para:",
    "untilEndOfBankingHours": "Até o final do expediente bancário.",
    "declined": "Não aprovado",
    "applicationWithdrawn": "Solicitação cancelada",
    "approved": "Aprovado",
    "trackStatusOfApplication": "Acompanhe o status de sua proposta",
    "yourChancesAreHigher": "Suas chances de aprovação são maiores se você solicitar novo empréstimo a partir do dia {date}.",
    "checkOut": "Confira",
    "here": "aqui",
    "mainReasons": "os principais motivos de não aprovação.",
    "lastProposalWasCancelled": "Sua última proposta foi cancelada, mas você pode fazer uma solicitação a qualquer momento."
  }
}
</i18n>
