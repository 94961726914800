var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.canApply)?_c('v-sheet',{attrs:{"id":"quotes-container","color":"white","elevation":4,"rounded":"","tile":""}},[_c('div',{staticClass:"center-align"},[_c('p',[_vm._v(_vm._s(_vm.$t('chooseTheLoanAmount')))])]),_c('hr'),_c('p',{staticClass:"p-text"},[_vm._v(_vm._s(_vm.$t('howMuchDoYouNeed')))]),_c('div',{attrs:{"id":"selected-amount"}},[_c('p',{staticClass:"bold-real"},[_vm._v(_vm._s(_vm.$t('currencyLabel'))+" ")]),_c('p',[_vm._v(_vm._s(_setup.selectedAmount))])]),(_setup.requestedAmounts && _setup.requestedAmounts.length > 1)?_c('div',{staticClass:"slider"},[_c(_setup.BrazilVSlider,{attrs:{"min":_setup.sliderMin,"max":_setup.sliderMax,"step":_setup.sliderStep,"value":_setup.selectedAmount},on:{"sliderChanged":_setup.amountChanged}}),_c('span',{staticClass:"slider-info min"},[_vm._v(_vm._s(_vm.$t('currencyDense', { value: _setup.sliderMin })))]),_c('span',{staticClass:"slider-info max"},[_vm._v(_vm._s(_vm.$t('currencyDense', { value: _setup.sliderMax })))])],1):_vm._e(),_c('div',{attrs:{"id":"monthly-installment"}},[_c('p',{staticClass:"p-text"},[_vm._v(_vm._s(_vm.$t('yourMonthlyInstallment')))]),_c('p',{staticClass:"p-text bold"},[_vm._v(_vm._s(_vm.$t("currency", { value: _setup.monthlyInstallmentAmount })))])]),_c('div',{staticClass:"installments"},_vm._l((_setup.allInstallments),function(i,index){return _c('span',{key:index,class:{
        'installment':true,
        'active':(i === _setup.selectedInstallment),
        'disabled': !_setup.installmentExists(i),
        'outer-left': _setup.isThreeInstallments(i),
        'outer-right': _setup.isTwelveInstallments(i)
      },on:{"click":function($event){return _setup.setSelectedInstallments(i)}}},[_vm._v(_vm._s(_vm.$t("installmentNum", { num: i })))])}),0),_c('div',{staticClass:"center-align"},[_c(_setup.VueLadda,{attrs:{"id":"quote-submit-button","loading":_setup.laddaLoading,"button-class":_setup.buttonClasses(),"data-style":"slide-right"},on:{"click":function($event){return _setup.submitQuoteToFrontend()}}},[_c('div',{staticClass:"opposite-sides"},[(_setup.applyNowButton)?_c('span',{staticClass:"button-text-span"},[_vm._v(_vm._s(_vm.$t('applyNow')))]):_vm._e(),(!_setup.applyNowButton)?_c('span',{staticClass:"button-text-span"},[_vm._v(_vm._s(_vm.$t('completeContract')))]):_vm._e(),_c('v-icon',{attrs:{"small":""}},[_vm._v("$arrowBackGrey")])],1)])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }