<template>
  <div>
    <input id="email" type="hidden" name="email" :value="emailValue" />
    <input id="phone" type="hidden" name="phone" :value="phoneNumberValue" />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue"
import { useAccountStore } from "@/store"

export default defineComponent({
  name: "TagManagerFooter",
  setup() {
    const { email, phoneNumber } = useAccountStore()
    const emailValue = computed(() => {
      return email.value
    })
    const phoneNumberValue = computed(() => {
      return phoneNumber.value
    })

    const eventName = (process.env.VUE_APP_ENVIRONMENT === "staging" ? "Simplic Web View - Staging" : "Simplic Web View")
    window.dataLayer = window.dataLayer || []
    window.dataLayer?.push({
      event: eventName,
      email: emailValue.value,
      phone: phoneNumberValue.value
    })

    return {
      emailValue,
      phoneNumberValue
    }
  }
})
</script>
