import { useLPStore, useEbsStore, useCookieStore } from "@/store"
import { postEBSEvent } from "@/api/secure"

const { activities } = useLPStore()
const { belvoBankConnection } = useEbsStore()
const { analyticDataLayer, accessTokensData } = useCookieStore()

const successCallbackFunction = (path: string) => {
  activities.value.forEach((activityData: any) => {
    if(activityData.name == "income_verification") {
      activityData.status = "in_review"
    }
  })

  if (path.includes("/lp")) {
    const accountID = accessTokensData.value.account_id
    const loanApplicationID = analyticDataLayer.value.loan_application_id
    if (loanApplicationID) {
      postEBSEvent("belvo_widget", accountID, loanApplicationID, "belvo_widget_success", "")
    }
  }
}

const resetBelvoBankConnection = () => {
  belvoBankConnection.value = null
}

async function createWidget(path: string, bankName: string, accountID: number | undefined, accessToken: string): Promise<void> {

  const allowedInstitution = "of" + bankName + "_br_retail"

  const config = {
    "access_mode": "single",
    "external_id": accountID ? accountID.toString() : "",
    "country_codes": ["BR"],
    "institutions": [allowedInstitution],
    "integration_type": "openfinance",
    "resources": ["TRANSACTIONS"],
    "locale": "pt",
    "show_close_dialog": false,
    "show_abandon_survey": false,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (link: string, institution: string) => successCallbackFunction(path),
    onExit: () => resetBelvoBankConnection()
  }

  /* @ts-expect-error - needed since custom object on window is not known until script is loaded*/
  window.belvoSDK.createWidget(accessToken, config).build()
}

function loadScript(src: string, path: string, bankName: string, accoundID: number | undefined, accessToken: string): void {
  const node = document.createElement("script")
  node.src = src
  node.type = "text/javascript"
  node.async = true
  node.onload = () => createWidget(path, bankName, accoundID, accessToken)
  document.body.appendChild(node)

  if (path.includes("/lp")) {
    const accountID = accessTokensData.value.account_id
    const loanApplicationID = analyticDataLayer.value.loan_application_id
    if (loanApplicationID) {
      postEBSEvent("belvo_widget", accountID, loanApplicationID, "belvo_widget_landed", "")
    }
  }
}

export { loadScript, successCallbackFunction, resetBelvoBankConnection, createWidget }
