<script setup lang="ts">
import { computed, onMounted, ref, Ref, defineProps, defineEmits, defineExpose } from "vue"
import { useLPStore } from "@/store"
import { RetrievedDocument } from "@/api/secure"

type DocumentUploadProps = {
  documentType: string
}

const props = defineProps<DocumentUploadProps>()

const emit = defineEmits<{
  (event: "document-uploaded", file: File): void
}>()

const { documents, addDocumentToActivity } = useLPStore()
const files: Ref<RetrievedDocument[] | null> = ref(null)
const dragActive = ref(false)
const loading = ref(false)

onMounted(() => {
  files.value = documents.value.get(props.documentType) as RetrievedDocument[]
})

const dragover = () => {
  dragActive.value = true
}

const dragleave = () => {
  dragActive.value = false
}

const drop = async (event: DragEvent) => {
  loading.value = true
  if (event != null) {
    const filesToUpload = event.dataTransfer?.files
    if(filesToUpload != undefined && filesToUpload.length > 0) {
      for (const file of filesToUpload) {
        await addDocumentToActivity(file, props.documentType)
        emit("document-uploaded", file)
      }
    }
  }
  dragActive.value = false
  loading.value = false
}

const uploadFile = async (event: Event) => {
  event.preventDefault()
  loading.value = true
  const filesToUpload = (event.target as HTMLInputElement).files
  if(filesToUpload != null && filesToUpload.length > 0) {
    for (const file of filesToUpload) {
      await addDocumentToActivity(file, props.documentType)
      emit("document-uploaded", file)
    }
  }
  loading.value = false
}

const computedFiles = computed(() => documents.value.get(props.documentType) as RetrievedDocument[])

defineExpose({
  dragleave,
  dragover,
  drop,
  uploadFile,
  dragActive
})
</script>

<template>
  <div id="uploader">
    <div v-if="loading" class="loader-container">
      <v-progress-circular indeterminate />
    </div>
    <div v-if="!loading">
      <input
        id="file-input" ref="file" type="file" multiple
        accept=".pdf,.jpg,.jpeg,.png" @change="uploadFile" />

      <label for="file-input" class="block cursor-pointer upload-container" :class="{'drag-over': dragActive}" @dragover.prevent="dragover" @dragleave.prevent="dragleave" @drop.prevent="drop">
        <div>
          <p class="uploadDocText">{{ $t('uploadDocText') }}</p>
        </div>
      </label>
    </div>
    <div class="file-list">
      <v-list-item v-for="(file, i) in computedFiles" :key="i" two-line>
        <v-list-item-content>
          <v-list-item-title class="file-name">{{ file.fileName }}</v-list-item-title>
          <v-list-item-subtitle>{{ file.status }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<style lang="scss">

  #uploader {
    .loader-container, .upload-container {
      height: 100px;
      text-align: center;
      border: 1px dashed #707070;
      padding-top: 45px;
      min-height: 120px;
      width: 100%;
      display: block;
    }

    .upload-container {
      background-image: url("../../../public/field-uploadDocs.png");
      background-position: top 20px center;
    }

    .drag-over {
      background-color: rgba(205, 235, 255, 0.68);
    }

    #file-input {
      display: none;
    }

    .v-list-item__content {
      padding: 0;
    }

    .v-list--two-line .v-list-item, .v-list-item--two-line {
      min-height: 40px;
      padding: 0 16px;
    }

    .file-list {
      padding-top: 20px;
    }

    .file-name {
      font-weight: bold;
      color: #185399;
      padding: 0;
    }

    .uploadDocText {
      padding-top: 32px;
      font-size: 0.9em;
    }
  }
</style>

<i18n>
{
  "pt": {
    "uploadDocText": "Enviar Documento(s)"
  }
}
</i18n>
