<script setup lang="ts">
import { computed, ref } from "vue"
import { useCookieStore, useAccountStore } from "@/store"
import lodash from "lodash"
import LogoutListItem from "@/components/dialogs/LogoutDialog.vue"
import { useRoute } from "vue-router/composables"

const route = useRoute()
const userMenu = ref(false)
const aboutMenu = ref(false)
const loanApplicationsMenu = ref(false)
const { accountHomeMenu } = useCookieStore()
const displayRefiLink = accountHomeMenu.value?.top_up_refi?.display
const onLPPage = route.path.includes("/lp")
const logoLink = ref("/")

const { fullName } = useAccountStore()
const firstName = computed(() => {
  const fName =  fullName.value!.split(" ")[0]
  return lodash.capitalize(fName)
})
</script>

<template>
  <div>
    <v-app-bar id="simplic-header" app height="100px">
      <v-container id="simplic-header--container">
        <router-link :to="logoLink"><img id="simplic-logo" :alt="$t('simplicLogoAlt')" src="~@/assets/header-logo.png" /></router-link>
        <v-spacer />
        <div id="simplic-header--links">
          <router-link to="/account_home" class="nav-link mx-2">{{ $t("dashboard") }}</router-link>
          <div class="ml-2">
            <v-menu
              v-model="loanApplicationsMenu"
              bottom
              right
              open-on-hover
              offset-y>
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on">
                  <span id="simplic-header--my-account">{{ $t("myLoans") }}</span>
                  <v-icon v-if="loanApplicationsMenu">mdi-menu-up</v-icon>
                  <v-icon v-else>mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item v-if="!onLPPage" class="dropdown-link-container" to="/loan-status">
                  <v-list-item-title class="dropdown-link">
                    {{ $t("myLoan") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="displayRefiLink" class="dropdown-link-container" to="/top-up-refinance">
                  <v-list-item-title class="dropdown-link">
                    {{ $t("refinance") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="dropdown-link-container" to="/loan_applications">
                  <v-list-item-title class="dropdown-link" to="/loan_applications">
                    {{ $t("myLoans") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="ml-2">
            <v-menu
              v-model="aboutMenu"
              bottom
              right
              open-on-hover
              offset-y>
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on">
                  <span id="simplic-header--my-account">{{ $t("aboutSimplic") }}</span>
                  <v-icon v-if="aboutMenu">mdi-menu-up</v-icon>
                  <v-icon v-else>mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item class="dropdown-link-container" to="/como-funciona">
                  <v-list-item-title class="dropdown-link">
                    {{ $t("howItWorks") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="dropdown-link-container" to="/quem_somos">
                  <v-list-item-title class="dropdown-link">
                    {{ $t("whoWeAre") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="dropdown-link-container" to="/faq">
                  <v-list-item-title class="dropdown-link">
                    {{ $t("faq") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="dropdown-link-container" to="/blog">
                  <v-list-item-title class="dropdown-link">
                    {{ $t("blog") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-divider vertical />
          <div class="ml-2">
            <v-menu
              v-model="userMenu"
              bottom
              right
              offset-y>
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on">
                  <v-icon size="32px">mdi-account-circle</v-icon>
                  <span v-if="firstName" id="simplic-header--my-account" v-html="$t('helloMessage', {firstName: firstName})" />
                  <v-icon v-if="userMenu">mdi-menu-up</v-icon>
                  <v-icon v-else>mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item class="dropdown-link-container" to="/redefinir_senha">
                  <v-list-item-title class="dropdown-link">
                    {{ $t("resetPassword") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="dropdown-link-container" to="/atualizar_dados">
                  <v-list-item-title class="dropdown-link">
                    {{ $t("updateProfileInfo") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="dropdown-link-container">
                  <LogoutListItem />
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
  #simplic-logo {
    display: block;
  }
  #simplic-header {
    background-color: $simplic-white;
  }
  #simplic-header--container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 900px;
  }
  #simplic-header--links {
    display: flex;
    align-items: baseline;

    .nav-link, #simplic-header--my-account {
      color: $simplic-dark-text;
      text-decoration: none;
      text-transform: capitalize;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .dropdown-link, .dropdown-link-container {
    transition: all .4s ease;
    color: $simplic-dark-text !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      background-color: #f3f3f3;
    }
  }
  #simplic-header--my-account {
    margin-left: 0.5em;
  }
</style>
<i18n>
{
  "pt": {
    "dashboard": "Início",
    "aboutSimplic": "Sobre Simplic",
    "howItWorks": "Como Funciona",
    "whoWeAre": "Quem Somos",
    "faq": "Ajuda",
    "blog": "Blog",
    "simplicLogoAlt": "Simplic Logo",
    "myLoans": "Histórico Empréstimos",
    "myLoan": "Meu Empréstimo Atual",
    "refinance": "Refinanciamento",
    "helloMessage": "Olá, {firstName}",
    "resetPassword": "Redefinir Senha",
    "updateProfileInfo": "Atualizar Dados"
  }
}
</i18n>
