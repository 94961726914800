import { buildHelpers } from "@/store/composition-helpers"
import store, { State, useAccountStore, useCookieStore } from "@/store"
import { WritableComputedRef } from "vue"
import { updateField, getField } from "vuex-map-fields"
import { Module } from "vuex"
import { getExpressIFrame, ExpressIFrameResponse, FetchBelvoBankConnectionResponse } from "@/api/secure"
import { logError } from "@/plugins/sentry"

const { mapGettersAndSettersToComputed } = buildHelpers<EbsStore>(
  () => store, "ebs"
)

interface EbsStore {
  src: string;
  checkingDocumentUUID: string;
  belvoBankConnection: FetchBelvoBankConnectionResponse|null;
}

export const newEbsState = (): EbsStore => {
  return {
    src: "",
    checkingDocumentUUID: "",
    belvoBankConnection: null
  }
}
const ebsState: EbsStore = newEbsState()

interface EbsAccessors {
  src: WritableComputedRef<string>;
  checkingDocumentUUID: WritableComputedRef<string>;
  belvoBankConnection: WritableComputedRef<FetchBelvoBankConnectionResponse|null>;
  getiFrameData: () => Promise<void>;
}

function useEbsStore(): EbsAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(ebsState) as Array<keyof EbsStore>)
  const { src, checkingDocumentUUID } = mapFields
  const { accountID } = useAccountStore()
  const { analyticDataLayer } = useCookieStore()

  const getiFrameData = async () => {
    try {
      if(src.value == "" && analyticDataLayer.value.loanNumber && analyticDataLayer.value.loan_application_id) {
        const response: ExpressIFrameResponse = await getExpressIFrame(accountID.value, analyticDataLayer.value.loanNumber, analyticDataLayer.value.loan_application_id)
        src.value = response.src
        checkingDocumentUUID.value = response.checking_document_uuid
      }
    } catch(e) {
      logError(e)
    }
  }

  return {
    ...mapFields,
    getiFrameData
  } as EbsAccessors
}

const ebs: Module<EbsStore, State> = {
  namespaced: true,
  state: ebsState,
  getters: { getField },
  mutations: { updateField }
}

export default ebs
export { ebsState, useEbsStore, EbsStore, EbsAccessors }
