<template>
  <div id="heading">
    <div v-if="status === 'invalid'" id="error">
      <p v-if="reason && reason !== ''">{{ reason }}</p>
      <p v-else>{{ $t('errorText') }}</p>
      <hr id="error-hr" />
    </div>
    <div id="heading-text">
      <h2>{{ $t('header') }}</h2>
      <p>{{ $t('subHeader') }}</p>
    </div>
    <div id="express-logo">
      <img src="~@/assets/logo-SimplicExpress.png" alt="simplic-express" />
    </div>
    <div id="clearfix" />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue"
import { useLPStore } from "@/store"
export default defineComponent({
  name: "SimplicExpressHeader",
  setup() {
    const { activities } = useLPStore()

    const activity = computed(() => {
      for (const activity of activities.value) {
        if (activity.name != "income_verification") {
          continue
        }

        return activity
      }

      return null
    })

    const status = computed(() => {
      return activity.value?.status
    })

    const reason = computed(() => {
      return activity.value?.reason
    })

    return {
      status,
      reason
    }
  }
})
</script>
<style lang="scss" scoped>
  h2 {
    padding-bottom: 10px;
  }

  #heading {
    text-align: center;
  }

  #heading-text {
    padding-top: 20px;
  }

  @media screen and (max-width: 950px) {
    #heading {
      text-align: center;
    }

    #express-logo {
      padding: 0 10px 5px 10px;
      margin: auto;
      float: none;
      width: 100%;
    }

    #heading-text {
      float: none;
      width: 90%;
      margin: auto;
      padding: 10px;
    }
  }

  #clearfix {
    clear: both;
  }

  #error {
    color: #D60000;
    padding: 20px;
  }

  #error-hr {
    color: #707070;
    opacity: .23;
    height: 2px;
  }

</style>
<i18n>
{
  "pt": {
    "header": "Acelere o seu processo!",
    "subHeader": "Agora você pode fazer a validação da sua renda online. Leva menos de 2 minutos. Rápido e seguro.",
    "errorText": "Os documentos enviados são inválidos. Por favor, envie novamente. Não se esqueça de que as informações devem estar nítidas e não haver cortes na foto. Precisamos também que as informações bancárias apareçam em seu extrato."
  }
}
</i18n>
