<template>
  <div>
    <p class="contact-title accent-text">{{ $t("customerService") }}</p>
    <div class="contact-container">
      <span>{{ $t("whatsApp") }}</span>
      <span class="contact-details">
        <v-icon class="whatsapp-icon" dark medium size="20px">mdi-whatsapp</v-icon>
        <a v-if="whatsappExperiment.value == 'control'" :href="contact.whatsApp.url">{{ contact.whatsApp.display }}</a>
        <a v-if="whatsappExperiment.value == 'freshwork_bot'" :href="contact.whatsAppFreshwork.url">{{ contact.whatsAppFreshwork.display }}</a>
      </span>
    </div>
    <div class="contact-container">
      <span>{{ $t("phoneCity") }}</span>
      <span class="contact-details">
        <a :href="contact.phoneCity.url">{{ contact.phoneCity.display }}</a>
      </span>
    </div>
    <div class="contact-container">
      <span>{{ $t("phoneOther") }}</span>
      <span class="contact-details">
        <a :href="contact.phoneOther.url">{{ contact.phoneOther.display }}</a>
      </span>
    </div>
    <div class="contact-container">
      <span>{{ $t("email") }}</span>
      <span class="contact-details">
        <a :href="contact.email.url">{{ contact.email.display }}</a>
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue"
import Cookies from "js-cookie"
import contact from "@/config/contact"
import analytics from "@/lib/analytics"
const whatsappExperiment = reactive({ value: "control" })
export default defineComponent({
  name: "Contact",
  setup() {
    onMounted(() => {
      const sessionId = Cookies.get("session_id")
      const whatsappExperimentEnabled = analytics.plugins.optimizely.isFeatureEnabled("whatsapp_footer_experiment")
      if (whatsappExperimentEnabled) {
        const experiment = analytics.plugins.optimizely.getVariation("whatsapp_footer", sessionId)
        if (experiment) {
          whatsappExperiment.value = experiment
        }
      }
    })
    return {
      contact,
      whatsappExperiment
    }
  }
})
</script>
<style lang="scss" scoped>
@import '~/src/scss/variables.scss';

.contact-title {
  font-size: 1em;
}

.contact-container {
  margin: 0.5em 0 1em 0;
}

.contact-details a {
  color: $simplic-white;
  margin-left: 0.5em;
  font-weight: bold;
  text-decoration: none;
}

.whatsapp-icon {
  margin-left: 0.5em;
  margin-right: 0.25em;
}
</style>
<i18n>
{
  "pt": {
    "customerService": "Atendimento ao cliente",
    "whatsApp": "WhatsApp:",
    "phoneCity": "Capitais e regiões metropolitanas:",
    "phoneOther": "Demais localidades:",
    "email": "E-mail:"
  }
}
</i18n>
