<script setup lang="ts">
import { defineProps, defineEmits, defineExpose } from "vue"
import { split } from "lodash"
import { getSerasaQuestionnaire, SerasaQuestionnaire, SerasaQuestionnaireUploadRequest, postSerasaQuestionnaire, SerasaScoreRange } from "@/api/secure/lp-service"
import { onMounted, ref } from "vue"
import { useNotificationsStore } from "@/store"
import VueI18n from "@/plugins/i18n"

type SerasaQuestionnaireProps = {
  cpf: number;
  accountID: number;
}

const props = defineProps<SerasaQuestionnaireProps>()

const emit = defineEmits<{
  (event: "submit-score-range", range: SerasaScoreRange): void
}>()


const questionnaire = ref<SerasaQuestionnaire>()
const checkedQuestions = ref<string[]>([])
const score = ref<SerasaScoreRange>()
const { errorMessage } = useNotificationsStore()

const markCheckedQuestionsTrue = () => {
  checkedQuestions.value.forEach(element => {
    const ids = split(element, "_")
    questionnaire.value?.questions.forEach(q => {
      if (ids[1] == q.id) {
        q.answers.forEach(a => {
          if (ids[2] == a.id) {
            a.selected = true
          }
        })
      }
    })
  })
}

const performQSubmit = async (req: SerasaQuestionnaireUploadRequest) => {
  await postSerasaQuestionnaire(req)
    .then((resp) => {
      score.value = resp
      emit("submit-score-range", score.value)
    })
    .catch(() => {
      errorMessage(VueI18n.t("serasaSubmitError").toString())
    })
}

const submitQuestionnaire = async () => {
  markCheckedQuestionsTrue()
  const req: SerasaQuestionnaireUploadRequest = {
    questionnaire: questionnaire.value as SerasaQuestionnaire,
    accountID: props.accountID.toString()
  }
  await performQSubmit(req)
}

onMounted(async () => {
  await getSerasaQuestionnaire(props.cpf.toString())
    .then((resp) => {
      questionnaire.value = resp
    })
    .catch(() => {
      errorMessage(VueI18n.t("serasaSubmitError").toString())
    })
})

defineExpose({
  questionnaire,
  checkedQuestions
})
</script>

<template>
  <v-card class="serasa-questionnaire-container">
    <v-container ma-0 pa-4>
      <v-row no-gutters>
        <v-col v-if="questionnaire" cols="12" no-gutters>
          <div v-for="question in questionnaire.questions" :key="question.id" class="question">
            <span class="question-text">{{ question.description }}</span>
            <div v-for="answer in question.answers" :key="question.id + '_' + answer.id">
              <input
                :id="'answer_' + question.id + '_' + answer.id"
                v-model="checkedQuestions"
                type="checkbox"
                :value="'answer_' + question.id + '_' + answer.id" />
              <span class="answer-text">{{ answer.description }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <div v-if="questionnaire" id="button-container">
        <button @click="submitQuestionnaire()">
          <span class="button-span">
            <span class="button-span-text">{{ $t('submit') }}</span>
          </span>
        </button>
      </div>
    </v-container>
  </v-card>
</template>

<style scoped lang="scss">
.serasa-questionnaire-container {
  display: block;
  margin: 1em 0.25em;
  box-sizing: border-box;
  outline: 1px solid $simplic-outline-gray;
}
.whats-app-phone-icon {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.question {
  padding-top: 10px;
  padding-bottom: 10px;
}

.question-text {
  font-weight: bold;
}

.answer-text {
  font-weight: normal;
  padding-left: 3px;
}

#button-container {
  padding-top: 20px;
  padding-bottom: 20px;

  button {
    background-color: $simplic-yellow;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 8px;
    font-weight: bold;
    color: #333333;

    .button-span {
      font: normal normal bold 16px/24px Open Sans;
      color: $simplic-dark-text;
    }

    .button-span-text {
      height: 24px;
      vertical-align: middle;
    }
  }
}
</style>

<i18n>
  {
    "pt": {
      "submit": "Enviar"
    }
  }
</i18n>
