<script setup lang="ts">
import { defineExpose, defineEmits, defineProps, withDefaults } from "vue"

type BankListProps = {
  value?: string;
  rules?: [];
  disabled?: boolean;
}

withDefaults(defineProps<BankListProps>(), {
  value: "",
  rules: () => [],
  disabled: false
})

const emit = defineEmits<{
  (event: "select", value: string): void
}>()

const bankList = [
  { name: "Por favor selecione", code: "" },
  { name: "Caixa", code: "104" },
  { name: "Itaú", code: "341" },
  { name: "Bradesco", code: "237" },
  { name: "Santander", code: "33" }
]

const onChange = (value: string) => {
  emit("select", value)
}

defineExpose({
  bankList,
  onChange
})
</script>

<template>
  <v-text-field
    v-if="disabled"
    label="Banco onde você possui conta"
    :value="bankList[bankList.findIndex((element) => element.code === value)].name"
    :readonly="true"
    :messages="['só leitura']"
    :persistent-placeholder="true" />
  <v-select
    v-else
    label="Banco onde você possui conta"
    :items="bankList"
    :outlined="!disabled"
    :filled="disabled"
    :rules="rules"
    :readonly="disabled"
    item-text="name"
    item-value="code"
    :value="value"
    @change="onChange" />
</template>

<style scoped lang="scss">
</style>
