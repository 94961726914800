<script setup lang="ts">
import { ref, defineEmits } from "vue"
import { getWhatsAppConsentText } from "@/api/secure/whatsapp-consent"
import VueI18n from "@/plugins/i18n"

const emit = defineEmits<{
  (event: "consentCheckbox", checkboxIsSelected: boolean): void
}>()

const checkboxIsSelected = ref(false)
const iOptIn = ref<string>("")

const getOptInText = async () => {
  try {
    const response = await getWhatsAppConsentText()
    iOptIn.value = response.configuration.consent_text
  } catch (error) {
    iOptIn.value = VueI18n.t("iOptIn").toString()
  }
}

const emitCheckboxClick = () => {
  checkboxIsSelected.value = !checkboxIsSelected.value
  emit("consentCheckbox", checkboxIsSelected.value)
}

getOptInText()

defineExpose({
  getOptInText,
  emitCheckboxClick,
  iOptIn,
  checkboxIsSelected
})
</script>

<template>
  <div>
    <label id="checkbox-text">
      <input id="checkbox" v-model="checkboxIsSelected" type="checkbox" @click="emitCheckboxClick" />
      {{ iOptIn }}
    </label>
  </div>
</template>
