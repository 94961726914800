<template>
  <div>
    <VSnackbar id="snackbar" v-model="showSnackbar" top app multi-line :color="color">
      <div id="snackbar--content">
        <v-icon v-if="icon" class="d-block mr-2" dark>{{ icon }}</v-icon>
        <span id="snackbar--message" :class="`${snackbarType}-snackbar`">{{ snackbarMessage }}</span>
        <VBtn id="snackbar--close" class="px-0" text @click="showSnackbar = false">
          <v-icon :color="closeColor">mdi-close-circle</v-icon>
        </VBtn>
      </div>
    </VSnackbar>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useNotificationsStore } from "@/store"

interface SnackbarTypeConfig {
  [key: string]: string;
}
interface SnackbarConfig {
  [key: string]: SnackbarTypeConfig;
}
const config: SnackbarConfig = {
  warn: {
    closeColor: "#674D00",
    color: "#FCF8E3"
  },
  error: {
    closeColor: "#DB2435",
    color: "#FDF4F5"
  },
  success: {
    closeColor: "#3E753F",
    color: "#DFF0D9"
  }
}

const { snackbarMessage, snackbarType, showSnackbar } = useNotificationsStore()
const icon = computed(() => {
  return config[snackbarType.value]?.icon
})
const color = computed(() => {
  return config[snackbarType.value]?.color || "#777"
})
const closeColor = computed(() => {
  return config[snackbarType.value]?.closeColor || "#777"
})
</script>

<style lang="scss" scoped>
  #snackbar--content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #snackbar--message {
    font-size: 1.1em;
    margin: 0 10px;
    width: calc(100% - 10px);
    &.success-snackbar {
      color: #3E753F;
    }
    &.error-snackbar {
      color: #DB2435;
    }
    &.warn-snackbar {
      color: #674D00;
    }
  }
  #snackbar--close {
    min-width: 0;
  }
</style>
