<template>
  <div>
    <div v-if="questionnaireLoading" id="skeleton-loader-container" class="questionnaire--container">
      <v-skeleton-loader max-width="600px" width="100%" type="text, list-item@5" />
      <v-skeleton-loader type="button" />
    </div>
    <div v-else-if="submitting" id="offers-skeleton-loader">
      <offers-loading-skeleton />
    </div>
    <div v-else-if="questionnaire" class="questionnaire--container">
      <questionnaire
        v-model="questionnaire"
        :show-previous="false"
        @page-change="onPageChange"
        @submit="onSubmit"
        @previous-on-first-page="previousOnFirstPage" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from "vue"
import Questionnaire from "@/components/Questionnaire.vue"
import { useAccountStore, usePaymentPlansStore, useSessionStore, useCookieStore } from "@/store"
import scrollToTop from "@/lib/scrollToTop"
import { getQuestionnaireConfig, postQuestionnaireAnswers  } from "@/api/secure"
import { logError } from "@/plugins/sentry"
import type CreatePaymentPlanQuestionnaire from "@/types/CreatePaymentPlanQuestionnaire"
import type CreatePaymentPlanWithDownPaymentQuestionnaire from "@/types/CreatePaymentPlanWithDownPaymentQuestionnaire"
import analytics from "@/lib/analytics"
import OffersLoadingSkeleton from "@/components/skeleton-loaders/payment-plans/OffersLoadingSkeleton.vue"
import { useRouter } from "vue-router/composables"

const getIsoDueDate = (questionnaire: CreatePaymentPlanQuestionnaire | CreatePaymentPlanWithDownPaymentQuestionnaire): string | undefined => {
  const firstPageAnswer = questionnaire.pages[0].questions[0].answer
  let dueDateAnswer: string | undefined
  switch(firstPageAnswer) {
  case("nok_due_date"):
    dueDateAnswer = questionnaire.pages[1].questions[0].answer
    break
  case("income"):
    dueDateAnswer = questionnaire.pages[2].questions[2].answer
    break
  case("onetime_prob"):
    dueDateAnswer = questionnaire.pages[3].questions[2].answer
    break
  case("direct_debit"):
    dueDateAnswer = questionnaire.pages[4].questions[2].answer
    break
  case("others"):
    dueDateAnswer = questionnaire.pages[5].questions[1].answer
    break
  default:
    dueDateAnswer = undefined
    break
  }

  if(dueDateAnswer === undefined) {
    return undefined
  }

  return `${dueDateAnswer}T00:00:00Z`
}

const getDownPaymentAmount = (questionnaire: CreatePaymentPlanQuestionnaire | CreatePaymentPlanWithDownPaymentQuestionnaire): string => {
  const firstPageAnswer = questionnaire.pages[0].questions[0].answer
  let downPaymentAmountAnswer: string | undefined
  switch(firstPageAnswer) {
  case("nok_due_date"):
    downPaymentAmountAnswer = questionnaire.pages[1].questions[1]?.answer
    break
  case("income"):
    downPaymentAmountAnswer = questionnaire.pages[2].questions[3]?.answer
    break
  case("onetime_prob"):
    downPaymentAmountAnswer = questionnaire.pages[3].questions[3]?.answer
    break
  case("direct_debit"):
    downPaymentAmountAnswer = questionnaire.pages[4].questions[3]?.answer
    break
  case("others"):
    downPaymentAmountAnswer = questionnaire.pages[5].questions[2]?.answer
    break
  }
  if(downPaymentAmountAnswer === undefined) {
    return "000"
  }
  return downPaymentAmountAnswer
}

export default defineComponent({
  name: "PaymentPlanQuestionnaire",
  components: { Questionnaire, OffersLoadingSkeleton },
  setup() {
    const router = useRouter()

    const questionnaire: Ref<CreatePaymentPlanQuestionnaire | CreatePaymentPlanWithDownPaymentQuestionnaire | null> = ref(null)
    const { accountHomeHeadline } = useSessionStore()
    const { firstDueDate, downPaymentAmount, withDownPayment, showGenericError, fetchOffers } = usePaymentPlansStore()
    const { analyticDataLayer } = useCookieStore()
    const submitting = ref(false)
    const questionnaireLoading = ref(false)
    const onPageChange = (title: string) => {
      accountHomeHeadline.value = title
      scrollToTop()
    }

    const minDaysInDefaultForDownPayment = 31
    let maxDaysInDefault = 0
    if (analyticDataLayer.value.maxDaysInDefault !== null) {
      maxDaysInDefault = analyticDataLayer.value.maxDaysInDefault
    }

    const maxDaysFromNow = 10
    const createPaymentPlanDefault = "create-payment-plan"
    const createPaymentPlanVariation = "create-payment-plan-with-down-payment"
    let downPaymentVariation: string = createPaymentPlanDefault
    if (withDownPayment.value) {
      downPaymentVariation = createPaymentPlanVariation
    }
    if (maxDaysInDefault > minDaysInDefaultForDownPayment && !withDownPayment.value) {
      const variation = analytics.plugins.optimizely.activate("customer_preferred_down_payment")
      if (typeof(variation) === "string") {
        downPaymentVariation = variation
      }
    }
    // const downPaymentDefault = "100"

    const onSubmit = async () => {
      submitting.value = true
      const { accountID } = useAccountStore()
      try {
        const postQuestionnaireAnswersPayload = {
          ...questionnaire.value,
          references: [
            {
              type: "identity_account",
              id: accountID.value?.toString()
            },
            {
              type: "loan_number",
              id: analyticDataLayer.value.loanNumber?.toString()
            },
            {
              type: "loan_application_id",
              id: analyticDataLayer.value.loan_application_id?.toString()
            }
          ]
        } as CreatePaymentPlanWithDownPaymentQuestionnaire
        await postQuestionnaireAnswers(postQuestionnaireAnswersPayload)

        const IsoFirstDueDate = getIsoDueDate(postQuestionnaireAnswersPayload)
        if (!IsoFirstDueDate) {
          throw new Error("failed to determine firstDueDate. Cannot fetch offers.")
        }
        firstDueDate.value = IsoFirstDueDate
        downPaymentAmount.value = getDownPaymentAmount(postQuestionnaireAnswersPayload)

        await fetchOffers(firstDueDate.value, downPaymentAmount.value)
        await router.push({
          name: "PaymentPlanOffers",
          params: {
            maxDueDays: maxDaysFromNow.toString()
          }
        })
      } catch(e) {
        logError(e)
        showGenericError()
      } finally {
        submitting.value = false
      }
    }

    const previousOnFirstPage = () => {
      router.push("/loan-status")
    }

    // Add loader or load data before route change
    onMounted(async () => {
      try {
        questionnaireLoading.value = true
        let config
        if (downPaymentVariation === createPaymentPlanVariation) {
          withDownPayment.value = true
          config = await getQuestionnaireConfig("simplic", createPaymentPlanVariation, {}) as CreatePaymentPlanWithDownPaymentQuestionnaire
        } else {
          config = await getQuestionnaireConfig("simplic", createPaymentPlanDefault, {}) as CreatePaymentPlanQuestionnaire
        }

        config.meta.too_late_message = `Intervalo permitido dentro de ${maxDaysFromNow} dias`
        questionnaire.value = config
      } catch(e) {
        showGenericError()
        logError(e)
        return
      } finally {
        questionnaireLoading.value = false
      }

      const today = new Date()
      const date = today.toISOString().substring(0, 10)

      questionnaire.value.pages.forEach((page: any) => {
        page.questions.forEach((question: { type: string; answer?: string }) => {
          if(question.type === "datetime" && question.answer === undefined) {
            question.answer = date
          }
        })
      })
      accountHomeHeadline.value = questionnaire.value?.pages[0]?.title
    })

    return {
      onPageChange,
      previousOnFirstPage,
      onSubmit,
      questionnaire,
      submitting,
      questionnaireLoading
    }
  }
})
</script>
<style lang="scss">
  @import '~/src/scss/variables.scss';

  .questionnaire--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1em 0 1em;
  }
  #create-payment-plan--questionnaire, #create-payment-plan-with-down-payment--questionnaire {
    margin: 1em auto 0 auto;
    width: 100%;
    max-width: 600px;

    .questionnaire--page-title {
      display: none;
    }
    .questionnaire--question {
      color: $simplic-copy;
      margin-bottom: 1.75em;
      legend {
        font-weight: bold;
      }
      label {
        font-weight: bold;
        color: $simplic-copy;
      }
    }
    .questionnaire--question--radio {
      label {
        color: $simplic-copy;
        font-weight: 400;
      }
      .v-radio {
        margin-bottom: 1em;
      }
      input {
        border: red;
      }
      .v-icon {
        color: rgb(24, 83, 153);
      }
    }
    .questionniare--question--datepicker {
      .v-input__slot {
        max-width: 200px;
      }
    }
    .questionniare--question--textarea {
      label {
        display: block;
        margin-bottom: 0.5em;
      }
    }
    textarea {
      margin-top: 0.5em;
      max-height: 12em;
    }
    #questionnaire--submit {
      display: flex;
      margin: 0 auto 2em auto;
      text-transform: capitalize;
      background-color: $simplic-yellow;
      color: $simplic-copy;
      padding: 1.5em 2em !important;
      font-weight: bold;
      font-size: 1.2em;
    }
    #questionnaire--previous {
      display: block;
      text-align: center;
      margin: 0.5em auto;
      color: rgb(24, 83, 153);
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
  #skeleton-loader-container {
    margin-top: 2em;
  }
  #offers-skeleton-loader {
    margin: 2em auto;
    text-align: center;
    .offer-sheet {
      margin: 1em auto;
      text-align: center;
    }
  }
  @media (min-width: 375px) {
    #create-payment-plan--questionnaire, #create-payment-plan-with-down-payment--questionnaire {
      textarea {
        max-height: 4em;
      }
    }
  }
</style>
