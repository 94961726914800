<script setup lang="ts">
import { computed, onMounted, withDefaults, defineProps, defineEmits, defineExpose } from "vue"
import PaymentPlanOffersLastCard from "@/components/payment-plans/OffersLastCard.vue"
import PaymentPlanOffersCard from "@/components/payment-plans/OffersCard.vue"
import OffersLoadingSkeletonDesktop from "@/components/skeleton-loaders/payment-plans/OffersLoadingSkeletonDesktop.vue"
import { PaymentPlanOffer } from "@/api/secure/sysopen-gateway-service"
import store, { useAccountStore } from "@/store"
import { postUniversalMetricsEvent } from "@/api/secure"

type OffersCardProps = {
  id?: string;
  loading?: boolean;
}

withDefaults(defineProps<OffersCardProps>(), {
  id: "",
  loading: false
})

interface PresentableOffer {
  offer: PaymentPlanOffer;
  hovered: boolean;
  selected: boolean;
}

const emit = defineEmits<{
  (event: "offer-selected", presentableOffer: PaymentPlanOffer): void
}>()

const { accountID } = useAccountStore()
const offers = computed(() => store.getters["paymentPlans/offersForDisplay"])
const selectedOffer = computed(() => store.getters["paymentPlans/matchingOfferByPaymentMethod"])
const presentableOffers = computed(() => offers.value.map((el: PaymentPlanOffer) => ({ offer: el, hovered: false, selected: false })))

const anyCardsHovered = computed(() => {
  return presentableOffers.value.some((el: PresentableOffer) => el.hovered)
})

const onSelectOffer = (presentableOffer: PresentableOffer) => {
  emit("offer-selected", presentableOffer.offer)
}

const onHover = (presentableOffer: PresentableOffer, v: boolean) => {
  presentableOffer.hovered = v
}

onMounted(() => {
  postUniversalMetricsEvent(accountID.value, "saw_payment_plan_offers")
})

defineExpose({
  onHover,
  onSelectOffer
})
</script>

<template>
  <div>
    <offers-loading-skeleton-desktop v-if="loading" :show-datepicker="false" />
    <div v-else :id="id" :class="{'any-cards-hovered': anyCardsHovered, 'offer-selected': selectedOffer}">
      <payment-plan-offers-card
        v-for="(presentableOffer) in presentableOffers"
        :key="presentableOffer.offer.payment_plan_offer_id"
        :class="{hovered: presentableOffer.hovered, selected: presentableOffer.offer === selectedOffer}"
        :offer="presentableOffer.offer"
        :single-offer="presentableOffers.length === 1"
        @mouseover="onHover(presentableOffer, true)"
        @mouseleave="onHover(presentableOffer, false)"
        @offer-selected="onSelectOffer(presentableOffer)" />
      <payment-plan-offers-last-card />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
    #payment-plan-offers-desktop {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
    }
    .outer-offer-sheet {
      display: block;
      margin: 1.5em 0.25em;
      box-sizing: border-box;
      position: relative;
      width: 24%;
      transition: all 0.5s ease;
    }
    .offer-selected, .any-cards-hovered {
      .outer-offer-sheet:not(.hovered) {
        opacity: 0.5
      }
      .outer-offer-sheet.hovered, .outer-offer-sheet.selected {
        opacity: 1;
      }
    }

</style>
