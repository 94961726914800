<script setup lang="ts">
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  ref,
  watch,
  withDefaults
} from "vue"

type BrazilVTextFieldProps = {
  value?: string;
  edited?: boolean;
  type?: string;
  rules?: [];
  label?: string;
  format?: (value: string) => string;
  maxLength?: number;
  isMaxLength?: (value: string) => boolean;
  counterValue?: (value: string) => number;
  counter?: number
  prependIconInner?: string
  prependIcon?: string
  prependIconEdit?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<BrazilVTextFieldProps>(), {
  value: "",
  edited: false,
  type: "text",
  rules: () => [],
  label: "",
  format: (value: string) => value,
  maxLength: 0,
  isMaxLength: () => false,
  counterValue: () => 0,
  counter: 0,
  prependIconInner: "",
  prependIcon: "",
  prependIconEdit: "",
  disabled: false
})

const emit = defineEmits<{
  (event: "input", value: string, isEdited: boolean): void
  (event: "blur", value: string, isEdited: boolean): void
}>()

const propFormat = props.format
const initialValue = ref<string>(propFormat(props.value))
const formattedValue = ref<string>(initialValue.value)
const isEdited = ref<boolean>(false)
const textFieldRef = ref<any>()

watch(() => props.value, (newValue) => {
  formattedValue.value = propFormat(newValue)
  isEdited.value = initialValue.value != formattedValue.value
})

watch(() => props.edited, (newValue) => {
  isEdited.value = newValue
  if (!newValue) initialValue.value = propFormat(formattedValue.value)
})

const getInputString = (event: KeyboardEvent): string => {
  const target = event.target as HTMLInputElement
  const currentValue = target.value
  const selectionStart = target.selectionStart as number | undefined
  const selectionEnd = target.selectionEnd as number | undefined

  return currentValue.slice(0, selectionStart) +
    event.key +
    currentValue.slice(selectionEnd)
}

const setCursorPosition = async (start: number, end: number): Promise<void> => {
  const input = textFieldRef.value?.$el.querySelector("input")
  await nextTick()

  input?.setSelectionRange(start, end)
}

const onKeyDown = (event: KeyboardEvent): void => {
  if (!event.ctrlKey && !event.metaKey) {
    if (props.type === "number" && (event.key.length === 1 && event.key.match(/[a-zA-Z!@#$%^&*()_+{}[\]:;"'<>,.?/\\|~]/))) event.preventDefault()
  }
}

const onInput = (event: KeyboardEvent) => {
  const value: string = props.type === "number" ? onNumberInput(event) : onTextInput(event)

  formattedValue.value = propFormat(value)
  isEdited.value = initialValue.value !== formattedValue.value

  emit("input", formattedValue.value, isEdited.value)
}

const onTextInput = (event: KeyboardEvent): string => {
  const target = event.target as HTMLInputElement

  let value = target.value
  if ((event.key.length === 1 && event.key.match(/[0-9a-zA-Z!@#$%^&*()_+{}[\]:;"'<>,.?/\\|~]/))) {
    value = getInputString(event)

    const selectionStart = target.selectionStart
    const newCursorPosition = selectionStart! + 1
    setCursorPosition(newCursorPosition, newCursorPosition)
  }

  return value
}

const onNumberInput = (event: KeyboardEvent): string => {
  const target = event.target as HTMLInputElement

  return target.value
}

const prependIconValue = computed(() => {
  if (props.prependIconEdit !== "") {
    return isEdited.value ? props.prependIconEdit : props.prependIcon
  }

  return props.prependIcon
})

const onBlur = (event: Event) => {
  event.preventDefault()

  const target = event.target as HTMLInputElement

  let value = target.value
  if (props.type === "number") {
    value = target.value.trim().trim().replace(/[a-zA-Z!@#$%^&*()_+{}[\]:;,."'<>?/\\|~]/g, "")
  }

  isEdited.value = initialValue.value !== value

  emit("blur", value, isEdited.value)
}

const onPaste = async (event: ClipboardEvent) => {
  event.preventDefault()

  const clipboardData = event.clipboardData?.getData("text")
  const value = props.type === "number" ? onPasteNumber(clipboardData) : onPasteText(clipboardData)

  formattedValue.value = propFormat(value || "")
  isEdited.value = initialValue.value !== formattedValue.value

  emit("input", formattedValue.value, isEdited.value)
}

const onPasteNumber = (value: string | undefined) => {
  const pastedData = value?.trim().replace(/[a-zA-Z!@#$%^&*()_+{}[\]:;,."'<>?/\\|~]/g, "")

  return props.isMaxLength(pastedData || "") ? pastedData?.slice(0, props.counter) : pastedData
}

const onPasteText = (value: string | undefined) => {
  return props.isMaxLength(value || "") ? value?.slice(0, props.maxLength) : value
}

defineExpose({
  onKeyDown,
  onInput,
  onBlur,
  onPaste,
  formattedValue,
  prependIconValue
})

</script>

<template>
  <v-text-field
    ref="textFieldRef"
    :prepend-icon="prependIconValue"
    :prepend-inner-icon="prependIconInner"
    :value="formattedValue"
    :counter="counter"
    :counter-value="counterValue"
    :label="label"
    :rules="rules"
    :maxlength="maxLength"
    :outlined="!disabled"
    :readonly="disabled"
    :validate-on-blur="true"
    :persistent-placeholder="true"
    :messages="disabled ? ['só leitura'] : []"
    @keydown="onKeyDown"
    @blur="onBlur"
    @keyup="onInput"
    @paste="onPaste" />
</template>

<style scoped lang="scss" >

</style>
