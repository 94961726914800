<template>
  <div id="offers-loading-skeleton-mobile">
    <template v-if="showDatepicker">
      <v-skeleton-loader max-width="300px" width="100%" type="text" />
      <v-skeleton-loader max-width="300px" width="30%" type="text" />
      <v-skeleton-loader max-width="300px" width="100%" type="button" />
    </template>
    <div id="offers-loading-skeleton-carousel-container">
      <simplic-carousel>
        <v-carousel-item
          v-for="i in numCards"
          :key="i">
          <offers-card-loading-skeleton width="100%" max-width="300px" />
        </v-carousel-item>
      </simplic-carousel>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue"
import SimplicCarousel from "@/components/SimplicCarousel.vue"
import OffersCardLoadingSkeleton from "@/components/skeleton-loaders/payment-plans/OffersCardLoadingSkeleton.vue"

export default defineComponent({
  components: { SimplicCarousel, OffersCardLoadingSkeleton },
  props: {
    numCards: {
      type: Number,
      default: 3
    },
    showDatepicker: {
      type: Boolean,
      default: true
    }
  }
})
</script>
<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
  #offers-loading-skeleton-mobile {
    margin: 2em auto;
  }
  #offers-loading-skeleton-carousel-container {
    display: flex;
    margin: 3em auto;
    justify-content: center;
    align-items: center;
    .outer-offer-sheet {
      display: block;
      margin: 0 auto;
      box-sizing: border-box;
      position: relative;
      width: 24%;
      transition: all 0.5s ease;
    }
    .offer-selected, .any-cards-hovered {
      .outer-offer-sheet:not(.hovered) {
        opacity: 0.5
      }
      .outer-offer-sheet.hovered, .outer-offer-sheet.selected {
        opacity: 1;
      }
    }
  }
</style>
