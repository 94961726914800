<template>
  <v-sheet
    v-if="hasEbs()"
    id="open-finance-container"
    color="white"
    :elevation="4"
    rounded
    tile>
    <p id="open-finance--header">
      {{ $t('openFinance') }}
    </p>
    <hr />
    <div class="inner-container">
      <div class="content-and-image">
        <div class="text-and-button">
          <form>
            <p>{{ $t('manageYourData') }}</p>
            <v-radio-group
              v-if="hasMultipleEbsProviders()"
              id="open-finance-radio-group"
              v-model="open_finance_selection" row>
              <v-radio id="belvo-radio" :label="$t('belvo')" value="Belvo" />
              <v-radio id="klavi-radio" :label="$t('klavi')" value="Klavi" />
            </v-radio-group>
            <button
              id="send-button"
              type="button"
              @click="goToEbsProvider()">
              <span class="button-text">{{ $t('send') }}</span>
            </button>
          </form>
        </div>
        <img alt="Ícone Open Finance" src="~@/assets/images/svg/open-finance-logo.svg" />
      </div>
    </div>
    <BelvoWidget
      v-if="belvoBankConnection && belvoBankConnection.belvo_access_tokens"
      :account-id="accountID.toString()"
      :bank-name="bankName"
      :access-token="belvoBankConnection.belvo_access_tokens.access" />
  </v-sheet>
</template>
<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted, Ref, ref } from "vue"
import { useAccountDetailsStore, useCookieStore, useAccountStore, useEbsStore } from "@/store"
import { useNotificationsStore } from "@/store/notifications"
import tagRecordings from "@/plugins/hotjar/tagRecordings"
import { VueWithHotjar } from "@/types"
import { getBelvoBankConnection, FetchBelvoBankConnectionParams } from "@/api/secure"
import BelvoWidget from "@/components/loan-processing/BelvoWidget.vue"
import { logError } from "@/lib/analytics"

export default defineComponent({
  name: "OpenFinanceCard",
  components: { BelvoWidget },
  setup() {
    const { ebsMetadata } = useAccountDetailsStore()
    const { analyticDataLayer } = useCookieStore()
    const { accountID, fullName, cpf } = useAccountStore()
    const { belvoBankConnection } = useEbsStore()
    const vueInstance = getCurrentInstance()
    const genericError = "Ocorreu um erro enquanto processávamos o seu pedido. Por favor, tente novamente."
    const bankName = analyticDataLayer.value.bank_display_name?.toLowerCase() || ""
    const open_finance_selection: Ref<string> = ref("")
    const klaviUrl = "https://portal-open.klavi.ai/login"
    const belvoProvider = "Belvo"
    const klaviProvider = "Klavi"

    const params = {
      accountID: accountID.value,
      name: encodeURIComponent(fullName.value),
      cpf: cpf.value,
      bank: bankName
    } as FetchBelvoBankConnectionParams
    async function fetchBankConnection(params: FetchBelvoBankConnectionParams) {
      await getBelvoBankConnection(params)
        .then((data) => {
          belvoBankConnection.value = data
        })
        .catch((error) => {
          const { errorMessage } = useNotificationsStore()
          errorMessage(genericError)
          logError(error)
        })
    }

    const hasBelvoEbs = (() => {
      return (ebsMetadata.value && ebsMetadata.value.has_belvo_ebs)
    })
    const hasKlaviEbs = (() => {
      return (ebsMetadata.value && ebsMetadata.value.has_klavi_ebs)
    })
    const hasEbs = (() => {
      return (hasBelvoEbs() || hasKlaviEbs())
    })
    const hasMultipleEbsProviders = (() => {
      return (hasBelvoEbs() && hasKlaviEbs())
    })
    const openBelvoWidget = (async () => {
      await fetchBankConnection(params)
    })
    const openKlaviPortal = (() => {
      window.location.href = klaviUrl
    })
    const goToEbsProvider = (() => {
      if (open_finance_selection.value == belvoProvider) {
        openBelvoWidget()
      } else if (open_finance_selection.value == klaviProvider) {
        openKlaviPortal()
      }
    })

    onMounted(() => {
      if(hasEbs()) {
        tagRecordings((vueInstance as unknown) as VueWithHotjar, ["open_finance_card_displayed"])
        if (hasBelvoEbs()) {
          open_finance_selection.value = belvoProvider
        } else if (hasKlaviEbs()) {
          open_finance_selection.value = klaviProvider
        }
      }

    })

    return {
      ebsMetadata,
      belvoBankConnection,
      bankName,
      accountID,
      fetchBankConnection,
      open_finance_selection,
      hasEbs,
      hasMultipleEbsProviders,
      goToEbsProvider
    }
  }
})
</script>
<style lang="scss" scoped>
#open-finance-container {
  display: block;
  box-sizing: border-box;
  position: relative;
  margin: 1em 0.25em;
  text-align: center;
  outline: 1px solid $simplic-outline-gray;

  #open-finance--header {
    font: normal normal bold 20px/24px Open Sans;
    padding: 20px 0;
    margin: 0 auto;
    color: $simplic-dark-text;
  }

  .inner-container {
    background-color: $simplic-background;

    .content-and-image {
      display: flex;

      img {
        width: 170px;
        float: right;
      }

      .text-and-button {
        text-align: left;
        float: left;
        color: $simplic-dark-text;
        padding-left: 10px;
        padding-top: 20px;
      }
    }

    button {
      background-color: $simplic-yellow;
      padding: 10px;
      margin-bottom: 15px;
      width: 90%;
      border-radius: 8px;

      .button-text {
        font: normal normal bold 16px/24px Open Sans;
        color: $simplic-dark-text;
      }
    }
  }
}
</style>
<i18n>
{
  "pt": {
    "openFinance": "Open Finance",
    "manageYourData": "Faça a gestão dos seus dados compartilhados via open finance com a Simplic",
    "send": "Enviar",
    "belvo": "Belvo",
    "klavi": "Klavi"
  }
}
</i18n>
