<script setup lang="ts">
import { defineExpose, onMounted } from "vue"
import { useAccountStore, useCookieStore, usePaymentPlansStore } from "@/store"
import { postUniversalMetricsEvent } from "@/api/secure"
import ConfirmationHeadline from "./ConfirmationHeadline.vue"
import analytics from "@/lib/analytics"
import BoletoAndPixConfirmation from "@/views/payment-plans/BoletoAndPixConfirmation.vue"
import FileSaver from "file-saver"

const { selectedOffer } = usePaymentPlansStore()
const { accountID } = useAccountStore()
const { analyticDataLayer, updateAnalyticDataLayer } = useCookieStore()

const onCopy = (text: string) => {
  navigator.clipboard.writeText(text)
}
const base64ToBlob = (data: string) => {
  // decode base64 string, remove space for IE compatibility
  const binary = window.atob(data.replace(/\s/g, ""))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < len; i += 1) {
    // this line is run ~100,000 times, so counting line coverage is really slow
    /* istanbul ignore next */
    view[i] = binary.charCodeAt(i)
  }
  return new Blob([view], { type: "application/pdf" })
}
const downloadPDF = (base64: string) => {
  const blob = base64ToBlob(base64)

  FileSaver.saveAs(blob, `${selectedOffer.value?.payment_method}.pdf`)
}
const onPdf = (pdf: string) => {
  downloadPDF(pdf)
}

onMounted(() => {
  postUniversalMetricsEvent(accountID.value, "create_payment_plan")
  analytics.track("payment_plan_created")
  analyticDataLayer.value.inDefaultNoPmtPlan = false
  updateAnalyticDataLayer()
})

defineExpose({
  onPdf,
  onCopy
})

</script>

<template>
  <div id="main-container">
    <confirmation-headline />
    <hr />
    <div id="body-container">
      <boleto-and-pix-confirmation @copy-clicked="onCopy" @pdf-clicked="onPdf" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
  #main-container {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }
  #body-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1em auto;
  }
</style>
