import { client } from "@/api/secure-service"
export interface PaymentPlanOffersPayload {
  cpf: string;
  first_due_date: string;
  down_payment_amount: string;
  max_days_in_default: number;
}

export interface PaymentPlanOffer {
  payment_plan_offer_id: string;
  payment_plan_code: string;
  number_of_installments: number;
  total_amount: number;
  first_due_date: string;
  down_payment_amount: number;
  installment_amount: number;
  discount: number;
  principal_discount: number;
  payment_method: string;
}

export interface ContractInstallment {
  installment_number: string;
}

export interface Installment {
  installment_number: string;
  due_date: string;
  status: string;
  installment_amount: number;
}
export interface PaymentPlan {
  id: string;
  operation_number: string;
  contract_installments: ContractInstallment[];
  installments: Installment[];
}
export interface ConfirmOfferResponse {
  payment_plans: PaymentPlan[];
}

export interface BarcodePayload {
  cpf: string;
  payment_plan_id: string;
  installment_due_date: string;
}

export interface BoletoBarcodeResponse {
  barcode: string;
}
export interface PixBarcodeResponse {
  barcode: string;
}
export interface BoletoPdfResponse {
  pdf: string;
}

export interface PixPdfResponse {
  pdf: string;
}

export interface PaymentPlanOffersResponse {
  payment_plan_offers: PaymentPlanOffer[];
  max_days_in_default: number;
}

export interface ConfirmOfferPayload {
  payment_plan_offer_id: string;
  cpf: string;
  payment_plan_code: string;
}

export interface UnpaidInstallmentsPayload {
  cpf: string;
}

export interface UnpaidInstallment {
  installment_number: string;
  due_date: string;
  status: string;
  installment_amount: number;
  outstanding_amount: number;
}

export interface UnpaidInstallmentsResponse {
  unpaid_installments: UnpaidInstallment[];
}

export async function fetchUnpaidInstallments(accountID: number, payload: UnpaidInstallmentsPayload): Promise<UnpaidInstallmentsResponse> {
  const response = await client().post(`/payment-plans/unpaid-installments/${accountID}`, payload)
  return response as UnpaidInstallmentsResponse
}

export async function generatePaymentPlanOffersV2(accountID: number, payload: PaymentPlanOffersPayload): Promise<PaymentPlanOffersResponse> {
  const response = await client().post(`/payment-plans/${accountID}/offers-general`, payload)
  return response as PaymentPlanOffersResponse
}

export async function callToConfirmOffer(accountID: number, payload: ConfirmOfferPayload): Promise<ConfirmOfferResponse> {
  const response = await client().post(`/payment-plans/${accountID}/create`, payload)
  return response as ConfirmOfferResponse
}

export async function callToFetchBoletoBarcode(accountID: number, payload: BarcodePayload): Promise<BoletoBarcodeResponse> {
  const response = await client().post(`/payment-plans/${accountID}/boleto`, payload)
  return response as BoletoBarcodeResponse
}
export async function callToFetchPixBarcode(accountID: number, payload: BarcodePayload): Promise<BoletoBarcodeResponse> {
  const response = await client().post(`/payment-plans/${accountID}/pix`, payload)
  return response as PixBarcodeResponse
}

export async function callToGetBoletoPdf(accountID: number, payload: BarcodePayload): Promise<BoletoPdfResponse> {
  const response = await client().post(`/payment-plans/${accountID}/boleto/pdf`, payload)
  return response as BoletoPdfResponse
}

export async function callToGetPixPdf(accountID: number, payload: BarcodePayload): Promise<BoletoPdfResponse> {
  const response = await client().post(`/payment-plans/${accountID}/pix/pdf`, payload)
  return response as PixPdfResponse
}
