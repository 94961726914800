<script setup lang="ts">
import { Carousel, Slide } from "vue-carousel"
import { computed, ref, defineExpose, defineProps, defineEmits, withDefaults } from "vue"
import LowerMyPaymentQuotesCard from "@/components/account-home/LowerMyPaymentQuotesCard.vue"
import { EligibleProductDetails, Quote } from "@/api/secure"

type LowerMyPaymentQuotesProps = {
  productDetails: EligibleProductDetails;
  quotes: Quote[];
  showCarousel?: boolean;
}

const props = withDefaults(defineProps<LowerMyPaymentQuotesProps>(), {
  showCarousel: false
})

const emit = defineEmits<{
  (event: "quoteSelected", value: any): void
}>()

const defaultQuoteIndex = props.quotes.length - 1
const activeQuoteIndex = ref(-1)

const getLMPCardId = (index: number) => {
  return "lower-my-payment-card-" + index
}

const selectedQuoteIndex = computed(() => {
  return activeQuoteIndex.value >= 0 ? activeQuoteIndex.value : defaultQuoteIndex
})

const quoteSelected = function (quote: Quote) {
  activeQuoteIndex.value = props.quotes.indexOf(quote)
  emit("quoteSelected", quote)
}

const onPageChange = function(quoteIndex: number) {
  activeQuoteIndex.value = quoteIndex
}

const isSelectedQuote = function(index: number) {
  return selectedQuoteIndex.value === index
}

const isNotSelectedQuote = function(index: number) {
  return selectedQuoteIndex.value !== index
}

const mouseOverHandler = function(index: number) {
  activeQuoteIndex.value = index
}

const mouseOutHandler = function() {
  activeQuoteIndex.value = -1
}

const selectedQuoteInstallmentAmount = function() {
  return props.quotes[selectedQuoteIndex.value].InstallmentAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
}

defineExpose({
  selectedQuoteIndex,
  onPageChange
})

</script>

<template>
  <div>
    <h2 id="installment-value-p3">
      {{ $t("installmentValue") }}
      <h2 id="installment-value">{{ selectedQuoteInstallmentAmount() }}</h2>
    </h2>
    <div class="refinance-quotes-container">
      <ul
        v-if="!showCarousel"
        class="refinance-quotes-section">
        <li
          v-for="(quote, i) in quotes"
          :key="i"
          @mouseover="mouseOverHandler(i)"
          @mouseout="mouseOutHandler">
          <lower-my-payment-quotes-card
            :id="getLMPCardId(i)"
            :quote="quote"
            :product-details="productDetails"
            :active="isSelectedQuote(i)"
            @buttonClicked="quoteSelected" />
        </li>
      </ul>
      <carousel
        v-else
        :per-page="1"
        :navigate-to="selectedQuoteIndex"
        pagination-active-color="#01a201"
        pagination-color="#9b9b9b"
        class="refi-carousel"
        @page-change="onPageChange">
        <slide
          v-for="(quote, i) in quotes"
          :key="i">
          <lower-my-payment-quotes-card
            :id="getLMPCardId(i)"
            :quote="quote"
            :product-details="productDetails"
            :active="isSelectedQuote(i)"
            :controls-disabled="isNotSelectedQuote(i)"
            class="refinance-card-slide"
            @buttonClicked="quoteSelected" />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<style scoped lang="scss">
.refinance-quotes-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
}
.refinance-quotes-container {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  min-height: 300px;
}

.refinance-quotes-header span {
  align-self: baseline;
}

ul.refinance-quotes-section {
  list-style: none;
  min-height: 280px;
}
ul.refinance-quotes-section li {
  padding-left: 12px;
  padding-right: 12px;
}
.refinance-card-slide {
  margin: 1px 10px 1px 10px;
}

@media screen and (max-width: 520px) {
  .refinance-quotes-header span {
    align-self: auto;
  }
}
#installment-value-p3 {
  font: normal normal bold 16px Open Sans;
  padding: 15px;
}
#installment-value {
  font: normal normal bold 24px Open Sans;
  color: $simplic-green;
}
</style>

<i18n>
  {
    "pt": {
      "installmentValue": "Novo Valor da Parcela: "
    }
  }
  </i18n>
