<script setup lang="ts">
import { onMounted, defineProps } from "vue"
import SimplicExpressHeader from "@/components/loan-processing/SimplicExpressHeader.vue"
import { useCookieStore } from "@/store"
import { postEBSEvent } from "@/api/secure"

type KlaviOpenBankingProps = {
  redirect: string;
}

defineProps<KlaviOpenBankingProps>()

const { analyticDataLayer, accessTokensData } = useCookieStore()

const clicked = () => {
  if(accessTokensData.value.account_id && analyticDataLayer.value.loan_application_id) {
    postEBSEvent("klavi_frontend", accessTokensData.value.account_id, analyticDataLayer.value.loan_application_id, "klavi_open_clicked", "")
  }
}

onMounted(() => {
  if(accessTokensData.value.account_id && analyticDataLayer.value.loan_application_id) {
    postEBSEvent("klavi_frontend", accessTokensData.value.account_id, analyticDataLayer.value.loan_application_id, "klavi_open_landed", "")
  }
})
</script>

<template>
  <div class="outer">
    <div class="inner">
      <SimplicExpressHeader />
      <a :href="redirect">
        <button
          @click="clicked">
          <span id="continue-text-span">{{ $t('continue') }}</span>
          <span id="arrow-img-span"><img aria-labelledby="continue-text-span" alt="arrow" src="~@/assets/arrow-back-gray.svg" /></span>
        </button>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.outer {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.inner {
  text-align:center;
  margin:auto;
  background-color: $simplic-white;
  border: 1px solid $simplic-light-gray;
}

button {
  background-color: $simplic-yellow;
  padding: 10px;
  margin: 20px auto;
  width: 200px;
  border-radius: 8px;

  #continue-text-span {
    font: normal normal bold 16px/22px Open Sans;
    float: left;
    color: $simplic-dark-text;
    padding-top: 1px;
  }

  #arrow-img-span {
    float: right;
    padding-top: 3px;
  }
}
</style>

<i18n>
{
  "pt": {
    "continue": "Continuar"
  }
}
</i18n>
