import { client } from "@/api/secure-service"
import { QuestionnaireData } from "@/types/questionnaireTypes"

// Record<string, unknown> refers to any object
export async function getQuestionnaireConfig (brand: string, questionnaire: string, params: Record<string, unknown>): Promise<QuestionnaireData> {
  try {
    const response = await client().get<QuestionnaireData>(`/questionnaire/${brand}/${questionnaire}`, undefined, { params: params })
    return Promise.resolve(response)
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function postQuestionnaireAnswers(payload: QuestionnaireData): Promise<QuestionnaireData> {
  try {
    const response = await client().post<QuestionnaireData, QuestionnaireData>(`/questionnaire/${payload.brand}/${payload.name}/answers`, payload)
    return Promise.resolve(response)
  } catch(err) {
    return Promise.reject(err)
  }
}
