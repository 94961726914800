<script setup lang="ts">
import { computed, ref, Ref, onMounted, defineExpose } from "vue"
import PaymentPlanOffersMobile from "@/components/payment-plans/OffersCarousel.vue"
import PaymentPlanOffersDesktop from "@/components/payment-plans/OffersCards.vue"
import SimplicDatepicker from "@/components/SimplicDatepicker.vue"
import store, {
  useAccountStore,
  useCookieStore,
  usePaymentPlansStore
} from "@/store"
import {
  fetchUnpaidInstallments,
  PaymentPlanOffer,
  UnpaidInstallmentsPayload
} from "@/api/secure/sysopen-gateway-service"
import analytics, { logError } from "@/lib/analytics"
import {
  defaultedInstallmentBalance,
  calculateDownPaymentOptions,
  nextHighestNumberPast
} from "@/lib/account/helpers"
import { useRouter } from "vue-router/composables"

const paymentMethods = computed(() => store.getters["paymentPlans/availablePaymentMethods"])
const { accountID, cpf } = useAccountStore()
const {
  loading,
  fetchOffers,
  selectOffer,
  confirmed,
  confirmOffer,
  fetchBarcodeByPaymentMethod,
  firstDueDate: firstDueDateStore,
  downPaymentAmount: downPaymentAmountStore,
  withDownPayment,
  currentPaymentMethod,
  selectedOffer
} = usePaymentPlansStore()
const fetchingNewOffers = ref(false)
const confirmingOfferSelection = ref(false)
const datepicker: Ref<{ valid: boolean } | null> = ref(null)
const defaultMaxDays = 10
const firstDueDate = ref(firstDueDateStore.value?.substring(0, 10))
const firstDueDateMaxDaysFromNow = ref(defaultMaxDays)
const downPaymentAmount = ref(downPaymentAmountStore.value)
const { analyticDataLayer } = useCookieStore()
const minDaysInDefaultForDownPayment = 31
const wantsCustomDownPayment = ref(false)
const startingSelection = "Selecione uma opção"
const downPaymentOptions = ref([startingSelection])
const downPaymentSelection = ref(startingSelection)
const router = useRouter()

const onSelectOfferDesktop = (offer: PaymentPlanOffer) => {
  selectOffer(offer)
  scrollBy(0, 100)
}

const onSelectOfferMobile = (offer: PaymentPlanOffer) => {
  selectOffer(offer)
  router.push({
    name: "PaymentPlanPaymentConfirmation"
  })
}

const routeToConfirmation = () => {
  router.push({
    name: "BoletoAndPixConfirmation"
  })
}

const onConfirm = async () => {
  try {
    confirmingOfferSelection.value = true
    if (confirmed.value) {
      routeToConfirmation()

      return
    }

    // Confirm button is only visible if there is a selected offer
    const confirmationResult = await confirmOffer()
    if (!confirmationResult) {
      // error handling happens at store level
      return
    }

    const barcodeResult = await fetchBarcodeByPaymentMethod()
    if (!barcodeResult) {
      await router.push("/loan-status")

      return
    }

    routeToConfirmation()
  } finally {
    confirmingOfferSelection.value = false
  }
}

const onDateSelection = async (chosenIsoDate: string) => {
  selectedOffer.value = null
  confirmed.value = false

  if (!datepicker.value?.valid) {
    return false
  }

  fetchingNewOffers.value = true
  if (chosenIsoDate !== firstDueDate.value) {

    await fetchOffers(chosenIsoDate, downPaymentAmount.value)

    firstDueDate.value = chosenIsoDate
  }

  fetchingNewOffers.value = false
}

const onDownPaymentCheckboxChange = async () => {
  if (downPaymentSelection.value !== startingSelection) {
    selectedOffer.value = null
    confirmed.value = false

    fetchingNewOffers.value = true
    const amount = !wantsCustomDownPayment.value ? "000" : downPaymentSelection.value

    await fetchOffers(firstDueDate.value || "", amount)

    downPaymentAmount.value = amount
    fetchingNewOffers.value = false

    return true
  }

  return false
}

const onDownPaymentChange = async () => {
  selectedOffer.value = null
  confirmed.value = false

  fetchingNewOffers.value = true
  const amount = (downPaymentSelection.value === startingSelection) ? "000" : downPaymentSelection.value

  await fetchOffers(firstDueDate.value || "", amount)

  downPaymentAmount.value = amount
  fetchingNewOffers.value = false

  return true
}

const paymentMethodImage = (paymentMethod: string) => {
  return require(`@/assets/images/svg/${paymentMethod.toLowerCase()}_btn.svg`)
}

const setCurrentPaymentMethod = (method: string) => {
  currentPaymentMethod.value = method
  selectedOffer.value = store.getters["paymentPlans/matchingOfferByPaymentMethod"]
}

onMounted(() => {
  analytics.track("payment_plan_offers_presented")

  const dueDateEnabled = analytics.plugins.optimizely.isFeatureEnabled("payments_plan_firstduedate")
  if (dueDateEnabled) {
    const maxDaysFromNow = analytics.plugins.optimizely.getFeatureVariableInteger("payments_plan_firstduedate", "first_due_date", accountID.value.toString())
    firstDueDateMaxDaysFromNow.value = maxDaysFromNow || defaultMaxDays
  }

  let maxDaysInDefault = 0
  if (analyticDataLayer.value.maxDaysInDefault !== null) {
    maxDaysInDefault = analyticDataLayer.value.maxDaysInDefault
  }

  if (maxDaysInDefault >= minDaysInDefaultForDownPayment) {
    withDownPayment.value = true
  }

  if (withDownPayment.value) {
    const payload: UnpaidInstallmentsPayload = { cpf: cpf.value.toString() }
    fetchUnpaidInstallments(accountID.value, payload)
      .then((response) => {
        const unpaidInstallments = response.unpaid_installments
        const balance = defaultedInstallmentBalance(unpaidInstallments)
        const options = calculateDownPaymentOptions(balance)
        downPaymentOptions.value.push(...options)

        const optimizelyVariation = analytics.plugins.optimizely.activate("collection_platform_default_offers")
        if (optimizelyVariation === "down_payment_offers") {
          const optionsAsNumbers = options.map(Number)
          const preselectedOption = nextHighestNumberPast(balance * 0.30, optionsAsNumbers) || 100

          wantsCustomDownPayment.value = true
          downPaymentSelection.value = preselectedOption.toString()
          onDownPaymentCheckboxChange()
        }
      })
      .catch(error => {
        logError(error)
      })
  }
})

defineExpose({
  downPaymentSelection,
  downPaymentOptions,
  paymentMethodImage,
  onDownPaymentChange,
  onDownPaymentCheckboxChange,
  onDateSelection,
  onSelectOfferDesktop,
  setCurrentPaymentMethod,
  onConfirm,
  onSelectOfferMobile
})
</script>

<template>
  <div id="payment-plan-offers-container">
    <h2>
      {{ $t('datePickerPrompt') }}
    </h2>
    <simplic-datepicker
      id="first-due-date-datepicker"
      ref="datepicker"
      :value="firstDueDate"
      :placeholder="$t('firstDueDatePlaceholder')"
      locale="pt"
      :disabled="loading"
      :min-days-from-now="0"
      :max-days-from-now="firstDueDateMaxDaysFromNow"
      weekdays-only
      @input="onDateSelection" />
    <div v-if="withDownPayment" id="down-payment-input">
      <div id="down-payment-checkbox">
        <v-checkbox
          v-model="wantsCustomDownPayment"
          @change="onDownPaymentCheckboxChange">
          <template #label>
            <div>
              <h2> {{ $t('downPaymentPrompt') }} </h2>
            </div>
          </template>
        </v-checkbox>
      </div>
      <div id="down-payment-dropdown">
        <v-select
          v-if="wantsCustomDownPayment"
          v-model="downPaymentSelection"
          :items="downPaymentOptions"
          outlined
          @change="onDownPaymentChange" />
      </div>
    </div>
    <div v-if="wantsCustomDownPayment" id="mobile-breakpoint" />
    <payment-plan-offers-mobile
      v-if="$vuetify.breakpoint.mobile" id="payment-plan-offers-mobile" :loading="loading"
      @offer-selected="onSelectOfferMobile" />
    <template v-if="!$vuetify.breakpoint.mobile">
      <payment-plan-offers-desktop
        id="payment-plan-offers-desktop" :loading="fetchingNewOffers"
        @offer-selected="onSelectOfferDesktop" />
      <transition name="slide-down" :duration="500">
        <template v-if="selectedOffer">
          <div>
            <transition name="slide-down" :duration="500">
              <div
                v-if="selectedOffer && (paymentMethods.length <= 1 || currentPaymentMethod.value !== '')"
                id="payment-confirmation-button-container"
                :class="{'payment-methods': paymentMethods.length > 1}">
                <h3>
                  {{ $t("confirmationMessage") }}
                </h3>
                <hr />
                <v-btn id="confirm-payment-option" :loading="confirmingOfferSelection" @click="onConfirm">
                  {{ $t('confirm') }}
                </v-btn>
              </div>
            </transition>
          </div>
        </template>
      </transition>
    </template>
  </div>
</template>

<style lang="scss">
@import '~/src/scss/variables.scss';

#payment-plan-offers-container {
  h2 {
    font-weight: bold;
    font-family: $font-family-sans-serif;
    color: #4a4a4a;
    font-size: 1.25em;
  }

  #first-due-date-datepicker {
    max-width: 200px;
  }

  .v-picker__body {
    z-index: 1;
  }

  #down-payment-input {
    display: flex;
    flex-wrap: wrap;
    max-height: 50px;
    max-width: 900px;
  }

  #down-payment-checkbox {
    padding-right: 10px;
  }

  #down-payment-dropdown {
    width: 228px;
  }

  #mobile-breakpoint {
    padding-bottom: 25px;
  }

  @media (max-width: 782px) {
    #mobile-breakpoint {
      padding-bottom: 75px;
    }
  }

  #down-payment-amount-label {
    color: rgb(75, 75, 75);
    margin-left: 5px;
    margin-right: 7px;
  }

  #down-payment-amount {
    margin-top: -20px;
    max-width: 170px;
    width: 170px;
  }
}

#payment-plan-offers-mobile {
  padding-bottom: 50px;
  max-width: 350px;
  margin: 0 auto;
}

#payment-confirmation-button-container, #payment-method-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1em auto 0 auto;
  padding: 1em;
  background-color: #DFF0D8;
}

#payment-confirmation-button-container.payment-methods {
  padding: 1em 2em;
  background-color: #ffffff;;
}

#confirm-payment-option {
  margin: 1em auto 0.75em auto;
  background-color: $simplic-yellow;
  text-transform: capitalize;
  color: $simplic-copy;
  padding: 1.25em 2em;
  font-size: 1.5em;
  letter-spacing: 0;
  width: 100%;
  font-weight: bold;
  max-width: 400px;
}
#payment-method-container {
  .payment-method-btn {
    margin: 1em .5em 0.75em .5em;
    padding: 1.25em 2em;
    font-size: 1.5em;
    letter-spacing: 0;
    font-weight: bold;
    max-width: 400px;
    text-transform: capitalize;
  }
  .payment-method-btn.active {
    padding: 1.15em 1.9em;
    border: .1em solid $simplic-yellow;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave {
  overflow: hidden;
  max-height: 1000px;
}

.slide-down-enter,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
<i18n>
{
  "pt": {
    "firstDueDatePlaceholder": "Por favor selecione",
    "datePickerPrompt": "Quero começar a quitar minhas parcelas atrasadas em:",
    "downPaymentPrompt": "Selecione um valor de entrada menor",
    "confirm": "Confirmo",
    "confirmationMessage": "Confirmar acordo nas condições escolhidas?"
  }
}
</i18n>
