<script setup lang="ts">
import { onMounted, Ref, ref, watch, defineProps, defineEmits } from "vue"

type BrazilVSliderProps = {
  step: number;
  min: number;
  max: number;
  value: number;
}

const props = defineProps<BrazilVSliderProps>()
const emit = defineEmits<{
  (event: "sliderChanged", value: number): void
}>()

const sliderValue: Ref<number> = ref(0)

const valueChanged = (v: number) => {
  emit("sliderChanged", v)
}

watch(() => sliderValue.value, (newVal) => {
  valueChanged(newVal)
})

onMounted(() => {
  sliderValue.value = props.value
})

</script>

<template>
  <v-slider
    v-model="sliderValue"
    class="brazil-v-slider"
    track-color="#f3f3f3"
    track-fill-color="#048a05"
    thumb-color="#048a05"
    ticks="always"
    :step="step"
    :min="min"
    :max="max"
    :value="value" />
</template>

<style lang="scss">
.v-slider--horizontal .v-slider__track-container {
  height: 9px !important;
}

.v-slider__thumb {
  width: 22px;
  height: 22px;
}

.v-slider__tick {
  width: 1px !important;
  height: 9px !important;
  top: calc(50% - 4.5px) !important;
  background: #DEDEDE;
}
</style>
<i18n>
{
  "pt": {
  }
}
</i18n>
