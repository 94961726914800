import { Severity, Event, EventHint } from "@sentry/types"
import { AxiosError } from "axios"


import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations/dist/vue"
import Vue from "vue"
import { AnalyticsPlugin } from "analytics"
import {
  Config,
  SentryConfigCallback,
  SentryEventCallback
} from "@/lib/analytics/analytics-types"

import { ErrorEventNames } from "@/lib/analytics/event-types"
import { buildAnalyticsCallback } from "@/lib/analytics/helpers"

export function isConfigurationSet(): boolean {
  return !!process.env.VUE_APP_SENTRY_DSN
}

const impl = {
  initialize: ({ config }: SentryConfigCallback) => {
    Sentry.init(config)
  },

  track: buildAnalyticsCallback(({ payload }: SentryEventCallback) => {
    if (!ErrorEventNames.has(payload.event))
      return

    const { error, severity, ...otherProps } = payload.properties
    Sentry.withScope(function (scope) {
      scope.setLevel(severity || Sentry.Severity.Info)
      scope.setExtras(otherProps)
      const transactionName = payload.traits?.flowName
      if (typeof transactionName === "string") {
        scope.setTransactionName(transactionName)
      }
      Sentry.captureException(error || new Error("no error set"))
    })
  }),

  identify: buildAnalyticsCallback(({ payload }: SentryEventCallback) => {
    const sentryUser = {
      id: payload.userId,
      anonymous_uuid: payload.userId,
      user_uuid: payload.userId,
      is_logged_in: !!payload.userId
    }
    Sentry.setUser({ ...payload.traits, ...sentryUser })
  }),
  loaded: () => {
    return true
  }
}

export function errorMessageHasAny(event: Event, hint: EventHint, messages: Array<string|RegExp>): boolean {
  const error = hint.originalException
  let errorMessage = ""
  if(typeof(error) === "string"){
    errorMessage = error
  }
  else if(error && error.message) {
    errorMessage = error.message
  }
  return !!messages.find(m => !!errorMessage.match(m))
}

export function decoreateAsWarn(ruleName: string, event: Event & {extra: Record<string, unknown>}): void{
  event.extra.noise_category = ruleName
  event.level = Severity.Warning
}

type ClassificationConfig = {
  when: () => boolean;
  then: () => void;
}

export enum knownErrorCategories {
  known3rdPartyScriptError = "known3rdPartyScriptError",
  networkFailedToRespond = "networkFailedToRespond",
  invalidBrowserState = "invalidBrowserState"
}

export function buildErrorNoiseClassificationConfigs(
  event: Event & {extra: Record<string, unknown>;
  }, hint: EventHint): Array<ClassificationConfig> {
  return [
    {
      when: (): boolean => {
        return errorMessageHasAny(
          event,
          hint,
          [
            /SecurityError. The operation is insecure./i,
            /SecurityError. Failed to read the 'localStorage' property from 'Window'. Access is denied for this document./i,
            /window.webkit.messageHandlers.selectedDebugHandler.postMessage/i
          ])
      },
      then: decoreateAsWarn.bind(undefined,  knownErrorCategories.invalidBrowserState, event)
    },
    {
      when: () => {
        const error = hint.originalException
        if(error && (error as AxiosError).isAxiosError){
          const axiosError = (error as AxiosError)
          if(!axiosError.response)
            return true
          else if(axiosError.response.status === 0)
            return true
        }
        return false
      },
      then: decoreateAsWarn.bind(undefined, knownErrorCategories.networkFailedToRespond, event)
    }
  ]
}

export default function sentryPlugin(config: Config): typeof impl & AnalyticsPlugin {
  if (config.enabled && !isConfigurationSet()) {
    console.error("Sentry not configured")
    config.enabled = false
  }
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: "sentry",
    config: {
      ...config,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      release: `simplic-web@${process.env.GIT_COMMIT}-${process.env.GIT_COMMIT_OVERLAYS}`,
      environment: process.env.VUE_APP_ENVIRONMENT,
      beforeSend: (event: Event, hint: EventHint) => {
        if(!event.extra){
          event.extra = {}
        }
        const eligibleConfig = buildErrorNoiseClassificationConfigs(
          event as Event & {extra: Record<string, unknown>}, hint
        ).find(({ when }) => when())
        if(eligibleConfig)
          eligibleConfig.then()
        return event
      },
      integrations: [
        new VueIntegration({
          Vue,
          attachProps: true,
          logErrors: true
        })]
    },
    ...impl
  }
}
